import { FC, useState } from 'react';
import styles from './ModalCompaniesStatusChange.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import ActionsBlock from '../../../primitives/actions-block/ActionsBlock';
import Button from '../../../primitives/button/Button';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import Loader from '../../../primitives/loader/Loader';
import IModalCompaniesStatusChange from './IModalCompaniesStatusChange';
import { bulkUpdateCompaniesStatus } from '../../../../services/store/slices/companies.slice';
import { CommonStatusDisplayNames } from '../../../../utils/helpers/constants';

const ModalCompaniesStatusChange: FC<IModalCompaniesStatusChange> = ({
	open,
	ids,
	setOpen,
	status,
	onChangeSuccess,
	onChangeError,
	onCancel,
}) => {
	const dispatch = useAppDispatch();
	const [changeLoading, setChangeLoading] = useState(false);

	const onChange = () => {
		setChangeLoading(true);

		handleWithTryCatch(
			async () => {
				if (ids.length) {
					await dispatch(bulkUpdateCompaniesStatus(ids, status));
					onChangeSuccess();
					setOpen(false);
					setChangeLoading(false);
				}
			},
			undefined,
			() => {
				onChangeError();
				setChangeLoading(false);
			},
		);
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Change Status?</h2>

				<p className={styles.text}>
					Are you sure you want to change status to{' '}
					<strong>
						{CommonStatusDisplayNames[status as keyof typeof CommonStatusDisplayNames]}
					</strong>{' '}
					for selected companies?
				</p>

				<ActionsBlock>
					<Button type="button" width={160} negative onClick={onCancel}>
						Cancel
					</Button>

					<Button type="button" width={160} onClick={onChange} disabled={changeLoading}>
						{changeLoading ? (
							<Loader
								thin
								maxHeight={14}
								maxWidth={14}
								color="#ffffff"
								transparentColor="transparent"
							/>
						) : (
							'Yes, change'
						)}
					</Button>
				</ActionsBlock>
			</BasicPanel>
		</Modal>
	);
};

export default ModalCompaniesStatusChange;
