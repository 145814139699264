import React, { FC } from 'react';
import styles from './ActionsBlock.module.scss';
import classNames from 'classnames';
import IActionsBlock from './IActionsBlock';

const ActionsBlock: FC<IActionsBlock> = ({ className, children }) => {
	return <div className={classNames(styles.actions, className)}>{children}</div>;
};

export default ActionsBlock;
