import React from 'react';
import styles from './UserProfile.module.scss';
import useUser from '../../../utils/helpers/hooks/useUser';
import { userNameFirstLetters } from '../../../utils/helpers/common';
import { Link, useLocation } from 'react-router-dom';
import { AppUrls } from '../../../utils/helpers/constants/app-routes';
import classNames from 'classnames';

const UserProfile = () => {
	const location = useLocation();
	const { info: userInfo } = useUser();
	const isActive = location.pathname === AppUrls.profile;

	return userInfo ? (
		<Link
			to={AppUrls.profile}
			className={classNames(styles['user-profile'], isActive ? styles.active : '')}
		>
			<div className={styles['user-block']}>
				<div className={styles.avatar}>
					{userNameFirstLetters(userInfo?.firstName, userInfo?.lastName)}
				</div>

				<div className={styles.info}>
					<div
						className={styles.name}
					>{`${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`}</div>
					<div className={styles.title}>Admin</div>
				</div>
			</div>
		</Link>
	) : null;
};

export default UserProfile;
