import React, { FC } from "react";
import classNames from "classnames";

import styles from "./Loader.module.scss";
import ILoader from "./ILoader";
import Spinner from "../spinner/Spinner";

const Loader: FC<ILoader> = ({
  className,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  thin,
  color,
  transparentColor,
  wide,
  ...rest
}) => {
  const inlineStyles = {
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
  };

  const spinnerInlineStyles =
    color && transparentColor
      ? {
          borderColor: color,
          borderTopColor: transparentColor,
        }
      : {};

  return (
    <div
      style={inlineStyles}
      className={classNames(styles.loader, className, wide && styles.wide)}
      {...rest}
    >
      <Spinner
        style={spinnerInlineStyles}
        thin={thin}
        width={maxWidth}
        height={maxHeight}
      />
    </div>
  );
};

export default React.memo(Loader);
