import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ISortProps, CommonResultStatusType, IKeyValuePair } from '../../../../utils/types';
import Table from '../../../primitives/table/Table';
import { processTableData } from '../../../../utils/helpers/common';
import TableFilters from '../../../primitives/table-filters/TableFilters';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import styles from './NistCrosswalkTable.module.scss';
import { getNistCrosswalkColumns } from './utils';
import usePagination from '../../../../utils/helpers/hooks/usePagination';
import Alert from '../../../primitives/alert/Alert';
import TableSkeleton from '../../../primitives/table/TableSkeleton';
import classNames from 'classnames';
import SearchFilter from '../../../primitives/filters/search-filter/SearchFilter';
import IPartialTable from '../common/IPartialTable';
import {
	INistCrosswalk,
	updateNistCrosswalk,
} from '../../../../services/store/slices/crosswalk.slice';
import ActionsBlock from '../../../primitives/actions-block/ActionsBlock';
import Button from '../../../primitives/button/Button';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import { useAppDispatch } from '../../../../services/store';
import ModalLeaveWarning from '../../modals/modal-leave-warning/ModalLeaveWarning';
import useLeaveWarning from '../../../../utils/helpers/hooks/useLeaveWarning';
import { useConfirmNavigation } from '../../../../utils/helpers/hooks/useConfirmNavigation';
import Loader from '../../../primitives/loader/Loader';
import useCrosswalk from '../../../../utils/helpers/hooks/useCrosswalk';

const NistCrosswalkTable: FC<IPartialTable<INistCrosswalk>> = ({ data, dataLoading }) => {
	const dispatch = useAppDispatch();
	const { updateLoading } = useCrosswalk();
	const [processedData, setProcessedData] = useState(data);
	const [currentPageData, setCurrentPageData] = useState<INistCrosswalk[]>([]);
	const [crosswalkUpdateResult, setCrosswalkUpdateResult] = useState<CommonResultStatusType>('');
	const [isProcessing, setIsProcessing] = useState(true);
	const [currentDataState, setCurrentDataState] = useState<INistCrosswalk[]>([]);
	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [showBrowserLeaveWarning, setBrowserShowLeaveWarning] = useState(false);
	const [updatedItemId, setUpdatedItemId] = useState<string | null>('');

	const initialState: INistCrosswalk[] = useMemo(() => {
		if (!data) return [];
		return Object.assign([], data);
	}, [data]);

	const { paginationSection, setCurrentPage, pagesCount, goToPageByDataEntryProperty } =
		usePagination(processedData, setCurrentPageData);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: INistCrosswalk) => true,
	});

	const onCheckboxClick = (e: any) => {
		const rowId = e.target.id;

		const recordId = rowId.split('|')[0];
		const recordType = rowId.split('|')[1];

		setCurrentDataState((prevState: INistCrosswalk[]) => {
			return prevState.map((item) => {
				if (item.id === recordId) {
					return {
						...item,
						[recordType]: !item[recordType as 'low' | 'moderate' | 'high'],
					};
				}

				return item;
			});
		});
	};

	const tableColumnsConfig = useMemo(() => getNistCrosswalkColumns(onCheckboxClick), []);

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onUpdateClick = useCallback(() => {
		const getOnlyChangedData = () => {
			return currentDataState.filter((item, index) => {
				const initialItem = initialState[index];

				return (
					item.low !== initialItem.low ||
					item.moderate !== initialItem.moderate ||
					item.high !== initialItem.high
				);
			});
		};

		const onlyChangedData = getOnlyChangedData();

		handleWithTryCatch(
			async () => {
				await dispatch(updateNistCrosswalk(onlyChangedData));
				setUpdatedItemId(onlyChangedData[0].id);
				setCrosswalkUpdateResult('success');
				setBrowserShowLeaveWarning(false);
			},
			undefined,
			() => {
				setCrosswalkUpdateResult('error');
			},
		);
	}, [currentDataState, dispatch, initialState]);

	const changesWereMade = useMemo(() => {
		if (!currentDataState || !initialState) return false;

		return currentDataState.some((item, index) => {
			const initialItem = initialState[index];

			return (
				item.low !== initialItem.low ||
				item.moderate !== initialItem.moderate ||
				item.high !== initialItem.high
			);
		});
	}, [currentDataState, initialState]);

	const modals = (
		<ModalLeaveWarning
			open={warningModalOpen}
			setOpen={setWarningModalOpen}
			onConfirm={() => {
				continueNavigation();
				setBrowserShowLeaveWarning(false);
			}}
		/>
	);

	const alerts = (
		<>
			<Alert
				uniqueKey={'crosswalk-update-success'}
				show={crosswalkUpdateResult === 'success'}
				type="success"
				message="Framework crosswalk evaluation data updated"
				clearActionStatus={() => setCrosswalkUpdateResult('')}
			/>

			<Alert
				uniqueKey={'crosswalk-update-error'}
				show={crosswalkUpdateResult === 'error'}
				type="error"
				message="Framework crosswalk evaluation data was not updated. Please try again."
				clearActionStatus={() => setCrosswalkUpdateResult('')}
			/>
		</>
	);

	const tableFilters = useMemo(() => {
		const leftSection = (
			<SearchFilter
				placeholder="Search..."
				setFilters={setCurrentFilters}
				properties={['controlId', 'enhancementName']}
			/>
		);

		const rightSection = (
			<ActionsBlock className={styles.actions}>
				<Button
					className={styles.action}
					type="button"
					width={96}
					onClick={() => setCurrentDataState(initialState)}
					negative
					disabled={updateLoading}
				>
					Cancel
				</Button>

				<Button
					className={styles.action}
					type="submit"
					width={84}
					disabled={updateLoading}
					onClick={onUpdateClick}
				>
					{updateLoading ? <Loader thin maxHeight={14} maxWidth={14} /> : 'Update'}
				</Button>
			</ActionsBlock>
		);

		return (
			<TableFilters
				disabled={!dataLoading && !data.length}
				leftSection={leftSection}
				rightSection={changesWereMade ? rightSection : null}
			/>
		);
	}, [changesWereMade, data.length, dataLoading, initialState, onUpdateClick, updateLoading]);

	const getChangedRows = useCallback(() => {
		if (!currentDataState || !initialState) return [];

		return currentDataState
			.filter((item, index) => {
				const initialItem = initialState[index];

				return (
					item.low !== initialItem.low ||
					item.moderate !== initialItem.moderate ||
					item.high !== initialItem.high
				);
			})
			?.map((item) => item.id);
	}, [currentDataState, initialState]);

	const mainTable = useMemo(() => {
		return (
			<Table
				tableKey="nist-crosswalk"
				data={currentPageData}
				columns={tableColumnsConfig}
				options={{
					onSortChange,
					successHighlightedRows: getChangedRows(),
					bodyMaxHeight: 'calc(100vh - 370px)',
					emptyResultMessage:
						!dataLoading && !data.length
							? `Nothing to display yet.`
							: `No matches found. Please try another search query.`,
					emptyResultMessageType: !dataLoading && !data.length ? 'common' : 'search',
				}}
			/>
		);
	}, [currentPageData, data.length, dataLoading, getChangedRows, onSortChange, tableColumnsConfig]);

	useLeaveWarning(showBrowserLeaveWarning);

	const { continueNavigation } = useConfirmNavigation(
		() => setWarningModalOpen(true),
		changesWereMade,
	);

	useEffect(() => {
		if (updatedItemId) {
			goToPageByDataEntryProperty(updatedItemId, 'id');

			const addDelay = setTimeout(() => {
				setUpdatedItemId(null);
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [data, goToPageByDataEntryProperty, updatedItemId]);

	useEffect(() => {
		if (changesWereMade) setBrowserShowLeaveWarning(true);
	}, [changesWereMade]);

	useEffect(() => {
		setCurrentDataState(initialState);
	}, [initialState]);

	useEffect(() => {
		setIsProcessing(true);
		if (!changesWereMade) setCurrentPage(1);

		const proccessedData: INistCrosswalk[] = processTableData(
			currentDataState,
			currentFilters,
			currentSort,
		);

		setProcessedData(proccessedData);
		setIsProcessing(false);
	}, [currentFilters, currentSort, currentDataState, setCurrentPage, changesWereMade]);

	return (
		<>
			<BasicPanel
				className={classNames(styles['basic-panel'], isProcessing ? styles.disabled : '')}
			>
				{tableFilters}

				{isProcessing || dataLoading ? (
					<TableSkeleton rowsNumber={4} columns={tableColumnsConfig} />
				) : (
					mainTable
				)}
			</BasicPanel>

			{pagesCount > 1 ? paginationSection : null}

			{alerts}

			{modals}
		</>
	);
};

export default NistCrosswalkTable;
