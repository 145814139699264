import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ISortProps, CommonResultStatusType, IKeyValuePair } from '../../../../utils/types';
import Table from '../../../primitives/table/Table';
import { processTableData } from '../../../../utils/helpers/common';
import TableFilters from '../../../primitives/table-filters/TableFilters';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import styles from './SupplementaryCrosswalkTable.module.scss';
import { getIsoCrosswalkColumns } from './utils';
import usePagination from '../../../../utils/helpers/hooks/usePagination';
import Alert from '../../../primitives/alert/Alert';
import TableSkeleton from '../../../primitives/table/TableSkeleton';
import classNames from 'classnames';
import SearchFilter from '../../../primitives/filters/search-filter/SearchFilter';
import IPartialTable from '../common/IPartialTable';
import EditControl from '../../edit-panels/control/EditControl';
import {
	ISupplementaryCrosswalk,
	SupplementaryFrameworkTypes,
} from '../../../../services/store/slices/crosswalk.slice';
import useDevice from '../../../../utils/helpers/hooks/useDevice/useDevice';

const SupplementaryCrosswalkTable: FC<
	IPartialTable<ISupplementaryCrosswalk> & { type: SupplementaryFrameworkTypes }
> = ({ data, dataLoading, type }) => {
	const device = useDevice();

	const [processedData, setProcessedData] = useState(data);
	const [currentPageData, setCurrentPageData] = useState<ISupplementaryCrosswalk[]>([]);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [selectedControlRecord, setSelectedControlRecord] =
		useState<ISupplementaryCrosswalk | null>();
	const [itemUpdateResult, setItemUpdateResult] = useState<CommonResultStatusType>('');
	const [isProcessing, setIsProcessing] = useState(true);
	const [updatedItemId, setUpdatedItemId] = useState<string | null>('');

	const { paginationSection, setCurrentPage, pagesCount, goToPageByDataEntryProperty } =
		usePagination(processedData, setCurrentPageData);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: ISupplementaryCrosswalk) => true,
	});

	const tableColumnsConfig = useMemo(() => getIsoCrosswalkColumns(device), [device]);

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onRowClickHandler = useCallback(
		(id: string) => {
			const crosswalkRecord = data.find((c: ISupplementaryCrosswalk) => c.id === id);
			setSelectedControlRecord(crosswalkRecord);
			setOpenDrawer((prev) => !prev);
		},
		[data],
	);

	const onItemUpdateSuccess = (updatedItemId?: string) => {
		setUpdatedItemId(updatedItemId || '');
		setItemUpdateResult('success');
		setOpenDrawer(false);
	};
	const onItemUpdateError = () => setItemUpdateResult('error');

	const editDrawer = (
		<EditControl
			controlRecord={selectedControlRecord}
			onUpdateSuccess={onItemUpdateSuccess}
			onUpdateError={onItemUpdateError}
			closeHandler={() => setOpenDrawer(false)}
			open={openDrawer}
			type={type}
		/>
	);

	const alerts = (
		<>
			<Alert
				uniqueKey={'item-update-success'}
				show={itemUpdateResult === 'success'}
				type="success"
				message="Item updated!"
				clearActionStatus={() => setItemUpdateResult('')}
			/>

			<Alert
				uniqueKey={'item-update-error'}
				show={itemUpdateResult === 'error'}
				type="error"
				message="Item was not updated. Please try again."
				clearActionStatus={() => setItemUpdateResult('')}
			/>
		</>
	);

	const tableFilters = useMemo(() => {
		const leftSection = (
			<SearchFilter
				placeholder="Search..."
				setFilters={setCurrentFilters}
				properties={['controlId', 'enhancementName']}
			/>
		);

		return <TableFilters disabled={!dataLoading && !data.length} leftSection={leftSection} />;
	}, [data.length, dataLoading]);

	const mainTable = useMemo(
		() => (
			<Table
				tableKey="iso-crosswalk"
				data={currentPageData}
				columns={tableColumnsConfig}
				options={{
					onSortChange,
					onRowClickHandler,
					bodyMaxHeight: 'calc(100vh - 370px)',
					successHighlightedRows: [updatedItemId || ''],
					emptyResultMessage:
						!dataLoading && !data.length
							? `Nothing to display yet.`
							: `No matches found. Please try another search query.`,
					emptyResultMessageType: !dataLoading && !data.length ? 'common' : 'search',
				}}
			/>
		),
		[
			currentPageData,
			data.length,
			dataLoading,
			onRowClickHandler,
			onSortChange,
			tableColumnsConfig,
			updatedItemId,
		],
	);

	useEffect(() => {
		if (updatedItemId) {
			goToPageByDataEntryProperty(updatedItemId, 'id');

			const addDelay = setTimeout(() => {
				setUpdatedItemId(null);
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [data, goToPageByDataEntryProperty, updatedItemId]);

	useEffect(() => {
		setIsProcessing(true);
		setCurrentPage(1);

		const proccessedData: ISupplementaryCrosswalk[] = processTableData(
			data,
			currentFilters,
			currentSort,
		);

		setProcessedData(proccessedData);
		setIsProcessing(false);
	}, [currentFilters, currentSort, data, setCurrentPage]);

	useEffect(() => {
		if (!openDrawer) setSelectedControlRecord(null);
	}, [openDrawer]);

	return (
		<>
			<BasicPanel
				className={classNames(styles['basic-panel'], isProcessing ? styles.disabled : '')}
			>
				{tableFilters}

				{isProcessing || dataLoading ? (
					<TableSkeleton rowsNumber={4} columns={tableColumnsConfig} />
				) : (
					mainTable
				)}
			</BasicPanel>

			{pagesCount > 1 ? paginationSection : null}

			{editDrawer}

			{alerts}
		</>
	);
};

export default SupplementaryCrosswalkTable;
