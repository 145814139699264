import axios from 'axios';

import { LOGIN, LOGOUT, PASSWORD_REQUIREMENTS, INITIATE_RESET_PASSWORD, CONFIRM_RESET_PASSWORD, AUTH } from './api.path';
import { IAuthorizationPayload, ILoginResponse, IVerifyMfaCode } from '../store/slices/authorization.slice';
import { IAWSPasswordRequirements } from '../../utils/types';

const authorizationApi = {
	login: (data: Partial<IAuthorizationPayload>): Promise<ILoginResponse> => {
		return axios.post(LOGIN, {
			username: data.email,
			password: data.password,
		});
	},
	initiatePasswordReset: (email: string) => {
		return axios.post(INITIATE_RESET_PASSWORD, { username: email });
	},
	confirmPasswordReset: (password: string, token?: string) => {
		return axios.post(CONFIRM_RESET_PASSWORD, { password, token });
	},
	getPasswordRequirements: (): Promise<IAWSPasswordRequirements> => {
		return axios.get(PASSWORD_REQUIREMENTS);
	},
	logout: () => {
		return axios.post(LOGOUT);
	},
	getQrCode: (): Promise<{ qrCode: string; secretCode: string }> => {
		return axios.get(`${AUTH}/qr-code`);
	},
	validateMFA: (userCode: string): Promise<{ qrCode: string }> => {
		return axios.post(`${AUTH}/validate-mfa`, { userCode });
	},
	verifyMFA: (data: IVerifyMfaCode) => {
		return axios.post(`${AUTH}/verify-mfa`, data);
	},
	disableMFA: () => {
		return axios.post(`${AUTH}/disable-mfa`);
	},
};

export default authorizationApi;
