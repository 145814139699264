import { ITableColumn } from '../../../primitives/table/ITable';
import { DeviceType } from '../../../../utils/helpers/hooks/useDevice/IUseDevice';
import ColoredTag from '../../../primitives/tag/ColoredTag';
import { IndustriesDisplayNames, StatusColors } from '../../../../utils/helpers/constants';
import { dueDateColumnRenderHelper } from '../../../../utils/helpers/common';

export const getCompanyColumns = (device?: DeviceType | null) => {
	return [
		{
			header: 'ID',
			key: 'id',
			sorting: true,
			width: '8%',
		},
		{
			header: 'Name',
			key: 'name',
			sorting: true,
			width: '20%',
		},
		{
			header: 'Industry',
			key: 'industry',
			sorting: true,
			width: '20%',
			columnRender: (industry: string) =>
				IndustriesDisplayNames[industry as keyof typeof IndustriesDisplayNames],
		},
		{
			header: 'Account Status',
			key: 'status',
			sorting: true,
			width: '20%',
			columnRender: (status: string) => {
				const statusColor = StatusColors[status as keyof typeof StatusColors];
				return (
					<ColoredTag tagId={status} key={status} text={status} bgColor={statusColor} />
				);
			},
		},
		{
			header: 'Creation Date',
			key: 'createdAt',
			sorting: true,
			width: '15%',
			columnRender: (date: string) => {
				return (
					<ColoredTag
						borderColor={'#F4F9EB'}
						textColor="#415E2E"
						tagId={date}
						text={date}
						withoutBackground
					/>
				);
			},
		},
		{
			header: 'Expiration Date',
			key: 'expirationDate',
			sorting: true,
			width: '15%',
			columnRender: (dueDate: string) => dueDateColumnRenderHelper(dueDate),
		},
		{
			header: '',
			key: 'actions',
			width: '5%',
		},
	] as ITableColumn[];
};
