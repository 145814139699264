import { FC, useCallback, useEffect, useState } from 'react';
import styles from './EditAdmin.module.scss';
import Input from '../../../primitives/form/input/Input';
import Button from '../../../primitives/button/Button';
import ActionsBlock from '../../../primitives/actions-block/ActionsBlock';
import { useForm } from 'react-hook-form';
import IEditAdmin from './IEditAdmin';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import {
	requireValidationPattern,
	//emailValidationPattern,
	nameValidationPattern,
} from '../../../../utils/helpers/common/form';
import Drawer from '../../../primitives/drawer/Drawer';
import ModalLeaveWarning from '../../modals/modal-leave-warning/ModalLeaveWarning';
import useLeaveWarning from '../../../../utils/helpers/hooks/useLeaveWarning';
import {
	IAdmin,
	IUpdateAdmin,
	getAdminById,
	updateAdmin,
} from '../../../../services/store/slices/admins.slice';
import { sanitizeData } from '../../../../utils/helpers/common';
import Scrollbar from '../../../primitives/scrollbar/Scrollbar';
import { IndustriesDisplayNames } from '../../../../utils/helpers/constants';
import ModalDeleteAdmin from '../../modals/modal-delete-admin/ModalDeleteAdmin';
import Error from '../../../primitives/error/Error';
import EditAdminSkeleton from './EditAdminSkeleton';
import useAdmins from '../../../../utils/helpers/hooks/useAdmins';
import Loader from '../../../primitives/loader/Loader';

const EditAdmin: FC<IEditAdmin> = ({
	adminId,
	closeHandler,
	onUpdateError,
	onUpdateSuccess,
	onDeleteSuccess,
	onDeleteError,
	open,
}) => {
	const dispatch = useAppDispatch();
	const { updateLoading } = useAdmins();

	const {
		register,
		handleSubmit,
		getValues,
		reset,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
	});

	const [currentAdmin, setCurrentAdmin] = useState<IAdmin | null>(null);

	const { id, firstName, lastName, email, title, company } = currentAdmin || {};

	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [showBrowserLeaveWarning, setBrowserShowLeaveWarning] = useState(false);
	const [error, setError] = useState('');
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const [loading, setLoading] = useState(false);

	const onFormSubmitHandler = (data: Partial<IUpdateAdmin>) => {
		if (Object.keys(errors).length) return;

		const newData = sanitizeData(data);

		handleWithTryCatch(
			async () => {
				if (id) {
					await dispatch(updateAdmin(id, newData));
					onUpdateSuccess();
				}
			},
			setError,
			onUpdateError,
		);
	};

	const formActions = (
		<ActionsBlock className={styles.actions}>
			<Button
				className={styles.action}
				type="button"
				width={136}
				onClick={() => setDeleteModalOpen(true)}
				negative
			>
				Delete
			</Button>

			<Button
				className={styles.action}
				type="submit"
				width={137}
				disabled={!!Object.keys(errors).length || updateLoading}
			>
				{updateLoading ? <Loader thin maxHeight={14} maxWidth={14} /> : 'Save'}
			</Button>
		</ActionsBlock>
	);

	const renderDetails = () => {
		return (
			<>
				<div className={styles['input-group']}>
					<Input
						{...register('firstName', {
							...requireValidationPattern(),
							...nameValidationPattern(),
						})}
						defaultValue={firstName}
						className={styles.input}
						withErrorStyle={!!errors.firstName}
						type="text"
						label="First Name"
					/>

					<Input
						{...register('lastName', {
							...requireValidationPattern(),
							...nameValidationPattern(),
						})}
						defaultValue={lastName}
						className={styles.input}
						withErrorStyle={!!errors.lastName}
						type="text"
						label="Last Name"
					/>
				</div>

				<div className={styles['input-group']}>
					<Input
						disabled
						// {...register('email', {
						// 	...requireValidationPattern(),
						// 	...emailValidationPattern(),
						// })}
						name="email"
						value={email}
						//withErrorStyle={!!errors.email || error.includes('email')}
						// error={
						// 	error.includes('email')
						// 		? 'Email already exists in Gravity Security GRC'
						// 		: ''
						// }
						className={styles.input}
						type="text"
						label="Email"
					/>

					<Input
						disabled
						defaultValue={company?.name}
						className={styles.input}
						type="text"
						label="Company"
					/>
				</div>

				<div className={styles['input-group']}>
					<Input
						{...register('title', { ...nameValidationPattern() })}
						defaultValue={title}
						className={styles.input}
						withErrorStyle={!!errors.title}
						type="text"
						label="Title"
					/>

					<Input
						disabled
						value={
							IndustriesDisplayNames[
								currentAdmin?.industry as keyof typeof IndustriesDisplayNames
							]
						}
						className={styles.input}
						type="text"
						label="Industry"
					/>
				</div>
			</>
		);
	};

	const editAdmin = (
		<div className={styles['edit-admin']}>
			<form onSubmit={handleSubmit(onFormSubmitHandler)}>
				<Scrollbar className={styles.scrollbar}>
					<div className={styles.content}>{renderDetails()}</div>
				</Scrollbar>

				{formActions}
			</form>
		</div>
	);

	const changesWereMade = useCallback(() => {
		const currentValues = getValues();

		if (id) {
			return (
				currentValues.firstName !== firstName ||
				currentValues.lastName !== lastName ||
				//currentValues.email !== email ||
				currentValues.title !== title
			);
		}

		return false;
	}, [firstName, getValues, id, lastName, title]);

	const modals = (
		<>
			<ModalLeaveWarning
				open={warningModalOpen}
				setOpen={setWarningModalOpen}
				onConfirm={() => {
					setBrowserShowLeaveWarning(false);
					closeHandler();
				}}
			/>

			<ModalDeleteAdmin
				open={deleteModalOpen}
				setOpen={setDeleteModalOpen}
				id={id || ''}
				name={`${firstName} ${lastName}`}
				onDeleteSuccess={onDeleteSuccess}
				onDeleteError={onDeleteError}
				onCancel={() => setDeleteModalOpen(false)}
			/>
		</>
	);

	useLeaveWarning(showBrowserLeaveWarning);

	useEffect(() => {
		if (changesWereMade()) setBrowserShowLeaveWarning(true);
	}, [changesWereMade]);

	useEffect(() => {
		if (!open) {
			reset({}, { keepValues: false });
			setBrowserShowLeaveWarning(false);
		}
	}, [open, reset]);

	useEffect(() => {
		if (!currentAdmin && adminId) setLoading(true);
	}, [currentAdmin, adminId]);

	useEffect(() => {
		if (adminId && !currentAdmin && !loading && !error) {
			handleWithTryCatch(async () => {
				const admin = await dispatch(getAdminById(adminId));
				if (admin) setCurrentAdmin(admin);
				setLoading(false);
			});
		}
	}, [adminId, currentAdmin, dispatch, error, loading]);

	useEffect(() => {
		if (error) setLoading(false);
	}, [error]);

	useEffect(() => {
		if (!open) {
			setCurrentAdmin(null);
			setError('');
		}
	}, [open]);

	return (
		<Drawer
			contentClassName={styles.drawer}
			open={open}
			title="Company Admin Details"
			onCloseClickHandler={() => {
				if (changesWereMade()) setWarningModalOpen(true);
				else closeHandler();
			}}
		>
			{error ? <Error message={error} /> : null}

			{loading ? <EditAdminSkeleton /> : null}

			{!loading && id ? editAdmin : null}

			{modals}
		</Drawer>
	);
};

export default EditAdmin;
