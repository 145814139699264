import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ResetPassword.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppUrls } from '../../utils/helpers/constants/app-routes';
import EmailStep from './steps/email-step/EmailStep';
import ConfirmationStep from './steps/confirmation-step/ConfirmationStep';
import { useAppDispatch } from '../../services/store';
import {
	getPasswordRequirements,
	initiatePasswordReset,
	updatePassword,
} from '../../services/store/slices/authorization.slice';
import classNames from 'classnames';
import PasswordStep from './steps/password-step/PasswordStep';
import { handleWithTryCatch } from '../../utils/helpers/errors';
import BasicPanel from '../../components/primitives/basic-panel/BasicPanel';
import { QueryParams } from '../../utils/helpers/constants';
import useAuthorization from '../../utils/helpers/hooks/useAuthorization';
import Loader from '../../components/primitives/loader/Loader';
import { sanitize } from 'dompurify';
import { checkTokenExpiration } from '../../utils/helpers/common';

const ResetPassword: FC = () => {
	const { passwordRequirements, loading: authorizationLoading } = useAuthorization();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();

	const context = useMemo(() => {
		return location.pathname === AppUrls.createPassword ? 'create' : 'reset';
	}, [location.pathname]);

	const [currentStep, setCurrentStep] = useState(context === 'create' ? 'password' : 'email');
	const [email, setEmail] = useState('');
	const [token, setToken] = useState('');
	const [error, setError] = useState('');

	const onResetInitiation = useCallback(async () => {
		if (email) {
			const newEmail = sanitize(email);

			handleWithTryCatch(async () => {
				await dispatch(initiatePasswordReset(newEmail));
			});

			if (currentStep === 'email') setCurrentStep('confirmation');
		}
	}, [currentStep, dispatch, email]);

	const onPasswordUpdate = useCallback(
		(password?: string) => {
			if (password && token) {
				handleWithTryCatch(async () => {
					await dispatch(updatePassword(password, token));
					navigate(AppUrls.login);
				}, setError);
			}
		},
		[dispatch, navigate, token],
	);

	const resetPasswordsSteps = [
		{
			stepName: 'email',
			component: (
				<EmailStep
					context={context}
					error={error}
					setError={setError}
					email={email}
					setEmail={setEmail}
					onSubmitHandler={onResetInitiation}
				/>
			),
		},
		{
			stepName: 'confirmation',
			component: (
				<ConfirmationStep
					context={context}
					email={email}
					onSubmitHandler={onResetInitiation}
				/>
			),
		},
		{
			stepName: 'password',
			component: (
				<PasswordStep context={context} error={error} onSubmitHandler={onPasswordUpdate} />
			),
		},
	];

	const onBackClick = () => {
		if (currentStep === 'email') navigate(AppUrls.login);
		else {
			setCurrentStep('email');

			if (location.search.includes(QueryParams.token))
				navigate(AppUrls.resetPassword, {
					replace: true,
				});
		}
	};

	const getPanelTitle = () => {
		if (context === 'create') {
			if (currentStep === 'confirmation') return 'Confirm Email';
			return 'Create Password';
		}

		return 'Reset Password';
	};

	useEffect(() => {
		if (error && error.includes('expired')) {
			setCurrentStep('email');
		}
	}, [error]);

	useEffect(() => {
		if (location.search.includes(QueryParams.token)) {
			const urlParams = new URLSearchParams(location.search);
			let verificationToken = urlParams.get(QueryParams.token) || '';

			if (verificationToken) {
				const tokenIsValid = checkTokenExpiration(verificationToken);

				if (tokenIsValid) {
					setToken(verificationToken);
					setCurrentStep('password');
				} else {
					setError('Your link has expired. Please try again.');
					setCurrentStep('email');
				}
			}
		}
	}, [dispatch, location.hash, location.search]);

	useEffect(() => {
		if (currentStep === 'password' && !passwordRequirements) {
			handleWithTryCatch(() => dispatch(getPasswordRequirements()));
		}
	}, [currentStep, dispatch, passwordRequirements]);

	if (authorizationLoading) return <Loader className={styles.loader} thin />;

	return (
		<BasicPanel
			className={classNames(styles['reset-password'], styles[currentStep])}
			title={getPanelTitle()}
			withLogo
			withBackNavigation={context === 'reset'}
			onBackClick={onBackClick}
		>
			{resetPasswordsSteps.find((step) => step.stepName === currentStep)?.component}
		</BasicPanel>
	);
};

export default ResetPassword;
