import { FC, useEffect, useState } from 'react';
import styles from './ControlsStep.module.scss';
import ActionsBlock from '../../../../../primitives/actions-block/ActionsBlock';
import Button from '../../../../../primitives/button/Button';
import classNames from 'classnames';
import IControlsStep from './IControlsStep';
import AddControlsTable from '../../../../tables/add-controls-table/AddControlsTable';
import Loader from '../../../../../primitives/loader/Loader';
import useBaseControls from '../../../../../../utils/helpers/hooks/useBaseControls';
import { handleWithTryCatch } from '../../../../../../utils/helpers/errors';
import { useAppDispatch } from '../../../../../../services/store';
import { getBaseControlsByType } from '../../../../../../services/store/slices/base-controls.slice';
import useFrameworks from '../../../../../../utils/helpers/hooks/useFrameworks';
import { getFrameworkTypeAndSubType } from '../../../../../../utils/helpers/common';
import { IIdName } from '../../../../../../utils/types';
import { FrameworkSubTypes, FrameworkTypes } from '../../../../../../utils/helpers/constants';
import usePolicies from '../../../../../../utils/helpers/hooks/usePolicies';

const ControlsStep: FC<IControlsStep> = ({
	currentFormState,
	setOpen,
	className,
	onFormSubmit,
}) => {
	const dispatch = useAppDispatch();
	const { loading } = useBaseControls();
	const { loading: policiesLoading } = usePolicies();
	const { items: frameworkItems } = useFrameworks();

	const [selectedControls, setSelectedControls] = useState<string[]>(
		currentFormState.controls || [],
	);

	const [controlsToDisplay, setControlsToDisplay] = useState<IIdName[]>([]);

	const onAddClick = () => {
		onFormSubmit(selectedControls);
	};

	const onSkipClick = () => onFormSubmit();

	const formActions = (
		<ActionsBlock className={styles.actions}>
			<Button type="button" width={136} onClick={() => setOpen(false)} negative>
				Cancel
			</Button>

			<div className={styles['actions-group']}>
				<Button
					className={styles.action}
					type="button"
					width={136}
					onClick={onSkipClick}
					negative
					disabled={loading || policiesLoading}
				>
					{loading || policiesLoading ? (
						<Loader thin maxHeight={14} maxWidth={14} />
					) : (
						'Skip'
					)}
				</Button>

				<Button
					className={styles.action}
					disabled={!selectedControls.length || loading || policiesLoading}
					type="button"
					width={132}
					onClick={onAddClick}
				>
					{loading || policiesLoading ? (
						<Loader thin maxHeight={14} maxWidth={14} />
					) : (
						'Add Selected'
					)}
				</Button>
			</div>
		</ActionsBlock>
	);

	useEffect(() => {
		handleWithTryCatch(async () => {
			const selectedFramework = frameworkItems?.find(
				(fr) => fr.id === currentFormState.framework,
			);

			const frameworkTypeData = getFrameworkTypeAndSubType(selectedFramework?.name || '');

			const controlsForType = await dispatch(
				getBaseControlsByType(
					FrameworkTypes[frameworkTypeData?.type as keyof typeof FrameworkTypes],
					FrameworkSubTypes[frameworkTypeData?.subType as keyof typeof FrameworkSubTypes],
				),
			);

			setControlsToDisplay(controlsForType || []);
		});
	}, [currentFormState.framework, dispatch, frameworkItems]);

	return (
		<div className={classNames(styles['add-controls'], className)}>
			<AddControlsTable
				data={controlsToDisplay || []}
				selectedRows={selectedControls}
				setSelectedRows={setSelectedControls}
				withSearch={false}
			/>

			{formActions}
		</div>
	);
};

export default ControlsStep;
