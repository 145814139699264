import React, { FC, forwardRef, useEffect, useState } from 'react';
import IMultiSelect from './IMultiSelect';
import styles from './MultiSelect.module.scss';
import Error from '../../error/Error';
import classNames from 'classnames';
import { Multiselect as RWMultiSelect } from 'react-widgets';
import { ISelectOption } from '../../../../utils/types';
import OptionsListComponentOverride from './options-list-component-override/OptionsListComponentOverride';
import InputComponentOverride from './input-component-override/InputComponentOverride';

const MultiSelect: FC<IMultiSelect> = forwardRef<HTMLInputElement, IMultiSelect>(
	(
		{
			id,
			name,
			defaultValue,
			value,
			error,
			className,
			label,
			options,
			onValueChange,
			placeholder,
			disabled,
			isTagStyledList,
			isTagsWithoutBg,
			withSearch,
		},
		ref,
	) => {
		const [isOpened, setIsOpened] = useState(false);
		const [selectedOptions, setSelectedOptions] = useState<ISelectOption[]>([]);

		const onSelectChange = (newValue: ISelectOption[]) => {
			setSelectedOptions(newValue);
			if (onValueChange) onValueChange(newValue);
		};

		useEffect(() => {
			setSelectedOptions(value || defaultValue || []);
		}, [value, defaultValue]);

		return (
			<div
				className={classNames(
					className,
					styles['multiselect-wrapper'],
					disabled ? styles.disabled : '',
				)}
			>
				{label ? (
					<label htmlFor={id} className={styles.label}>
						{label}
					</label>
				) : null}

				<RWMultiSelect
					{...{ id, name, ref, disabled }}
					defaultValue={selectedOptions}
					value={selectedOptions}
					onChange={onSelectChange}
					data={options}
					dataKey={'value'}
					textField={'label'}
					showSelectedItemsInList
					listComponent={({ data }) => (
						<OptionsListComponentOverride
							options={data}
							selectedOptions={selectedOptions}
							onSelectChangeHandler={onSelectChange}
							isTagStyledList={isTagStyledList}
							isTagsWithoutBg={isTagsWithoutBg}
							withSearch={withSearch}
						/>
					)}
					tagListComponent={() => (
						<InputComponentOverride
							selectedOptions={selectedOptions}
							text={placeholder || 'Select'}
							onClearHandler={() => onSelectChange([])}
							openToggleHandler={() => setIsOpened((prev) => !prev)}
							isOpened={isOpened}
						/>
					)}
					popupComponent={(props) => (
						<div
							{...props}
							className={classNames(
								styles['multiselect-popup'],
								isOpened ? styles.opened : '',
							)}
						/>
					)}
					containerClassName={classNames(
						styles['multiselect-input'],
						isOpened ? styles.opened : '',
					)}
					onToggle={setIsOpened}
					open={isOpened}
					defaultOpen={true}
				/>

				{error ? <Error message={error} /> : null}
			</div>
		);
	},
);

export default MultiSelect;
