import { FC, useEffect, useMemo, useState } from 'react';
import styles from './ModalCreateCompany.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import StepsProgress from '../../../primitives/steps-progress/StepsProgress';
import Back from '../../../primitives/back/Back';
import { ICreateCompany, addNewCompany } from '../../../../services/store/slices/companies.slice';
import CompanyStep from './steps/company-step/CompanyStep';
import AdminStep from './steps/admin-step/AdminStep';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import { useAppDispatch } from '../../../../services/store';
import IModalCreateCompany from './IModalCreateCompany';
import { useForm } from 'react-hook-form';
import useFrameworks from '../../../../utils/helpers/hooks/useFrameworks';
import { FrameworkTypes } from '../../../../utils/helpers/constants';

const ModalCreateCompany: FC<IModalCreateCompany> = ({
	open,
	setOpen,
	onAddSuccess,
	onAddError,
}) => {
	const dispatch = useAppDispatch();
	const [currentStep, setCurrentStep] = useState('company');
	const { handleSubmit } = useForm();
	const [locationNameValue, setLocationNameValue] = useState('');
	const [locationTimeZone, setLocationTimeZone] = useState('');
	const [userError, setUserError] = useState(false);
	const { items: frameworkItems } = useFrameworks();
	const defaultFrameworksOptions = useMemo(() => {
		return (
			frameworkItems
				?.filter((framework) =>
					[FrameworkTypes.iso, FrameworkTypes.soc].includes(framework.type),
				)
				.map((item) => ({
					value: item.id,
					label: item.name,
				})) || []
		);
	}, [frameworkItems]);

	const [currentFormState, setCurrentFormState] = useState<ICreateCompany>({
		name: '',
		contactEmail: '',
		description: '',
		industry: '',
		lifetime: '',
		defaultStatementOnboardingFlow: false,
		defaultStatementOnboardingFrameworkId:
			frameworkItems?.find((f) => f.type === FrameworkTypes.soc)?.id || '',
		adminFirstName: '',
		adminLastName: '',
		adminEmail: '',
		adminTitle: '',
	});

	const createCompanySteps = [
		{
			stepName: 'company',
			component: (
				<CompanyStep
					currentFormState={currentFormState}
					setCurrentFormState={setCurrentFormState}
					setOpen={setOpen}
					setNextStep={() => setCurrentStep('admin')}
					locationNameValue={locationNameValue}
					setLocationNameValue={setLocationNameValue}
					setLocationTimeZone={setLocationTimeZone}
					defaultFrameworksOptions={defaultFrameworksOptions}
				/>
			),
		},
		{
			stepName: 'admin',
			component: (
				<AdminStep
					currentFormState={currentFormState}
					setCurrentFormState={setCurrentFormState}
					setOpen={setOpen}
					userError={userError}
				/>
			),
		},
	];

	const activeStepNumber = createCompanySteps.findIndex((step) => step.stepName === currentStep);

	const onFormSubmit = () => {
		if (userError) setUserError(false);

		handleWithTryCatch(
			async () => {
				const addedCompanyId = await dispatch(
					addNewCompany({
						...currentFormState,
						location: {
							name: locationNameValue || '',
							timeZone: locationTimeZone || '',
						},
					}),
				);
				onAddSuccess(addedCompanyId || '');
				setOpen(false);
			},
			undefined,
			(message) => {
				const isUserError = message?.includes('already exists');

				onAddError(isUserError);
				if (isUserError) setUserError(true);
			},
		);
	};

	useEffect(() => {
		if (!open) {
			setCurrentStep('company');
			setLocationNameValue('');
			setLocationTimeZone('');
			setCurrentFormState({
				name: '',
				contactEmail: '',
				description: '',
				industry: '',
				lifetime: '',
				defaultStatementOnboardingFlow: false,
				defaultStatementOnboardingFrameworkId:
					frameworkItems?.find((f) => f.type === FrameworkTypes.soc)?.id || '',
				adminFirstName: '',
				adminLastName: '',
				adminEmail: '',
				adminTitle: '',
			});
		}
	}, [open, frameworkItems]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<StepsProgress
					small
					className={styles.progress}
					stepsNumber={createCompanySteps.length}
					activeStep={activeStepNumber}
				/>

				<div className={currentStep === 'admin' ? styles.title : ''}>
					{currentStep === 'admin' ? (
						<Back className={styles.back} onClick={() => setCurrentStep('company')} />
					) : null}

					<h2>Add Company</h2>
				</div>

				<form onSubmit={handleSubmit(onFormSubmit)}>
					{createCompanySteps.find((step) => step.stepName === currentStep)?.component}
				</form>
			</BasicPanel>
		</Modal>
	);
};

export default ModalCreateCompany;
