import React from 'react';
import styles from './Navigation.module.scss';
import { AppRoutes } from '../../../utils/helpers/constants/app-routes';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import useUser from '../../../utils/helpers/hooks/useUser';

const Navigation = () => {
	const location = useLocation();
	const { info: userInfo } = useUser();

	const renderLinks = () => {
		return AppRoutes.filter(
			(route) => route.type === 'protected' && !route.excludeFromNavigation,
		).map((route) => {
			const isActive = location.pathname === route.url;

			return (
				<Link
					to={route.url}
					key={route.key}
					className={classNames(
						styles.item,
						styles[route.key],
						isActive ? styles.active : '',
					)}
				>
					{route.text}
				</Link>
			);
		});
	};
	return (
		<div className={styles.navigation}>
			{userInfo ? <nav className={styles.items}>{renderLinks()}</nav> : null}
		</div>
	);
};

export default React.memo(Navigation);
