import { Dispatch } from 'react';
import { createSlice, PayloadAction, UnknownAction } from '@reduxjs/toolkit';
import authorizationApi from '../../api/authorization.api';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import { IPasswordRequirements } from '../../../utils/types';
import { transformPasswordRequirements } from '../../../utils/helpers/common';
import { setUserIsAuthorized, setUserIsUnauthorized } from './user.slice';

const SOFTWARE_TOKEN_MFA_CHALLENGE_NAME = 'SOFTWARE_TOKEN_MFA';

export interface ILoginResponse {
	challengeName: string;
	loginSession: string;
	userIdForSRP: string;
}

export interface IAuthorizationSlice {
	loading?: boolean;
	passwordRequirements?: IPasswordRequirements | null;
	qrCode?: string;
	secretCode?: string;
	mfaCodeRequired?: boolean;
	loginSession?: string | null;
	userIdForSRP?: string | null;
}

export interface IAuthorizationPayload {
	email: string;
	password: string;
}

export interface IAuthorizationPassword {
	password: string;
	confirmPassword: string;
}

export interface IValidateMfaCode {
	userCode: string;
}

export interface IValidateMfaCodeResponse {
	qrCode: string;
	secretCode: string;
}

export interface IVerifyMfaCode {
	userIdForSRP: string;
	authenticatorCode: string;
	loginSession: string;
}

const initialState: IAuthorizationSlice = {
	loading: false,
	passwordRequirements: null,
	mfaCodeRequired: false,
	loginSession: null,
};

export const authorizationSlice = createSlice({
	name: 'authorization',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		setPasswordRequirements: (state, action) => {
			state.passwordRequirements = action.payload;
		},
		setQrCodeResponse: (state, { payload }: PayloadAction<IValidateMfaCodeResponse>) => {
			state.qrCode = payload.qrCode;
			state.secretCode = payload.secretCode;
		},
		clearQrCode: (state) => {
			state.qrCode = undefined;
			state.secretCode = undefined;
		},
		setIsMFACodeRequired: (
			state,
			{ payload }: PayloadAction<Omit<ILoginResponse, 'challengeName'>>,
		) => {
			state.mfaCodeRequired = true;
			state.loginSession = payload.loginSession;
			state.userIdForSRP = payload.userIdForSRP;
		},
		setIsMFACodeNotRequired: (state) => {
			state.mfaCodeRequired = false;
			state.loginSession = null;
			state.userIdForSRP = null;
		},
	},
});

export const getUserTwoFactorQrCode =
	(withLoading: boolean = true) =>
	async (dispatch: Dispatch<any>) => {
		if (withLoading) dispatch(loading());

		try {
			const qrCodeResponse = await authorizationApi.getQrCode();

			dispatch(setQrCodeResponse(qrCodeResponse));
			return qrCodeResponse;
		} catch (error: any) {
			dispatch(clearQrCode());
			throw new CommonResponseError('Error while getting QR code');
		} finally {
			dispatch(loaded());
		}
	};

export const validateMFACode = (data: IValidateMfaCode) => async (dispatch: Dispatch<any>) => {
	try {
		const validateMFAResponse = await authorizationApi.validateMFA(data.userCode);
		return validateMFAResponse;
	} catch (error: any) {
		console.log('error', error);
		throw new CommonResponseError(error.message || 'Error while setting MFA code');
	}
};

export const verifyMFACode = (data: IVerifyMfaCode) => async (dispatch: Dispatch<any>) => {
	try {
		await authorizationApi.verifyMFA(data);
		dispatch(setUserIsAuthorized());
		// dispatch(setLoggedOut(false)); // TODO: check if this is needed
		dispatch(setIsMFACodeNotRequired());
	} catch (error: any) {
		console.log('error', error);
		throw new CommonResponseError(error.message || 'Error while setting MFA code');
	}
};

export const disableMFACode = () => async (dispatch: Dispatch<any>) => {
	try {
		const disableMFAResponse = await authorizationApi.disableMFA();
		return disableMFAResponse;
	} catch (error: any) {
		console.log('error', error);
		throw new CommonResponseError(error.message || 'Error while setting MFA code');
	}
};

export const login =
	(data: Partial<IAuthorizationPayload>) => async (dispatch: Dispatch<UnknownAction>) => {
		dispatch(loading());

		try {
			const loginResp = await authorizationApi.login(data);
			if (loginResp && loginResp.challengeName === SOFTWARE_TOKEN_MFA_CHALLENGE_NAME) {
				dispatch(
					setIsMFACodeRequired({
						loginSession: loginResp.loginSession,
						userIdForSRP: loginResp.userIdForSRP,
					}),
				);
			} else {
				dispatch(setUserIsAuthorized());
			}
		} catch (error) {
			throw new CommonResponseError('Incorrect email or password');
		} finally {
			dispatch(loaded());
		}
	};

export const initiatePasswordReset = (email: string) => async () => {
	await authorizationApi.initiatePasswordReset(email);
};

export const updatePassword =
	(password: string, token?: string) => async (dispatch: Dispatch<UnknownAction>) => {
		dispatch(loading());

		try {
			await authorizationApi.confirmPasswordReset(password, token);
		} catch (error) {
			throw new CommonResponseError('Error while updating password');
		} finally {
			dispatch(loaded());
		}
	};

export const getPasswordRequirements = () => async (dispatch: Dispatch<UnknownAction>) => {
	dispatch(loading());

	try {
		const requirements = await authorizationApi.getPasswordRequirements();

		const transformedInstructions = transformPasswordRequirements(requirements);
		dispatch(setPasswordRequirements(transformedInstructions));
	} catch (error) {
		throw new CommonResponseError('Error while getting password requirements');
	} finally {
		dispatch(loaded());
	}
};

export const logOut = () => async (dispatch: Dispatch<any>) => {
	try {
		await authorizationApi.logout();
	} catch (error) {
	} finally {
		dispatch(setUserIsUnauthorized());
	}
};

export const {
	loading,
	loaded,
	setPasswordRequirements,
	clearQrCode,
	setIsMFACodeNotRequired,
	setIsMFACodeRequired,
	setQrCodeResponse,
} = authorizationSlice.actions;

export default authorizationSlice.reducer;
