import { useSelector } from 'react-redux';
import { RootState } from '../../../services/store';

export default function useCrosswalk() {
	const { loading, updateLoading, nistItems, isoItems, socItems } = useSelector(
		(store: RootState) => store.crosswalk,
	);

	return {
		loading,
		updateLoading,
		nistItems,
		isoItems,
		socItems,
	};
}
