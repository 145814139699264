import { FC, useCallback, useEffect, useState } from 'react';
import Table from '../../../primitives/table/Table';
import { getBriefControlsColumns } from './utils';
import styles from './AddControlsTable.module.scss';
import { IIdName, IKeyValuePair, ISortProps } from '../../../../utils/types';
import { processTableData } from '../../../../utils/helpers/common';
import classNames from 'classnames';
import IAddControlsTable from './IAddControlsTable';
import SearchFilter from '../../../primitives/filters/search-filter/SearchFilter';
import TableSkeleton from '../../../primitives/table/TableSkeleton';

const AddControlsTable: FC<IAddControlsTable> = ({
	className,
	data,
	selectedRows,
	setSelectedRows,
	bodyMaxHeight = 360,
	withSearch = true,
}) => {
	const [dataToDisplay, setDataToDisplay] = useState<IIdName[]>([]);
	const [isProcessing, setIsProcessing] = useState(true);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IIdName) => true,
	});

	useEffect(() => {
		if (data.length) {
			setIsProcessing(true);
			const proccessedData: IIdName[] = processTableData(data, currentFilters, currentSort);

			setDataToDisplay(proccessedData);
			setIsProcessing(false);
		} else {
			setIsProcessing(false);
		}
	}, [currentFilters, currentSort, data]);

	return (
		<div className={classNames(styles['table-wrap'], className)}>
			{withSearch ? (
				<SearchFilter
					className={styles.search}
					properties={['displayId', 'name']}
					setFilters={setCurrentFilters}
				/>
			) : null}

			{isProcessing ? (
				<TableSkeleton rowsNumber={4} columns={getBriefControlsColumns()} />
			) : (
				<Table
					tableKey="add-control"
					data={dataToDisplay}
					columns={getBriefControlsColumns()}
					options={{
						rowCheckbox: true,
						rowClassName: styles.row,
						onSortChange,
						emptyResultMessage: !data.length
							? 'No controls to display.'
							: 'No matches found. Please try another search query.',
						emptyResultMessageType: !data.length ? 'common' : 'search',
						bodyMaxHeight,
						small: true,
						scrollFullRightPosition: true,
						selectedRows,
						setSelectedRows,
					}}
				/>
			)}
		</div>
	);
};

export default AddControlsTable;
