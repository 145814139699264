import { combineReducers, configureStore } from '@reduxjs/toolkit';

import authorization from './slices/authorization.slice';
import user from './slices/user.slice';
import admins from './slices/admins.slice';
import companies from './slices/companies.slice';
import policies from './slices/policies.slice';
import frameworks from './slices/frameworks.slice';
import crosswalk from './slices/crosswalk.slice';
import aiLogs from './slices/ai-logs.slice';
import baseControls from './slices/base-controls.slice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const env = process.env.REACT_APP_ENV;

const appReducers = combineReducers({
	authorization,
	user,
	admins,
	companies,
	policies,
	frameworks,
	crosswalk,
	aiLogs,
	baseControls,
});

const store = configureStore({
	reducer: appReducers,
	devTools: env !== 'production',
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
