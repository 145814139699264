import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppQueryParams } from '../../utils/helpers/constants';
import useAILogs from '../../utils/helpers/hooks/useAILogs';
import { handleWithTryCatch } from '../../utils/helpers/errors';
import { useAppDispatch } from '../../services/store';
import { getAILogs } from '../../services/store/slices/ai-logs.slice';
import AILogsTable from '../../components/partials/tables/ai-logs/AILogsTable';

const AILogs = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const { items: logItems } = useAILogs();
	const [loading, setLoading] = useState(true);

	const navigateToItem = useMemo(() => {
		if (!loading && logItems) {
			const urlParams = new URLSearchParams(location.search);
			const logIdFromUrl = urlParams.get(AppQueryParams.item) || '';

			if (logIdFromUrl) {
				const log = logItems?.find((item) => item.id === logIdFromUrl);

				if (log) return logIdFromUrl;
			}
		}
	}, [loading, logItems, location.search]);

	useEffect(() => {
		handleWithTryCatch(() => dispatch(getAILogs()));
	}, [dispatch]);

	useEffect(() => {
		if (logItems) setLoading(false);
	}, [logItems]);

	return (
		<>
			<h2>AI Logs</h2>

			<AILogsTable data={logItems || []} dataLoading={loading} showItemId={navigateToItem} />
		</>
	);
};

export default AILogs;
