import { useMemo } from 'react';
import { CommonStatusDisplayNames, StatusColors } from '../../../utils/helpers/constants';
import { IPill } from './Pill.types';
import styles from './Pill.module.scss';

export const Pill = ({ className, status, children }: IPill) => {
	const statusContent = useMemo(
		() => CommonStatusDisplayNames[status === 'in-progress' ? 'inProgress' : status],
		[status],
	);
	return (
		<div
			className={`${className} ${styles.pill}`}
			style={{
				backgroundColor: StatusColors[status === 'in-progress' ? 'inProgress' : status],
			}}
		>
			{children ? children : statusContent}
		</div>
	);
};
