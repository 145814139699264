import React, { FC } from 'react';
import ILogAttemptsBlock from './ILogAttemptsBlock';
import styles from './LogAttemptsBlock.module.scss';
import classNames from 'classnames';
import CollapsiblePanel from '../../primitives/collapsible-panel/CollapsiblePanel';
import { IAILogJobAttemptItem } from '../../../services/store/slices/ai-logs.slice';
import AttemptFiles from '../attempt-files/AttemptFiles';

const LogAttemptsBlock: FC<ILogAttemptsBlock> = ({ attempts, className, status }) => {
	const renderAttempts = () => {
		const sortedAttempts = attempts.sort((a, b) => b.attempt - a.attempt);

		return sortedAttempts.map((attempt: IAILogJobAttemptItem) => {
			return (
				<CollapsiblePanel
					className={styles['attempt-block']}
					trigger={<div className={styles['attempt-name']}>{attempt.attemptText}</div>}
					triggerClassName={styles['attempt-trigger']}
				>
					<AttemptFiles files={attempt.files} status={status} />
				</CollapsiblePanel>
			);
		});
	};

	return (
		<div className={classNames(className, styles['log-attempts-block'])}>
			{renderAttempts()}
		</div>
	);
};

export default LogAttemptsBlock;
