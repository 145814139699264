import { dateInMDYTimeFormat } from '../../../../utils/helpers/common';
import { CommonStatusDisplayNames, StatusColors } from '../../../../utils/helpers/constants';
import { ITableColumn } from '../../../primitives/table/ITable';
import ColoredTag from '../../../primitives/tag/ColoredTag';

export const getAILogsColumns = () => {
	return [
		{
			header: 'Job ID',
			key: 'id',
			sorting: true,
			width: '12%',
		},
		{
			header: 'Company Name',
			key: 'companyName',
			sorting: true,
			width: '23%',
		},
		{
			header: 'Status',
			key: 'status',
			sorting: true,
			width: '15%',
			columnRender: (status: string) => {
				const statusColor = StatusColors[status as keyof typeof StatusColors];
				return (
					<ColoredTag
						tagId={status}
						key={status}
						text={CommonStatusDisplayNames[status as keyof typeof CommonStatusDisplayNames]}
						bgColor={statusColor}
					/>
				);
			},
		},
		{
			header: 'Attempt',
			key: 'attempt',
			sorting: true,
			width: '14%',
		},
		{
			header: 'Created At',
			key: 'createdAt',
			sorting: true,
			width: '18%',
			columnRender: (date: Date) => {
				const formattedDate = dateInMDYTimeFormat(date);
				return (
					<ColoredTag
						borderColor={'#F4F9EB'}
						textColor="#7A926A"
						tagId={formattedDate}
						text={formattedDate}
						withoutBackground
					/>
				);
			},
		},
		{
			header: 'Updated At',
			key: 'updatedAt',
			sorting: true,
			width: '18%',
			columnRender: (date: Date) => {
				const formattedDate = dateInMDYTimeFormat(date);
				return (
					<ColoredTag
						borderColor={'#F4F9EB'}
						textColor="#7A926A"
						tagId={formattedDate}
						text={formattedDate}
						withoutBackground
					/>
				);
			},
		},
	] as ITableColumn[];
};
