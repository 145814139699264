import axios from 'axios';
import { ADMINS } from './api.path';
import { IAdmin, IAdminBasic, ICreateAdmin } from '../store/slices/admins.slice';

const adminsApi = {
	getAdmins: (): Promise<IAdminBasic[]> => {
		return axios.get(`${ADMINS}/admins`);
	},
	getAdminById: (adminId: string): Promise<IAdminBasic> => {
		return axios.get(`${ADMINS}/${adminId}`);
	},
	deleteAdmin: (adminId: string): Promise<IAdminBasic> => {
		return axios.delete(`${ADMINS}/${adminId}`);
	},
	updateAdmin: (data: Partial<IAdmin>): Promise<IAdminBasic> => {
		return axios.put(`${ADMINS}`, data);
	},
	addNewAdmin: (data: ICreateAdmin): Promise<IAdminBasic> => {
		return axios.post(`${ADMINS}`, data);
	},
};

export default adminsApi;
