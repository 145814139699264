import { useEffect } from 'react';
import useUser from './useUser';
import { useAppDispatch } from '../../../services/store';
import { handleWithTryCatch } from '../errors';
import { getAdmins } from '../../../services/store/slices/admins.slice';
import useCompanies from './useCompanies';

export default function useGetAdmins() {
	const dispatch = useAppDispatch();
	const { authorized, info } = useUser();
	const { items: companyItems } = useCompanies();

	useEffect(() => {
		if (authorized && info?.email && companyItems) {
			handleWithTryCatch(() => dispatch(getAdmins()));
		}
	}, [authorized, dispatch, companyItems, info?.email]);
}
