import axios from 'axios';
import store from '../services/store';
import { setUserIsAuthorized, setUserIsUnauthorized } from '../services/store/slices/user.slice';
import {
	PASSWORD_REQUIREMENTS,
	INITIATE_RESET_PASSWORD,
	CONFIRM_RESET_PASSWORD,
	LOGOUT,
	LOGIN,
} from '../services/api/api.path';

const setupAxios = () => {
	setBaseUrl();
	setInterceptors();
};

const { dispatch } = store;

const setBaseUrl = () => {
	const env = process.env.REACT_APP_ENV;

	const url = {
		localDevelopment: 'http://localhost:8080/api/v1',
		development: 'https://development.api.gravitygrc.com/api/v1',
		staging: 'http://ec2-3-141-43-182.us-east-2.compute.amazonaws.com/api/v1',
		production: 'https://production.api.gravitygrc.com/api/v1',
	};

	let baseUrl;

	if (env) baseUrl = url[env as keyof typeof url];

	axios.defaults.baseURL = baseUrl || url.production;
	axios.defaults.withCredentials = true;
};

const setInterceptors = () => {
	axios.interceptors.response.use(
		(response) => {
			const { data } = response;

			const unauthorizedRoutes = [
				PASSWORD_REQUIREMENTS,
				CONFIRM_RESET_PASSWORD,
				INITIATE_RESET_PASSWORD,
				LOGIN,
			];

			if (response.config.url && !unauthorizedRoutes.includes(response.config.url)) {
				dispatch(setUserIsAuthorized());
			}

			return data;
		},
		(error) => {
			if (error?.response?.status) {
				const { data, status } = error.response;

				switch (status) {
					case 401:
						dispatch(setUserIsUnauthorized());
						if (error?.response?.config.url !== LOGOUT) return Promise.reject(data);
						break;

					default:
						return Promise.reject(data);
				}

				return Promise.reject(data);
			}

			return Promise.reject(error);
		},
	);
};

export default setupAxios;
