import { FC, useEffect, useState } from 'react';
import styles from './ControlsBlock.module.scss';
import classNames from 'classnames';
import ColoredTag from '../../primitives/tag/ColoredTag';
import { CommonColors } from '../../../utils/helpers/constants';
import HoverTooltip from '../../primitives/tooltip/HoverTooltip';
import Button from '../../primitives/button/Button';
import ModalAddControls from '../modals/modal-add-controls/ModalAddControls';
import IControlsBlock from './IControlsBlock';
import useBaseControls from '../../../utils/helpers/hooks/useBaseControls';

const ControlsBlock: FC<IControlsBlock> = ({ className, entityControlIds, setControlIds, controlsType }) => {
	const { baseControls: baseControlItems } = useBaseControls();

	const [openAddControlsModal, setOpenAddControlsModal] = useState(false);
	const [addedControlIds, setAddedControlIds] = useState<string[]>([]);

	const entityControlObjs = baseControlItems?.filter((control) => {
		return entityControlIds?.includes(control.id!);
	});

	const onRemoveControlClick = async (controlId: string) => {
		const newControls = entityControlIds?.filter((control) => control !== controlId);

		if (setControlIds) setControlIds(newControls);
	};

	const renderControls = () => {
		if (entityControlObjs?.length)
			return (
				<div className={styles['controls']}>
					{entityControlObjs?.map((control) => {
						return (
							<ColoredTag
								tagId={control.id}
								key={control.id}
								text={control.displayId!}
								bgColor={CommonColors.defaultTagBg}
								withoutBackground
								tooltipId={'tooltip-control-name'}
								tooltipContent={control.name || ''}
								withRemoveOnHover
								onRemoveClickHandler={() => onRemoveControlClick(control.id)}
								className={classNames(
									styles.tag,
									addedControlIds.length && addedControlIds.includes(control.id)
										? styles['added-control']
										: '',
								)}
							/>
						);
					})}
				</div>
			);
	};

	const onNewControlsAdded = (newControls: string[]) => {
		setAddedControlIds(newControls);
		if (setControlIds) setControlIds([...entityControlIds, ...newControls]);
	};

	const renderEmptyState = () => {
		if (!entityControlIds?.length)
			return (
				<p className={styles.empty}>
					You don't have any controls. Click "Add Control" to add a control.
				</p>
			);
	};

	const renderActions = () => {
		return (
			<div className={styles.actions}>
				<Button
					type="button"
					negative
					small
					width={104}
					className={styles.action}
					onClick={() => setOpenAddControlsModal(true)}
				>
					Add Control
				</Button>
			</div>
		);
	};

	const modals = (
		<>
			<ModalAddControls
				open={openAddControlsModal}
				setOpen={setOpenAddControlsModal}
				entityControlIds={entityControlIds}
				setEntityControls={setControlIds!}
				onNewAdded={onNewControlsAdded}
				controlsType={controlsType}
			/>
		</>
	);

	useEffect(() => {
		if (addedControlIds.length) {
			const addDelay = setTimeout(() => setAddedControlIds([]), 2000);

			return () => clearTimeout(addDelay);
		}
	}, [addedControlIds]);

	return (
		<div className={classNames(styles['controls-block'], className)}>
			{renderEmptyState()}

			{renderControls()}

			{renderActions()}

			<HoverTooltip tooltipId="tooltip-control-name" place="bottom-start" />
			<HoverTooltip warning withIcon tooltipId="tooltip-remove-warning" />

			{modals}
		</div>
	);
};

export default ControlsBlock;
