import React, { FC } from 'react';
import styles from './EmailStep.module.scss';
import Button from '../../../../components/primitives/button/Button';
import Input from '../../../../components/primitives/form/input/Input';
import { emailIsValid } from '../../../../utils/helpers/common/form';
import IResetPasswordStep from '../IResetPasswordStep';
import Error from '../../../../components/primitives/error/Error';
import { useForm } from 'react-hook-form';
import ActionsBlock from '../../../../components/primitives/actions-block/ActionsBlock';

const EmailStep: FC<IResetPasswordStep> = ({
	email,
	error,
	setError,
	setEmail,
	onSubmitHandler,
	context,
}) => {
	const { handleSubmit } = useForm();

	const onFormSubmitHandler = () => {
		if (submitIsDisabled()) return;
		if (onSubmitHandler) onSubmitHandler();
	};

	const submitIsDisabled = () => {
		return !email || !emailIsValid(email);
	};

	const renderPasswordForm = () => {
		const onEmailChange = (value: string) => {
			if (error && setError) setError('');
			if (setEmail) setEmail(value);
		};

		return (
			<form className={styles.form} noValidate onSubmit={handleSubmit(onFormSubmitHandler)}>
				<Input
					id="email"
					type="email"
					autoComplete="off"
					label="Email"
					value={email}
					withErrorStyle={!!error}
					placeholder="Enter your email address"
					onValueChange={onEmailChange}
				/>

				{error ? <Error message={error} /> : null}

				<ActionsBlock className={styles.actions}>
					<Button disabled={submitIsDisabled()} type="submit">
						{context === 'create' ? 'Create' : 'Reset'}
					</Button>
				</ActionsBlock>
			</form>
		);
	};

	return (
		<>
			<p className={styles.info}>
				Enter your email address for us to send you password recovery link.
			</p>

			{renderPasswordForm()}
		</>
	);
};

export default EmailStep;
