import React, { useEffect, useMemo, useState } from 'react';
import { PAGE_SIZE } from '../constants';
import Pagination from '../../../components/primitives/pagination/Pagination';

export default function usePagination(
	data: any[],
	setCurrentPageData: (data: any[]) => void,
	pageSize?: number,
	smallPagination?: boolean,
) {
	const pageSizeValue = pageSize || PAGE_SIZE;
	const [currentPage, setCurrentPage] = useState(1);
	const [currentPageSize, setCurrentPageSize] = useState(pageSizeValue);

	const pagesCount = useMemo(
		() => Math.ceil(data.length / pageSizeValue),
		[data.length, pageSizeValue],
	);

	useEffect(() => {
		setCurrentPageData(
			data.slice((currentPage - 1) * currentPageSize, currentPage * currentPageSize),
		);
	}, [data, currentPage, setCurrentPageData, currentPageSize]);

	const paginationSection = (
		<Pagination
			small={smallPagination}
			currentPage={currentPage}
			pageCount={pagesCount}
			currentPageSize={currentPageSize}
			onPageChangeHandler={(page: number) => setCurrentPage(page)}
			onPageSizeChangeHandler={(size: number) => setCurrentPageSize(size)}
			initialPageSize={pageSize}
		/>
	);

	const findPageByDataEntry = (value: string, propertyName: string) => {
		const index = data.findIndex((entry) => entry[propertyName] === value);
		return Math.ceil((index < 0 ? 1 : index + 1) / pageSizeValue);
	};

	const goToPageByDataEntryProperty = (value: string, propertyName: string) => {
		const pageNumber = findPageByDataEntry(value, propertyName);
		setCurrentPage(pageNumber);
	};

	return {
		paginationSection,
		setCurrentPage,
		pagesCount,
		goToPageByDataEntryProperty,
	};
}
