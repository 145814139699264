import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import { ICompanyBasic } from './companies.slice';
import aiLogsApi from '../../api/ai-logs.api';
import { dateInMDYTimeFormat, sortObjByIdDesc } from '../../../utils/helpers/common';

export interface IAILogsSlice {
	loading?: boolean;
}

export interface IAILogBasic {
	id?: number;
	company?: ICompanyBasic;
	status?: string;
	attempt?: number;
	createdAt?: Date;
	updatedAt?: Date;
}

export interface IAILogJobAttemptTypeItem {
	id: number;
	fileId: number;
	logFileUrl: string | null;
	threadId: string | null;
}

export interface IAILogJobAttemptItemFile {
	id: number;
	name: string;
	fileUrl: string;
}

export interface IAILogJobAttemptFile {
	file?: IAILogJobAttemptItemFile;
	controls?: IAILogJobAttemptTypeItem;
	assets?: IAILogJobAttemptTypeItem;
}

export interface IAILogJobAttemptItem {
	attempt: number;
	attemptText: string;
	files?: IAILogJobAttemptFile[];
}

export interface IAILogJobBasic {
	id?: number;
	status?: string;
	attempts?: IAILogJobAttemptItem[];
	createdAt?: Date;
	updatedAt?: Date;
}

export interface IAILog {
	id?: string;
	companyName?: string;
	status?: string;
	attempt?: number;
	createdAt?: string;
	updatedAt?: string;
}

export interface IAILogs extends IAILogsSlice {
	items: IAILog[] | null;
}

const initialState: IAILogs = {
	loading: false,
	items: null,
};

export const aiLogsSlice = createSlice({
	name: 'aiLogs',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		setAILogs: (state, { payload }: PayloadAction<IAILog[]>) => {
			state.items = payload;
		},
	},
});

export const getAILogs = () => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const logs = await aiLogsApi.getAILogs();

		const transformedData = logs.map((log: IAILogBasic) => {
			return {
				id: log.id?.toString(),
				companyName: log.company?.name,
				status: log.status,
				attempt: log.attempt,
				createdAt: dateInMDYTimeFormat(log.createdAt),
				updatedAt: dateInMDYTimeFormat(log.updatedAt),
			};
		});

		const sortedData = sortObjByIdDesc(transformedData);

		if (logs) dispatch(setAILogs(sortedData));
	} catch (error: any) {
		dispatch(setAILogs([]));
	} finally {
		dispatch(loaded());
	}
};

export const getAILogEntryById = (id: string) => async () => {
	try {
		return await aiLogsApi.getAILogById(id);
	} catch (error: any) {
		throw new CommonResponseError('Error while getting AI log entry.');
	}
};

export const { loading, loaded, setAILogs } = aiLogsSlice.actions;
export default aiLogsSlice.reducer;
