import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../services/store';
import IAILogDetails from './IAILogDetails';
import { IAILogJobBasic, getAILogEntryById } from '../../../../services/store/slices/ai-logs.slice';
import SectionTitle from '../../../primitives/section-title/SectionTitle';
import styles from './AILogDetails.module.scss';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import Error from '../../../primitives/error/Error';
import AILogDetailsSkeleton from './AILogDetailsSkeleton';
import LogAttemptsBlock from '../../log-attempts-block/LogAttemptsBlock';

const AILogDetails: FC<IAILogDetails> = ({ logEntryId }) => {
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const [currentLogEntry, setCurrentLogEntry] = useState<IAILogJobBasic | null>();

	const renderDetails = () => {
		return (
			<>
				<SectionTitle className={styles.details}>Files</SectionTitle>

				<LogAttemptsBlock
					className={styles.file}
					status={currentLogEntry?.status || ''}
					attempts={currentLogEntry?.attempts || []}
				/>
			</>
		);
	};

	useEffect(() => {
		if (!currentLogEntry && logEntryId) setLoading(true);
	}, [currentLogEntry, logEntryId]);

	useEffect(() => {
		if (logEntryId && !currentLogEntry && !loading && !error) {
			handleWithTryCatch(async () => {
				const log = await dispatch(getAILogEntryById(logEntryId));
				setCurrentLogEntry(log);
				setLoading(false);
			}, setError);
		}
	}, [currentLogEntry, dispatch, error, loading, logEntryId]);

	useEffect(() => {
		if (error) setLoading(false);
	}, [error]);

	return (
		<div className={styles['log-entry-details']}>
			{error ? <Error message={error} /> : null}

			{!error && loading ? <AILogDetailsSkeleton /> : null}

			{!error && !loading ? <div className={styles.section}>{renderDetails()}</div> : null}
		</div>
	);
};

export default AILogDetails;
