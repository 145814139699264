import React, { useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ProtectedRoute from './utils/helpers/hocs/auth/ProtectedRoute';
import { AppRoutes, AppUnauthorizedRoutes, AppUrls } from './utils/helpers/constants/app-routes';
import UnprotectedRoute from './utils/helpers/hocs/auth/UnprotectedRoute';
import { AnimatedScreenWrapper } from './utils/helpers/hocs/transition/AnimatedScreenWrapper';
import { AnimatePresence } from 'framer-motion';
import useUser from './utils/helpers/hooks/useUser';
import Loader from './components/primitives/loader/Loader';
import useDataPreload from './utils/helpers/hooks/useDataPreload';
import useCompanies from './utils/helpers/hooks/useCompanies';
import Alert from './components/primitives/alert/Alert';
import PageNotFound from './pages/404/PageNotFound';

function App() {
	const location = useLocation();
	const { info: userInfo } = useUser();
	const { error: getCompaniesError } = useCompanies();

	const mappedRoutes = useMemo(
		() => (
			<>
				{AppRoutes.map((route) => {
					const Component = route.component;

					if (route.type === 'unprotected') {
						return (
							<Route
								path={route.url}
								key={route.key}
								element={
									<UnprotectedRoute>
										<AnimatedScreenWrapper>
											<Component />
										</AnimatedScreenWrapper>
									</UnprotectedRoute>
								}
							/>
						);
					}

					return (
						<Route
							path={route.url}
							key={route.key}
							element={
								<ProtectedRoute>
									<AnimatedScreenWrapper>
										<Component />
									</AnimatedScreenWrapper>
								</ProtectedRoute>
							}
						/>
					);
				})}
			</>
		),
		[],
	);

	const routes = useMemo(
		() => (
			<AnimatePresence mode="wait">
				<Routes {...{ location, key: location.pathname }}>
					{mappedRoutes}

					<Route path="/" element={<Navigate to={AppUrls.companies} replace />} />

					<Route path="*" element={<PageNotFound />} />
				</Routes>
			</AnimatePresence>
		),
		[location, mappedRoutes],
	);

	const errorAlerts = useMemo(() => {
		return (
			<>
				{!!getCompaniesError ? (
					<Alert
						uniqueKey={'companies-error'}
						show
						type="error"
						message="Error while getting companies. Please try to reload the page."
						hideAfterDelay={false}
					/>
				) : null}
			</>
		);
	}, [getCompaniesError]);

	useDataPreload();

	if (
		(location.pathname === '/' || !AppUnauthorizedRoutes.includes(location.pathname)) &&
		userInfo === undefined
	)
		return <Loader wide thin />;

	return (
		<>
			{routes}
			{errorAlerts}
		</>
	);
}

export default React.memo(App);
