import { useEffect, useMemo, useState } from 'react';
import useAdmins from '../../utils/helpers/hooks/useAdmins';
import AdminsTable from '../../components/partials/tables/admins/AdminsTable';
import useGetAdmins from '../../utils/helpers/hooks/useGetAdmins';
import { useLocation } from 'react-router-dom';
import { AppQueryParams } from '../../utils/helpers/constants';

const Admins = () => {
	const location = useLocation();
	const { items: adminItems } = useAdmins();
	const [loading, setLoading] = useState(true);

	const navigateToItem = useMemo(() => {
		if (!loading && adminItems) {
			const urlParams = new URLSearchParams(location.search);
			const adminIdFromUrl = urlParams.get(AppQueryParams.item) || '';

			if (adminIdFromUrl) {
				const admin = adminItems?.find((item) => item.id === adminIdFromUrl);

				if (admin) return adminIdFromUrl;
			}
		}
	}, [loading, adminItems, location.search]);

	useGetAdmins();

	useEffect(() => {
		if (adminItems) setLoading(false);
	}, [adminItems]);

	return (
		<>
			<h2>Company Admins</h2>

			<AdminsTable
				data={adminItems || []}
				dataLoading={loading}
				showItemId={navigateToItem}
			/>
		</>
	);
};

export default Admins;
