import { FC, useEffect, useState } from 'react';
import styles from './CompanyStep.module.scss';
import ActionsBlock from '../../../../../primitives/actions-block/ActionsBlock';
import Button from '../../../../../primitives/button/Button';
import Select from '../../../../../primitives/form/select/Select';
import Input from '../../../../../primitives/form/input/Input';
import {
	commonStringIsValid,
	emailIsValid,
	locationStringIsValid,
} from '../../../../../../utils/helpers/common/form';
import ICompanyStep from './ICompanyStep';
import LocationSearch from '../../../../location-search/LocationSearch';
import {
	IndustriesSelectOptions,
	LifeTimeSelectOptions,
} from '../../../../../../utils/helpers/constants';
import classNames from 'classnames';
import ReactSwitch from 'react-switch';

const CompanyStep: FC<ICompanyStep> = ({
	currentFormState,
	setCurrentFormState,
	setOpen,
	setNextStep,
	className,
	locationNameValue,
	setLocationNameValue,
	setLocationTimeZone,
	defaultFrameworksOptions,
}) => {
	const [nextIsDisabled, setNextIsDisabled] = useState(true);

	const formActions = (
		<ActionsBlock className={styles.actions}>
			<Button type="button" width={136} onClick={() => setOpen(false)} negative>
				Cancel
			</Button>

			<Button
				disabled={nextIsDisabled}
				type="button"
				width={136}
				onClick={() => {
					if (!nextIsDisabled) setNextStep();
				}}
			>
				Next
			</Button>
		</ActionsBlock>
	);

	useEffect(() => {
		if (
			currentFormState.name &&
			commonStringIsValid(currentFormState.name) &&
			(!currentFormState.contactEmail ||
				(currentFormState.contactEmail && emailIsValid(currentFormState.contactEmail))) &&
			locationNameValue &&
			locationStringIsValid(locationNameValue) &&
			(!currentFormState.description ||
				(currentFormState.description &&
					commonStringIsValid(currentFormState.description))) &&
			currentFormState.industry &&
			currentFormState.lifetime
		) {
			setNextIsDisabled(false);
		} else {
			setNextIsDisabled(true);
		}
	}, [
		currentFormState.lifetime,
		currentFormState.contactEmail,
		currentFormState.description,
		currentFormState.industry,
		locationNameValue,
		currentFormState.name,
	]);

	const createForm = (
		<div className={classNames(styles['add-company'], className)}>
			<div className={styles.inputs}>
				<Input
					className={styles.input}
					withErrorStyle={!commonStringIsValid(currentFormState.name)}
					type="text"
					label="Name"
					placeholder="Enter company name"
					value={currentFormState.name}
					onValueChange={(value) => {
						setCurrentFormState((prev) => ({
							...prev,
							name: value,
						}));
					}}
				/>

				<Input
					withErrorStyle={
						!!(
							currentFormState.contactEmail &&
							!emailIsValid(currentFormState.contactEmail)
						)
					}
					className={styles.input}
					type="text"
					label="Contact Email (optional)"
					placeholder="Enter contact email"
					value={currentFormState.contactEmail}
					onValueChange={(value) => {
						setCurrentFormState((prev) => ({
							...prev,
							contactEmail: value,
						}));
					}}
				/>

				<Input
					withErrorStyle={!commonStringIsValid(currentFormState.description)}
					className={styles.input}
					type="text"
					label="Description (optional)"
					placeholder="Enter description"
					value={currentFormState.description}
					onValueChange={(value) => {
						setCurrentFormState((prev) => ({
							...prev,
							description: value,
						}));
					}}
				/>

				<Select
					id="industry"
					className={styles.input}
					options={IndustriesSelectOptions}
					label="Industry"
					value={currentFormState.industry}
					onValueChange={(value) => {
						setCurrentFormState((prev) => ({
							...prev,
							industry: value,
						}));
					}}
					placeholder="Select industry"
				/>

				<LocationSearch
					withErrorStyle={!locationStringIsValid(locationNameValue)}
					type="text"
					className={styles.input}
					value={locationNameValue}
					onValueChange={(data) => {
						setLocationNameValue(data.value);
						if (data.timeZone) setLocationTimeZone(data.timeZone);
					}}
				/>

				<Select
					id="lifetime"
					className={styles.input}
					options={LifeTimeSelectOptions}
					label="Account Lifetime"
					value={currentFormState.lifetime}
					onValueChange={(value) => {
						setCurrentFormState((prev) => ({
							...prev,
							lifetime: value,
						}));
					}}
					placeholder="Select account lifetime"
				/>

				<label className={styles['switcher-wrap']}>
					<p className={styles.label}>Default Statement Onboarding Flow</p>

					<ReactSwitch
						onChange={(value) => {
							setCurrentFormState((prev) => ({
								...prev,
								defaultStatementOnboardingFlow: value,
							}));
						}}
						checked={!!currentFormState.defaultStatementOnboardingFlow}
						uncheckedIcon={false}
						checkedIcon={false}
						activeBoxShadow={'none'}
						offHandleColor={'#A0D47D'}
						onHandleColor={'#62AB30'}
						onColor={'#F2FAE8'}
						offColor={'#F6FCEF'}
						className={
							!!currentFormState.defaultStatementOnboardingFlow ? '' : styles.off
						}
						handleDiameter={16}
						height={20}
						width={36}
					/>
				</label>

				{!!currentFormState.defaultStatementOnboardingFlow && (
					<Select
						id="framework"
						className={styles.input}
						options={defaultFrameworksOptions}
						label="Framework"
						value={currentFormState.defaultStatementOnboardingFrameworkId}
						onValueChange={(value) => {
							setCurrentFormState((prev) => ({
								...prev,
								defaultStatementOnboardingFrameworkId: value,
							}));
						}}
						placeholder="Select default framework"
					/>
				)}
			</div>

			{formActions}
		</div>
	);

	return createForm;
};

export default CompanyStep;
