import {
	IAWSPasswordRequirements,
	IPasswordRequirements,
	ISortProps,
	IKeyValuePair,
	IIdName,
	ISelectOption,
} from '../../types';
import {
	CommonColors,
	MAX_CONTROLS_DISPLAY_NUMBER,
	MIN_CONTROLS_DISPLAY_NUMBER,
} from '../constants';
import ColoredTag from '../../../components/primitives/tag/ColoredTag';
import { DeviceType } from '../hooks/useDevice/IUseDevice';

import DOMPurify from 'dompurify';
import dayjs from 'dayjs';
import { IAdmin } from '../../../services/store/slices/admins.slice';
import { ICompany } from '../../../services/store/slices/companies.slice';
import { IFramework } from '../../../services/store/slices/frameworks.slice';

export const nonScrollablePageOn = () => document.body.classList.add('non-scrollable');
export const nonScrollablePageOff = () => document.body.classList.remove('non-scrollable');

export const sanitizeData = (data: any) => {
	if (!data) return;

	const sanitizedData = { ...data };

	Object.entries(sanitizedData).forEach(([key, value]) => {
		if (typeof value === 'string') {
			data[key] = DOMPurify.sanitize(value);
		}
	});

	return sanitizedData;
};

export const dateInMDYTimeFormat = (date?: string | number | Date | null) => {
	if (!date) return '';

	return dayjs(date).format('MM/DD/YYYY h:mm A');
};

export const dateInMDYFormat = (date?: string | number | Date | null) => {
	if (!date) return '';

	return dayjs(date).format('MM/DD/YY');
};

export const dateInVersionFormat = (date?: string | number | Date | null) => {
	if (!date) return '';

	return dayjs(date).format('YYYY.MM.DD');
};

export const getFrameworkNameWithoutSubType = (name?: string) => {
	if (!name) return '';

	return name.split(' (')[0];
};

export const dueDateColumnRenderHelper = (dueDate: string) => {
	const daysDifference = checkDaysDifferenceWithNow(dueDate);
	const inPast = daysDifference > 0;

	if (inPast)
		return (
			<ColoredTag
				tagId={dueDate}
				text={dueDate}
				bgColor={CommonColors.errorLightTag}
				borderColor={CommonColors.errorTag}
				textColor="#415E2E"
			/>
		);

	const absDaysDifference = Math.abs(daysDifference);

	const bgColor =
		absDaysDifference <= 7
			? CommonColors.errorLightTag
			: absDaysDifference <= 30
				? CommonColors.alertLightTag
				: 'transparent';

	const borderColor =
		absDaysDifference <= 7
			? CommonColors.errorTag
			: absDaysDifference <= 30
				? CommonColors.alertTag
				: CommonColors.defaultTagBg;

	return (
		<ColoredTag
			tagId={dueDate}
			text={dueDate}
			bgColor={bgColor}
			borderColor={borderColor}
			textColor="#415E2E"
		/>
	);
};

export const controlsColumnRenderHelper = (
	controls: string[],
	device?: DeviceType | null,
	minControlsDisplayNumber?: number,
	maxControlsDisplayNumber?: number,
) => {
	if (!controls?.length) return;

	const maxTagsNumber =
		device === 'smallDesktop'
			? minControlsDisplayNumber || MIN_CONTROLS_DISPLAY_NUMBER
			: maxControlsDisplayNumber || MAX_CONTROLS_DISPLAY_NUMBER;

	const tagsCount = controls?.length;
	const tagsToDisplay = tagsCount > maxTagsNumber ? controls.slice(0, maxTagsNumber) : controls;

	return (
		<>
			{tagsToDisplay?.map((control) => (
				<ColoredTag
					tagId={control}
					withoutBackground
					key={control}
					text={control}
					borderColor={'#F4F9EB'}
					textColor="#7A926A"
				/>
			))}

			{tagsCount > MAX_CONTROLS_DISPLAY_NUMBER ? (
				<ColoredTag
					tagId={'rest'}
					withoutBackground
					key={'rest'}
					text={`+${tagsCount - maxTagsNumber}`}
					borderColor={'#F4F9EB'}
					textColor="#7A926A"
				/>
			) : null}
		</>
	);
};

export const transformPasswordRequirements = (instructions: IAWSPasswordRequirements) => {
	const { MinimumLength, RequireLowercase, RequireNumbers, RequireSymbols, RequireUppercase } =
		instructions || {};

	return {
		minimumLength: MinimumLength || 8,
		requireLowercase: RequireLowercase || true,
		requireUppercase: RequireUppercase || true,
		requireNumbers: RequireNumbers || true,
		requireSymbols: RequireSymbols || true,
	} as IPasswordRequirements;
};

export const generatePasswordTextInstructions = (instructions?: IPasswordRequirements | null) => {
	if (!instructions) return;

	const { minimumLength, requireLowercase, requireUppercase, requireNumbers, requireSymbols } =
		instructions;

	const passwordInstructions = [];

	if (requireLowercase && requireUppercase)
		passwordInstructions.push({
			key: 'requireLowercaseUppercase',
			rgx: '^(?=.*[a-z])(?=.*[A-Z])',
			text: 'Lowercase and uppercase letters',
		});
	else if (requireLowercase)
		passwordInstructions.push({
			key: 'requireLowercase',
			rgx: '^(?=.*[a-z])',
			text: 'Lowercase letters',
		});
	else if (requireUppercase)
		passwordInstructions.push({
			key: 'requireUppercase',
			rgx: '^(?=.*[A-Z])',
			text: 'Uppercase letters',
		});

	passwordInstructions.push({
		key: 'minimumLength',
		rgx: `.{${minimumLength},}`,
		text: `At least ${minimumLength} characters`,
	});

	if (requireSymbols)
		passwordInstructions.push({
			key: 'requireSymbols',
			rgx: '^(?=.*[!@#$%^&*])',
			text: 'At least 1 special character',
		});
	if (requireNumbers)
		passwordInstructions.push({
			key: 'requireNumbers',
			rgx: '^(?=.*[0-9])',
			text: 'At least 1 number',
		});

	return passwordInstructions;
};

export const checkForRegex = (rgx: string, input: string) => {
	const regex = new RegExp(rgx);
	return regex.test(input);
};

export const userNameFirstLetters = (firstName?: string, lastName?: string) => {
	if (firstName && lastName) {
		return `${firstName[0]}${lastName[0]}`;
	}
};

export function getSortingHandlersByProperty(propertyName: string) {
	switch (propertyName) {
		case 'createdAt':
		case 'dueDate':
		case 'updatedAt':
			return (a: string, b: string) => {
				const dateA = new Date(a);
				const dateB = new Date(b);

				if (dateA.getTime() < dateB.getTime()) return -1;
				if (dateA.getTime() > dateB.getTime()) return 1;
				return 0;
			};
		case 'controlId':
		case 'displayId':
			return compareIDs;
		case 'company':
		case 'framework':
			return (a: ICompany | IIdName, b: ICompany | IIdName) => {
				if (!a.name || !b.name) return 0;

				return a.name.toLowerCase() > b.name.toLowerCase()
					? 1
					: b.name.toLowerCase() > a.name.toLowerCase()
						? -1
						: 0;
			};
		case 'id':
		case 'jobId':
			return (a: string, b: string) => {
				if (Number(a) < Number(b)) return -1;
				if (Number(a) > Number(b)) return 1;
				return 0;
			};
		default:
			return (a: string, b: string) => {
				if (a < b) return -1;
				if (a > b) return 1;
				return 0;
			};
	}
}

export const sortUsersByFullName = (arr: IAdmin[]) =>
	arr.sort((a, b) => {
		if (!a.fullName || !b.fullName) return 0;

		return a.fullName.toLowerCase() > b.fullName.toLowerCase()
			? 1
			: b.fullName.toLowerCase() > a.fullName.toLowerCase()
				? -1
				: 0;
	});

function compareIDs(id1: string, id2: string) {
	const parts1 = splitID(id1);
	const parts2 = splitID(id2);

	for (let i = 0; i < Math.min(parts1.length, parts2.length); i++) {
		const part1 = parts1[i];
		const part2 = parts2[i];

		// Check if both parts are numbers
		const isNum1 = !isNaN(Number(part1));
		const isNum2 = !isNaN(Number(part2));

		if (isNum1 && isNum2) {
			// If both parts are numbers, compare them numerically
			const num1 = parseFloat(part1);
			const num2 = parseFloat(part2);
			if (num1 < num2) {
				return -1;
			} else if (num1 > num2) {
				return 1;
			}
		} else if (isNum1 || isNum2) {
			// If one part is a number and the other is not, prioritize the number
			return isNum1 ? -1 : 1;
		} else {
			// If both parts are not numbers, compare them as strings
			if (part1 < part2) {
				return -1;
			} else if (part1 > part2) {
				return 1;
			}
		}
	}

	return parts1.length - parts2.length;
}

function splitID(id: string) {
	// Split by '-', '.', '(', ')'
	return id.split(/[-.()]/);
}

export const sortBaseControlObjByDisplayIdAsc = (arr: IIdName[]) => {
	const arrCopy = [...arr];

	return arrCopy.sort((a, b) => compareIDs(a.displayId || '', b.displayId || ''));
};

export const sortBaseControlsForCrosswalkAsc = (arr: any[]) => {
	//INistCrosswalk[] | IIsoCrosswalk[]
	const arrCopy = [...arr];

	return arrCopy.sort((a, b) => compareIDs(a.controlId || '', b.controlId || ''));
};

export function getSearchHandlerByProperty(
	propertyName: string,
	propertyValue: any,
	searchInput: string,
) {
	switch (propertyName) {
		case 'assignedAssets':
		case 'assignedPolicies':
			const assignedValue = propertyValue as IIdName;
			return assignedValue.name.toLowerCase().includes(searchInput.toLowerCase());
		default:
			return false;
	}
}

export function sortTableData<T>(data: T[], currentSort: ISortProps) {
	if (!data.length) return [];

	if (!currentSort || !currentSort?.property || !currentSort?.direction) return data;

	const sortingHandlerForProperty = getSortingHandlersByProperty(currentSort?.property);

	const sortedData = [...data].sort((a: T, b: T) => {
		const propertyValueA = a[currentSort.property as keyof typeof a];
		const propertyValueB = b[currentSort.property as keyof typeof b];

		//@ts-ignore
		if (sortingHandlerForProperty(propertyValueA, propertyValueB) < 0)
			return currentSort.direction === 'asc' ? -1 : 1;

		//@ts-ignore
		if (sortingHandlerForProperty(propertyValueA, propertyValueB) > 0)
			return currentSort.direction === 'asc' ? 1 : -1;

		return 0;
	});

	return sortedData;
}

export function processTableData<T>(
	data: T[],
	currentFilters: IKeyValuePair,
	currentSort: ISortProps,
) {
	const processedData: T[] = [];

	data.forEach((dataRecord: T) => {
		if (Object.entries(currentFilters).every(([key, filterMatch]) => filterMatch(dataRecord))) {
			processedData.push(dataRecord);
		}
	});

	if (!currentSort || !currentSort?.property || !currentSort?.direction) return processedData;

	const sortedData = sortTableData(processedData, currentSort);

	return sortedData;
}

export const sortByNameAsc = (arr: any[]) =>
	[...arr].sort((a, b) =>
		a.name.toLowerCase() > b.name.toLowerCase()
			? 1
			: b.name.toLowerCase() > a.name.toLowerCase()
				? -1
				: 0,
	);

export const sortObjByIdDesc = (arr: any[]) => {
	const sorted = [...arr].sort((a, b) => {
		if (!a.id || !b.id) return 0;

		const aId = Number(a.id);
		const bId = Number(b.id);

		return bId - aId;
	});

	return sorted;
};

export const sortStringsAsc = (arr: string[]) => {
	return [...arr].sort((a, b) =>
		a.toLowerCase() > b.toLowerCase() ? 1 : b.toLowerCase() > a.toLowerCase() ? -1 : 0,
	);
};

export const checkDaysDifferenceWithNow = (date: string) => {
	const dateToCheck = new Date(date);
	const now = new Date();

	const timeDifference = now.getTime() - dateToCheck.getTime();
	const daysDifference = timeDifference / (1000 * 3600 * 24);

	return daysDifference;
};

export const checkTokenExpiration = (token: string) => {
	const tokenPart = token.split('.')[1];
	const tokenData = atob(tokenPart);
	const expDate = JSON.parse(tokenData)?.exp;

	if (expDate) {
		const newDate = expDate * 1000;
		const startTime = dayjs();
		const expirationTime = dayjs(newDate);

		let isValid = expirationTime.isAfter(startTime);

		return isValid;
	}
};

export const getCompanyOptions = (companies: ICompany[] | null) => {
	if (!companies) return [];

	return companies.map(
		(company: ICompany) =>
			({
				value: company.id,
				label: company.name,
			}) as ISelectOption,
	);
};

export const getAdminOptions = (admins: IAdmin[] | null, companyId?: string) => {
	if (!admins) return [];

	let adminsToProcess = admins;

	if (companyId) {
		adminsToProcess = admins.filter((admin: IAdmin) => admin.company?.id === companyId);
	}

	return adminsToProcess.map(
		(admin: IAdmin) =>
			({
				value: admin.id,
				label: admin.fullName,
			}) as ISelectOption,
	);
};

export const getFrameworkOptions = (frameworks: IFramework[]) => {
	const options = frameworks?.map((framework) => ({
		value: framework.id!,
		label: framework.name!,
	}));

	return options.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
};

export const getBaseControlOptions = (controls: IIdName[]) => {
	const uniqueControls = controls.filter(
		(control, index, self) => self.findIndex((c) => c.id === control.id) === index,
	);

	return Array.from(uniqueControls).map(
		(control) =>
			({
				value: control.id,
				label: control.displayId,
			}) as ISelectOption,
	);
};

export const controlsAreDifferent = (controlsA?: string[], controlsB?: string[]) => {
	if (controlsA?.length !== controlsB?.length) return true;

	if (controlsA && controlsB) {
		const currentControls = [...controlsA].sort();
		const initialControls = [...controlsB].sort();

		return JSON.stringify(currentControls) !== JSON.stringify(initialControls);
	}

	return false;
};

export const getFrameworkSmallName = (frameworkName: string) => {
	const name = frameworkName.toLowerCase();

	if (name.includes('low')) return 'low';
	if (name.includes('moderate')) return 'moderate';
	if (name.includes('high')) return 'high';
};

export const getFrameworkTypeAndSubType = (frameworkName: string) => {
	const name = frameworkName.toLowerCase();

	if (name.includes('nist')) {
		return {
			type: 'nist',
			subType: getFrameworkSmallName(frameworkName),
		};
	}

	if (name.includes('iso')) {
		return {
			type: 'iso',
		};
	}

	if (name.includes('soc')) {
		return {
			type: 'soc',
		};
	}
};
