import { FC, useState } from 'react';
import styles from './ModalResetCompany.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import ActionsBlock from '../../../primitives/actions-block/ActionsBlock';
import Button from '../../../primitives/button/Button';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import IModalResetCompany from './IModalResetCompany';
import Loader from '../../../primitives/loader/Loader';
import { resetCompany } from '../../../../services/store/slices/companies.slice';

const ModalResetCompany: FC<IModalResetCompany> = ({
	open,
	setOpen,
	id,
	name,
	onResetError,
	onResetSuccess,
	onCancel,
}) => {
	const dispatch = useAppDispatch();
	const [resetLoading, setResetLoading] = useState(false);

	const onReset = () => {
		setResetLoading(true);

		handleWithTryCatch(
			async () => {
				if (id) {
					await dispatch(resetCompany(id));
					onResetSuccess();
					setOpen(false);
					setResetLoading(false);
				}
			},
			undefined,
			() => {
				onResetError();
				setResetLoading(false);
			},
		);
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Reset Company Account?</h2>

				<p className={styles.text}>
					Are you sure you want reset <strong>{name}</strong> account?
					<span className={styles.item}>
						- all the control statements will be removed
					</span>
					<span className={styles.item}>- all the assets will be removed</span>
					<span className={styles.item}>
						- the onboarding should be performed once again
					</span>
					Would you like to proceed?
				</p>

				<ActionsBlock>
					<Button type="button" width={160} negative onClick={onCancel}>
						Cancel
					</Button>

					<Button errorStyled type="button" width={160} onClick={onReset} disabled={resetLoading}>
						{resetLoading ? (
							<Loader
								thin
								maxHeight={14}
								maxWidth={14}
								color={'#ffffff'}
								transparentColor="transparent"
							/>
						) : (
							'Reset'
						)}
					</Button>
				</ActionsBlock>
			</BasicPanel>
		</Modal>
	);
};

export default ModalResetCompany;
