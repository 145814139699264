import React, { FC, useState } from 'react';
import styles from './Login.module.scss';
import { IAuthorizationPayload, login } from '../../services/store/slices/authorization.slice';
import { useAppDispatch } from '../../services/store';
import Button from '../../components/primitives/button/Button';
import Input from '../../components/primitives/form/input/Input';
import { emailIsValid, stringIsNotEmpty } from '../../utils/helpers/common/form';
import Error from '../../components/primitives/error/Error';
import { handleWithTryCatch } from '../../utils/helpers/errors';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { AppUrls } from '../../utils/helpers/constants/app-routes';
import BasicPanel from '../../components/primitives/basic-panel/BasicPanel';
import ActionsBlock from '../../components/primitives/actions-block/ActionsBlock';
import useAuthorization from '../../utils/helpers/hooks/useAuthorization';
import { sanitizeData } from '../../utils/helpers/common';

const Login: FC = () => {
	const dispatch = useAppDispatch();
	const { loading: authLoading } = useAuthorization();
	const { handleSubmit } = useForm();

	const [formData, setFormData] = useState<IAuthorizationPayload>({
		email: '',
		password: '',
	});

	const [error, setError] = useState('');

	const onFormSubmitHandler = async () => {
		if (submitIsDisabled()) return;

		const newData = sanitizeData(formData);

		handleWithTryCatch(() => dispatch(login(newData)), setError);
	};

	const submitIsDisabled = () => {
		const { email, password } = formData;

		return !email || !password || !emailIsValid(email) || !stringIsNotEmpty(password);
	};

	const loginForm = (
		<form className={styles.form} noValidate onSubmit={handleSubmit(onFormSubmitHandler)}>
			<Input
				id="email"
				type="email"
				autoComplete="off"
				label="Email"
				withErrorStyle={!!error}
				placeholder="Enter your email address"
				value={formData.email}
				onValueChange={(value: string) => {
					if (error) setError('');
					setFormData((prev) => ({ ...prev, email: value }));
				}}
			/>

			<Input
				id="password"
				type="password"
				autoComplete="off"
				label="Password"
				withErrorStyle={!!error}
				placeholder="Enter your password"
				value={formData.password}
				className={styles.password}
				onValueChange={(value: string) => {
					if (error) setError('');
					setFormData((prev) => ({ ...prev, password: value }));
				}}
			/>

			{error ? <Error message={error} /> : null}

			<ActionsBlock className={styles.actions}>
				<Button disabled={submitIsDisabled() || authLoading} type="submit">
					Log In
				</Button>
			</ActionsBlock>
		</form>
	);

	return (
		<BasicPanel className={styles.login} withLogo title="Log In">
			{loginForm}

			<div className={styles['link-wrapper']}>
				<Link to={AppUrls.resetPassword} className={styles['reset-link']}>
					Forgot Password?
				</Link>
			</div>
		</BasicPanel>
	);
};

export default Login;
