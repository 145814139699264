import { INistCrosswalk } from '../../../../services/store/slices/crosswalk.slice';
import Checkbox from '../../../primitives/form/checkbox/Checkbox';
import { ITableColumn } from '../../../primitives/table/ITable';
import ColoredTag from '../../../primitives/tag/ColoredTag';
import styles from './NistCrosswalkTable.module.scss';

export const getNistCrosswalkColumns = (onCheckboxClickHandler: (e: any) => void) => {
	return [
		{
			header: 'Control ID',
			key: 'controlId',
			sorting: true,
			width: '13.7%',
		},
		{
			header: 'Control Enhancement Name',
			key: 'enhancementName',
			sorting: true,
			width: '31%',
		},
		{
			header: 'Low',
			key: 'low',
			width: '12.5%',
			columnClassName: styles.centered,
			columnRender: (value: boolean, rowData: INistCrosswalk) => {
				return (
					<Checkbox
						id={rowData.id + '|low'}
						checked={value}
						onChange={onCheckboxClickHandler}
					/>
				);
			},
		},
		{
			header: 'Moderate',
			key: 'moderate',
			width: '12.5%',
			columnClassName: styles.centered,
			columnRender: (value: boolean, rowData: INistCrosswalk) => {
				return (
					<Checkbox
						id={rowData.id + '|moderate'}
						checked={value}
						onChange={onCheckboxClickHandler}
					/>
				);
			},
		},
		{
			header: 'High',
			key: 'high',
			width: '12.5%',
			columnClassName: styles.centered,
			columnRender: (value: boolean, rowData: INistCrosswalk) => {
				return (
					<Checkbox
						id={rowData.id + '|high'}
						checked={value}
						onChange={onCheckboxClickHandler}
					/>
				);
			},
		},
		{
			header: 'Updated Time',
			key: 'updatedAt',
			sorting: true,
			width: '17%',
			columnRender: (value: string) => {
				return (
					<ColoredTag
						borderColor={'#F4F9EB'}
						textColor="#7A926A"
						tagId={value}
						text={value}
						withoutBackground
					/>
				);
			},
		},
	] as ITableColumn[];
};
