import { FC, useCallback, useEffect, useState } from 'react';
import styles from './FrameworksAndControlsBlock.module.scss';
import classNames from 'classnames';
import ColoredTag from '../../primitives/tag/ColoredTag';
import { CommonColors, FrameworkSubTypes, FrameworkTypes } from '../../../utils/helpers/constants';
import HoverTooltip from '../../primitives/tooltip/HoverTooltip';
import Button from '../../primitives/button/Button';
import IFrameworksAndControlsBlock from './IFrameworksAndControlsBlock';
import useFrameworks from '../../../utils/helpers/hooks/useFrameworks';
import ModalAddControls from '../modals/modal-add-controls/ModalAddControls';
import Select from '../../primitives/form/select/Select';
import { getFrameworkOptions, getFrameworkTypeAndSubType } from '../../../utils/helpers/common';
import useBaseControls from '../../../utils/helpers/hooks/useBaseControls';
import { IIdName } from '../../../utils/types';
import { handleWithTryCatch } from '../../../utils/helpers/errors';
import { useAppDispatch } from '../../../services/store';
import { getBaseControlsByType } from '../../../services/store/slices/base-controls.slice';

const FrameworksAndControlsBlock: FC<IFrameworksAndControlsBlock> = ({
	className,
	entityControlIds,
	entityFrameworkId,
	setControlIds,
	setFrameworkId,
}) => {
	const dispatch = useAppDispatch();
	const { baseControls: baseControlItems } = useBaseControls();
	const { items: frameworkItems } = useFrameworks();

	const [openAddControlsModal, setOpenAddControlsModal] = useState(false);
	const [addedControlIds, setAddedControlIds] = useState<string[]>([]);

	const [controlsForFramework, setControlsForFramework] = useState<IIdName[]>([]);

	const entityControlObjs = baseControlItems?.filter((control) => {
		return entityControlIds?.includes(control.id!);
	});

	const onRemoveControlClick = async (controlId: string) => {
		const newControls = entityControlIds?.filter((control) => control !== controlId);

		if (setControlIds) setControlIds(newControls);
	};

	const renderControls = () => {
		if (entityControlObjs?.length)
			return (
				<div className={styles['controls']}>
					<h5>Controls</h5>

					{entityControlObjs?.map((control) => {
						return (
							<ColoredTag
								tagId={control.id}
								key={control.id}
								text={control.displayId!}
								bgColor={CommonColors.defaultTagBg}
								withoutBackground
								tooltipId={'tooltip-control-name'}
								tooltipContent={control.name || ''}
								withRemoveOnHover
								onRemoveClickHandler={() => onRemoveControlClick(control.id)}
								className={classNames(
									styles.tag,
									addedControlIds.length && addedControlIds.includes(control.id)
										? styles['added-control']
										: '',
								)}
							/>
						);
					})}
				</div>
			);
	};

	const onNewControlsAdded = (newControls: string[]) => {
		setAddedControlIds(newControls);
		if (setControlIds) setControlIds([...entityControlIds, ...newControls]);
	};

	const getControlsForCurrentType = useCallback(() => {
		handleWithTryCatch(async () => {
			const selectedFramework = frameworkItems?.find((fr) => fr.id === entityFrameworkId);
			const frameworkTypeData = getFrameworkTypeAndSubType(selectedFramework?.name || '');

			const controlsForType = await dispatch(
				getBaseControlsByType(
					FrameworkTypes[frameworkTypeData?.type as keyof typeof FrameworkTypes],
					FrameworkSubTypes[frameworkTypeData?.subType as keyof typeof FrameworkSubTypes],
				),
			);

			setControlsForFramework(controlsForType || []);
		});
	}, [dispatch, entityFrameworkId, frameworkItems]);

	const onFrameworkChange = (newFramework: string) => {
		if (setControlIds) setControlIds([]);
		if (setFrameworkId) setFrameworkId(newFramework);
	};

	const renderEmptyState = () => {
		if (!entityControlIds?.length)
			return (
				<p className={styles.empty}>
					You don't have any controls assigned to this policy. Click "Add Control" to add
					a control.
				</p>
			);
	};

	const renderActions = () => {
		return (
			<div className={styles.actions}>
				<Button
					type="button"
					negative
					small
					width={104}
					className={styles.action}
					onClick={() => setOpenAddControlsModal(true)}
				>
					Add Control
				</Button>
			</div>
		);
	};

	const modals = (
		<>
			<ModalAddControls
				open={openAddControlsModal}
				setOpen={setOpenAddControlsModal}
				entityControlIds={entityControlIds}
				setEntityControls={setControlIds!}
				onNewAdded={onNewControlsAdded}
				predefinedItems={controlsForFramework.filter(
					(fc) => !entityControlIds.includes(fc.id!),
				)}
			/>
		</>
	);

	useEffect(() => {
		if (addedControlIds.length) {
			const addDelay = setTimeout(() => setAddedControlIds([]), 2000);

			return () => clearTimeout(addDelay);
		}
	}, [addedControlIds]);

	useEffect(() => {
		if (entityFrameworkId) getControlsForCurrentType();
	}, [entityFrameworkId, getControlsForCurrentType]);

	return (
		<div className={classNames(styles['frameworks-and-controls-block'], className)}>
			<Select
				id="framework"
				onValueChange={(value) => onFrameworkChange(value)}
				defaultValue={entityFrameworkId}
				className={styles.input}
				options={getFrameworkOptions(frameworkItems || [])}
				label="Framework"
			/>

			{renderEmptyState()}

			{renderControls()}

			{renderActions()}

			<HoverTooltip tooltipId="tooltip-control-name" place="bottom-start" />
			<HoverTooltip warning withIcon tooltipId="tooltip-remove-warning" />

			{modals}
		</div>
	);
};

export default FrameworksAndControlsBlock;
