import { useEffect } from 'react';
import useUser from './useUser';
import { useAppDispatch } from '../../../services/store';
import { handleWithTryCatch } from '../errors';
import { getUserInfo } from '../../../services/store/slices/user.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppUnauthorizedRoutes, AppUrls } from '../constants/app-routes';
import { getCompanies } from '../../../services/store/slices/companies.slice';
import { getAdmins } from '../../../services/store/slices/admins.slice';
import { getFrameworks } from '../../../services/store/slices/frameworks.slice';
import { getGSPolicies } from '../../../services/store/slices/policies.slice';
import { getAllBaseControls } from '../../../services/store/slices/base-controls.slice';

export default function useDataPreload() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { authorized, info: userInfo } = useUser();

	useEffect(() => {
		if (!AppUnauthorizedRoutes.includes(location.pathname)) {
			handleWithTryCatch(
				async () => {
					if (userInfo === undefined) await dispatch(getUserInfo());
				},
				undefined,
				() => navigate(AppUrls.login),
			);
		}
	}, [dispatch, location.pathname, navigate, userInfo]);

	useEffect(() => {
		if (authorized) {
			handleWithTryCatch(() => dispatch(getUserInfo()));
		}
	}, [authorized, dispatch]);

	useEffect(() => {
		(async () => {
			if (authorized && userInfo?.email) {
				await handleWithTryCatch(async () => await dispatch(getFrameworks()));
				await handleWithTryCatch(async () => await dispatch(getAllBaseControls()));

				if (window.location.pathname !== AppUrls.companies)
					await handleWithTryCatch(async () => await dispatch(getCompanies()));

				if (window.location.pathname !== AppUrls.admins)
					await handleWithTryCatch(async () => await dispatch(getAdmins()));

				if (window.location.pathname !== AppUrls.policies)
					await handleWithTryCatch(async () => await dispatch(getGSPolicies()));
			}
		})();
	}, [authorized, dispatch, userInfo?.email]);
}
