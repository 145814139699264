import { FC } from 'react';
import styles from './ModalLeaveWarning.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import IModalLeaveWarning from './IModalLeaveWarning';
import ActionsBlock from '../../../primitives/actions-block/ActionsBlock';
import Button from '../../../primitives/button/Button';

const ModalLeaveWarning: FC<IModalLeaveWarning> = ({ open, setOpen, onConfirm }) => {
	const onConfirmHandler = () => {
		setOpen(false);
		onConfirm();
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Discard changes?</h2>

				<p className={styles.text}>You have unsaved changes, do you want to discard them?</p>

				<ActionsBlock>
					<Button type="button" width={160} negative onClick={() => setOpen(false)}>
						No, Cancel
					</Button>

					<Button type="button" width={160} onClick={onConfirmHandler}>
						Yes, Discard
					</Button>
				</ActionsBlock>
			</BasicPanel>
		</Modal>
	);
};

export default ModalLeaveWarning;
