import { useSelector } from 'react-redux';
import { RootState } from '../../../services/store';

export default function useBaseControls() {
	const { loading, baseControls, nistControls } = useSelector(
		(store: RootState) => store.baseControls,
	);

	return {
		baseControls,
		loading,
		nistControls,
	};
}
