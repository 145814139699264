import React, { FC, useRef, useState } from 'react';
import styles from './HoverTooltip.module.scss';
import { IHoverTooltip } from './IHoverTooltip';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';

const HoverTooltip: FC<IHoverTooltip> = ({
	className,
	tooltipId,
	withIcon,
	place,
	warning,
	dark,
}) => {
	const ref = useRef<any>(null);
	const [isTopStart, setIsTopStart] = useState(false);
	const [isBottomStart, setIsBottomStart] = useState(false);

	const afterShowHandler = () => {
		if (ref.current) {
			setIsTopStart(ref.current?.place === 'top-start');
			setIsBottomStart(ref.current?.place === 'bottom-start');
		}
	};

	return (
		<div
			className={classNames(
				styles['tooltip-wrap'],
				className,
				withIcon ? styles['with-icon'] : '',
				warning ? styles.warning : '',
			)}
		>
			<Tooltip
				ref={ref}
				id={tooltipId}
				className={classNames(
					styles.tooltip,
					isTopStart ? styles['top-start'] : '',
					isBottomStart ? styles['bottom-start'] : '',
					dark ? styles.dark : '',
				)}
				offset={3}
				place={place || 'top-start'}
				noArrow
				afterShow={afterShowHandler}
				afterHide={() => setIsTopStart(false)}
			/>
		</div>
	);
};

export default HoverTooltip;
