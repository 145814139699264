import { FC, useCallback, useEffect, useState } from 'react';
import styles from './EditControl.module.scss';
import Button from '../../../primitives/button/Button';
import ActionsBlock from '../../../primitives/actions-block/ActionsBlock';
import Drawer from '../../../primitives/drawer/Drawer';
import ModalLeaveWarning from '../../modals/modal-leave-warning/ModalLeaveWarning';
import useLeaveWarning from '../../../../utils/helpers/hooks/useLeaveWarning';
import Scrollbar from '../../../primitives/scrollbar/Scrollbar';
import IEditControlRecord from './IEditControl';
import SectionTitle from '../../../primitives/section-title/SectionTitle';
import CollapsiblePanel from '../../../primitives/collapsible-panel/CollapsiblePanel';
import ControlsBlock from '../../controls-block/ControlsBlock';
import {
	updateSupplementaryCrosswalk,
} from '../../../../services/store/slices/crosswalk.slice';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';

const EditControl: FC<IEditControlRecord> = ({
	controlRecord,
	closeHandler,
	onUpdateError,
	onUpdateSuccess,
	open,
	type,
}) => {
	const dispatch = useAppDispatch();
	const [currentControls, setCurrentControls] = useState<string[]>([]);
	const { id, controlId, enhancementName, controls } = controlRecord || {};

	useEffect(() => {
		setCurrentControls(controls?.map((c) => c.id) || []);
	}, [controls]);

	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [showBrowserLeaveWarning, setBrowserShowLeaveWarning] = useState(false);

	const onFormSubmitHandler = () => {
		handleWithTryCatch(
			async () => {
				await dispatch(
					updateSupplementaryCrosswalk(
						{
							id: id!,
							controls: currentControls.map((c) => Number(c)),
						},
						type,
					),
				);
				onUpdateSuccess(id!);
			},
			undefined,
			() => onUpdateError(),
		);
	};

	const formActions = (
		<ActionsBlock className={styles.actions}>
			<Button type="submit" width={137} onClick={onFormSubmitHandler}>
				Save
			</Button>
		</ActionsBlock>
	);

	const renderDetails = () => {
		const trigger = <SectionTitle className={styles.details}>Details</SectionTitle>;

		return (
			<CollapsiblePanel trigger={trigger}>
				<h6>{controlId}</h6>

				<p className={styles.name}>{enhancementName}</p>
			</CollapsiblePanel>
		);
	};

	const renderControls = () => {
		const trigger = <SectionTitle>NIST Controls</SectionTitle>;

		return (
			<CollapsiblePanel trigger={trigger}>
				<ControlsBlock
					entityControlIds={currentControls}
					setControlIds={setCurrentControls}
					controlsType="nist"
				/>
			</CollapsiblePanel>
		);
	};

	const editControl = (
		<div className={styles['edit-control']}>
			<div className={styles.container}>
				<Scrollbar className={styles.scrollbar}>
					<div className={styles.content}>
						{renderDetails()}

						{renderControls()}
					</div>
				</Scrollbar>

				{formActions}
			</div>
		</div>
	);

	const changesWereMade = useCallback(() => {
		if (id) {
			if (currentControls.length !== controls?.length) return true;

			return (
				JSON.stringify(currentControls.sort()) !==
				JSON.stringify(controls?.map((c) => c.id).sort())
			);
		}
	}, [id, controls, currentControls]);

	const modals = (
		<ModalLeaveWarning
			open={warningModalOpen}
			setOpen={setWarningModalOpen}
			onConfirm={() => {
				setBrowserShowLeaveWarning(false);
				closeHandler();
			}}
		/>
	);

	useLeaveWarning(showBrowserLeaveWarning);

	useEffect(() => {
		if (changesWereMade()) setBrowserShowLeaveWarning(true);
	}, [changesWereMade]);

	useEffect(() => {
		if (!open) setBrowserShowLeaveWarning(false);
	}, [open]);

	return (
		<Drawer
			contentClassName={styles.drawer}
			open={open}
			title="Control Details"
			onCloseClickHandler={() => {
				if (changesWereMade()) setWarningModalOpen(true);
				else closeHandler();
			}}
		>
			{open ? editControl : null}

			{modals}
		</Drawer>
	);
};

export default EditControl;
