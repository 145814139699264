import { FC, useState } from 'react';
import styles from './ModalDeletePolicy.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import ActionsBlock from '../../../primitives/actions-block/ActionsBlock';
import Button from '../../../primitives/button/Button';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import IModalDeletePolicy from './IModalDeletePolicy';
import { deletePolicy } from '../../../../services/store/slices/policies.slice';
import Loader from '../../../primitives/loader/Loader';

const ModalDeletePolicy: FC<IModalDeletePolicy> = ({
	open,
	setOpen,
	id,
	name,
	onCancel,
	onDeleteError,
	onDeleteSuccess,
}) => {
	const dispatch = useAppDispatch();
	const [deleteLoading, setDeleteLoading] = useState(false);

	const onDelete = () => {
		setDeleteLoading(true);

		handleWithTryCatch(
			async () => {
				if (id) {
					await dispatch(deletePolicy(id));
					setDeleteLoading(false);
					onDeleteSuccess();
					setOpen(false);
				}
			},
			undefined,
			() => {
				setDeleteLoading(false);
				onDeleteError();
			},
		);
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Delete Policy?</h2>

				<p className={styles.text}>
					Are you sure you want to delete <strong>{name}</strong> from policies?
				</p>

				<ActionsBlock>
					<Button type="button" width={160} negative onClick={onCancel}>
						Cancel
					</Button>

					<Button
						errorStyled
						type="button"
						width={160}
						disabled={deleteLoading}
						onClick={onDelete}
					>
						{deleteLoading ? (
							<Loader
								thin
								maxHeight={14}
								maxWidth={14}
								color={'#ffffff'}
								transparentColor="transparent"
							/>
						) : (
							'Delete'
						)}
					</Button>
				</ActionsBlock>
			</BasicPanel>
		</Modal>
	);
};

export default ModalDeletePolicy;
