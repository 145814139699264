import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ISortProps, IKeyValuePair, CommonResultStatusType } from '../../../../utils/types';
import Table from '../../../primitives/table/Table';
import TableFilters from '../../../primitives/table-filters/TableFilters';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import styles from './GSPoliciesTable.module.scss';
import { getGSPolicyColumns } from './utils';
import usePagination from '../../../../utils/helpers/hooks/usePagination';
import { IGSPolicy } from '../../../../services/store/slices/policies.slice';
import useDevice from '../../../../utils/helpers/hooks/useDevice/useDevice';
import TableSkeleton from '../../../primitives/table/TableSkeleton';
import classNames from 'classnames';
import {
	getBaseControlOptions,
	getFrameworkOptions,
	processTableData,
} from '../../../../utils/helpers/common';
import useFrameworks from '../../../../utils/helpers/hooks/useFrameworks';
import ActionButton from '../../../primitives/action-button/ActionButton';
import HoverTooltip from '../../../primitives/tooltip/HoverTooltip';
import Alert from '../../../primitives/alert/Alert';
import { AppUrls } from '../../../../utils/helpers/constants/app-routes';
import { AppQueryParams } from '../../../../utils/helpers/constants';
import { useNavigate } from 'react-router-dom';
import StatusFilter from '../../../primitives/filters/status-filter/StatusFilter';
import { PolicyStatusOptions } from '../../../../utils/helpers/constants';
import IPartialTable from '../common/IPartialTable';
import SearchFilter from '../../../primitives/filters/search-filter/SearchFilter';
import FrameworkFilter from '../../../primitives/filters/framework-filter/FrameworkFilter';
import ControlsFilter from '../../../primitives/filters/controls-filter/ControlsFilter';
import EditGSPolicy from '../../edit-panels/policy/EditGSPolicy';
import ModalDeletePolicy from '../../modals/modal-delete-policy/ModalDeletePolicy';
import Button from '../../../primitives/button/Button';
import ModalCreatePolicy from '../../modals/modal-create-policy/ModalCreatePolicy';
import useBaseControls from '../../../../utils/helpers/hooks/useBaseControls';

const GSPoliciesTable: FC<IPartialTable<IGSPolicy>> = ({ data, dataLoading, showItemId }) => {
	const device = useDevice();
	const navigate = useNavigate();
	const { items: frameworkItems } = useFrameworks();
	const { baseControls } = useBaseControls();

	const [processedData, setProcessedData] = useState(data);
	const [currentPageData, setCurrentPageData] = useState<IGSPolicy[]>([]);
	const [isProcessing, setIsProcessing] = useState(true);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [deleteResult, setDeleteResult] = useState<CommonResultStatusType>('');
	const [policyToDelete, setPolicyToDelete] = useState<string | null>(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [addNewModalOpen, setAddNewModalOpen] = useState(false);

	const [updatedPolicyId, setUpdatedPolicyId] = useState<string | null>('');
	const [updateResult, setUpdateResult] = useState<CommonResultStatusType>('');
	const [addedPolicy, setAddedPolicy] = useState('');
	const [addNewResult, setAddNewResult] = useState<CommonResultStatusType>('');

	const { paginationSection, setCurrentPage, pagesCount, goToPageByDataEntryProperty } =
		usePagination(processedData, setCurrentPageData);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IGSPolicy) => true,
		frameworkFilter: (dataRecord: IGSPolicy) => true,
		controlsFilter: (dataRecord: IGSPolicy) => true,
		statusFilter: (dataRecord: IGSPolicy) => true,
	});

	const tableColumnsConfig = useMemo(() => getGSPolicyColumns(device), [device]);

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onRowClickHandler = useCallback(
		(id: string) => navigate(`${AppUrls.policies}?${AppQueryParams.item}=${id}`),
		[navigate],
	);

	const tableFilters = useMemo(() => {
		const leftSection = (
			<Fragment>
				<SearchFilter
					disabled={dataLoading || !data.length}
					placeholder="Search policy..."
					setFilters={setCurrentFilters}
					properties={['name']}
				/>
				<StatusFilter
					disabled={dataLoading || !data.length}
					options={PolicyStatusOptions}
					setFilters={setCurrentFilters}
				/>
				<FrameworkFilter
					disabled={dataLoading || !data.length}
					options={getFrameworkOptions(frameworkItems || [])}
					setFilters={setCurrentFilters}
				/>
				<ControlsFilter
					disabled={dataLoading || !data.length}
					options={getBaseControlOptions(baseControls || [])}
					setFilters={setCurrentFilters}
					propertyName="baseControls"
				/>
			</Fragment>
		);
		const rightSection = (
			<Button width={125} onClick={() => setAddNewModalOpen(true)}>
				Create Policy
			</Button>
		);

		return <TableFilters leftSection={leftSection} rightSection={rightSection} />;
	}, [baseControls, data.length, dataLoading, frameworkItems]);

	const onPolicyUpdateSuccess = () => {
		setUpdatedPolicyId(showItemId || '');
		setUpdateResult('success');
		setOpenDrawer(false);
		navigate(`${AppUrls.policies}`);
	};

	const onPolicyUpdateError = () => setUpdateResult('error');

	const renderRowActions = useMemo(() => {
		return [
			{
				actionComponent: (
					<ActionButton
						tooltipId={'tooltip-delete'}
						tooltipContent={'Delete'}
						type="cancel"
					/>
				),
				actionHandler: (id: string) => setPolicyToDelete(id),
			},
		];
	}, []);

	const mainTable = useMemo(
		() => (
			<Table
				tableKey="gs-policies"
				data={currentPageData}
				columns={tableColumnsConfig}
				options={{
					rowActions: renderRowActions,
					onRowClickHandler,
					onSortChange,
					successHighlightedRows: [updatedPolicyId || addedPolicy || ''],
					emptyResultMessage:
						!dataLoading && !data.length
							? `No policies to display yet.`
							: `No matches found. Please try another search query.`,
					emptyResultMessageType: !dataLoading && !data.length ? 'common' : 'search',
				}}
			/>
		),
		[
			addedPolicy,
			currentPageData,
			data.length,
			dataLoading,
			onRowClickHandler,
			onSortChange,
			renderRowActions,
			tableColumnsConfig,
			updatedPolicyId,
		],
	);

	const detailsDrawer = (
		<EditGSPolicy
			open={openDrawer}
			policy={data.find((policy) => policy.id === showItemId)}
			onUpdateSuccess={onPolicyUpdateSuccess}
			onUpdateError={onPolicyUpdateError}
			onDeleteSuccess={() => setDeleteResult('success')}
			closeHandler={() => {
				setOpenDrawer(false);
				navigate(AppUrls.policies);
			}}
		/>
	);

	const alerts = (
		<>
			<Alert
				uniqueKey={'delete-error'}
				show={deleteResult === 'error'}
				type="error"
				message="Error while policy delete."
				clearActionStatus={() => setDeleteResult('')}
			/>

			<Alert
				uniqueKey={'delete-success'}
				show={deleteResult === 'success'}
				type="success"
				message="Policy deleted."
				clearActionStatus={() => setDeleteResult('')}
			/>

			<Alert
				uniqueKey={'policy-success'}
				show={updateResult === 'success'}
				type="success"
				message="Policy updated!"
				clearActionStatus={() => setUpdateResult('')}
			/>
			<Alert
				uniqueKey={'policy-error'}
				show={updateResult === 'error'}
				type="error"
				message="Error occurred while updating policy"
				clearActionStatus={() => setUpdateResult('')}
			/>

			<Alert
				uniqueKey={'create-success'}
				show={addNewResult === 'success'}
				type="success"
				message="Policy created!"
				clearActionStatus={() => setAddNewResult('')}
			/>

			<Alert
				uniqueKey={'create-error'}
				show={addNewResult === 'error'}
				type="error"
				message="Error occurred while creating policy. Please try again."
				clearActionStatus={() => setAddNewResult('')}
			/>
		</>
	);

	const renderModals = () => {
		const foundedPolicy = data?.find((policy) => policy.id === policyToDelete);

		return (
			<>
				<ModalDeletePolicy
					open={deleteModalOpen}
					setOpen={setDeleteModalOpen}
					id={policyToDelete || ''}
					name={foundedPolicy?.name}
					onDeleteSuccess={() => setDeleteResult('success')}
					onDeleteError={() => setDeleteResult('error')}
					onCancel={() => {
						setDeleteModalOpen(false);
						setPolicyToDelete('');
					}}
				/>

				<ModalCreatePolicy
					open={addNewModalOpen}
					setOpen={setAddNewModalOpen}
					onAddSuccess={(id: string) => {
						setAddedPolicy(id);
						setAddNewResult('success');
					}}
					onAddError={() => setAddNewResult('error')}
				/>
			</>
		);
	};

	useEffect(() => {
		if (updatedPolicyId && data.find((p) => p.id === updatedPolicyId)) {
			goToPageByDataEntryProperty(updatedPolicyId, 'id');

			const addDelay = setTimeout(() => {
				setUpdatedPolicyId(null);
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [data, goToPageByDataEntryProperty, updatedPolicyId]);

	useEffect(() => {
		if (addedPolicy && data.find((p) => p.id === addedPolicy)) {
			goToPageByDataEntryProperty(addedPolicy, 'id');

			const addDelay = setTimeout(() => {
				setAddedPolicy('');
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [data, goToPageByDataEntryProperty, addedPolicy]);

	useEffect(() => {
		if (policyToDelete) setDeleteModalOpen(true);
	}, [policyToDelete]);

	useEffect(() => {
		setOpenDrawer(!!showItemId);
	}, [showItemId]);

	useEffect(() => {
		if (showItemId) goToPageByDataEntryProperty(showItemId, 'id');
	}, [goToPageByDataEntryProperty, showItemId]);

	useEffect(() => {
		setIsProcessing(true);
		setCurrentPage(1);

		const proccessedData: IGSPolicy[] = processTableData(data, currentFilters, currentSort);

		setProcessedData(proccessedData);
		setIsProcessing(false);
	}, [currentFilters, currentSort, data, setCurrentPage]);

	return (
		<>
			<BasicPanel
				className={classNames(styles['basic-panel'], isProcessing ? styles.disabled : '')}
			>
				{tableFilters}

				{isProcessing || dataLoading ? (
					<TableSkeleton columns={tableColumnsConfig} />
				) : (
					mainTable
				)}
			</BasicPanel>

			{pagesCount > 1 ? paginationSection : null}

			{detailsDrawer}

			{renderModals()}

			<HoverTooltip tooltipId="tooltip-delete" place="top-end" />

			{alerts}
		</>
	);
};

export default GSPoliciesTable;
