import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import SidebarLayout from '../../../../components/layouts/sidebar-layout/SidebarLayout';
import useUser from '../../hooks/useUser';
import { LayoutType } from '../../constants';
import { AppUrls } from '../../constants/app-routes';

const ProtectedRoute: FC<PropsWithChildren & { layout?: LayoutType }> = ({ children, layout }) => {
	const { authorized } = useUser();

	if (authorized !== null && !authorized) return <Navigate to={AppUrls.login} replace />;
	return <SidebarLayout>{children}</SidebarLayout>;
};

export default ProtectedRoute;
