import { FC, useEffect, useState } from 'react';
import styles from './DetailsStep.module.scss';
import ActionsBlock from '../../../../../primitives/actions-block/ActionsBlock';
import Button from '../../../../../primitives/button/Button';
import Select from '../../../../../primitives/form/select/Select';
import Input from '../../../../../primitives/form/input/Input';
import { commonStringIsValid } from '../../../../../../utils/helpers/common/form';
import classNames from 'classnames';
import IDetailsStep from './IDetailsStep';
import { getFrameworkOptions } from '../../../../../../utils/helpers/common';
import useFrameworks from '../../../../../../utils/helpers/hooks/useFrameworks';
import Textarea from '../../../../../primitives/form/textarea/Textarea';
import Checkbox from '../../../../../primitives/form/checkbox/Checkbox';
import HoverTooltip from '../../../../../primitives/tooltip/HoverTooltip';

const DetailsStep: FC<IDetailsStep> = ({
	currentFormState,
	setCurrentFormState,
	setOpen,
	setNextStep,
	className,
}) => {
	const { items: frameworkItems } = useFrameworks();

	const [nextIsDisabled, setNextIsDisabled] = useState(true);

	const formActions = (
		<ActionsBlock className={styles.actions}>
			<Button type="button" width={136} onClick={() => setOpen(false)} negative>
				Cancel
			</Button>

			<Button
				disabled={nextIsDisabled}
				type="button"
				width={136}
				onClick={() => {
					if (!nextIsDisabled) setNextStep();
				}}
			>
				Next
			</Button>
		</ActionsBlock>
	);

	useEffect(() => {
		if (
			currentFormState.name &&
			commonStringIsValid(currentFormState.name) &&
			(!currentFormState.description ||
				(currentFormState.description &&
					commonStringIsValid(currentFormState.description))) &&
			(!currentFormState.preamble ||
				(currentFormState.preamble && commonStringIsValid(currentFormState.preamble))) &&
			currentFormState.framework
		) {
			setNextIsDisabled(false);
		} else {
			setNextIsDisabled(true);
		}
	}, [
		currentFormState.description,
		currentFormState.framework,
		currentFormState.name,
		currentFormState.preamble,
	]);

	const createForm = (
		<div className={classNames(styles['add-policy'], className)}>
			<div className={styles.inputs}>
				<Input
					className={styles.input}
					withErrorStyle={!commonStringIsValid(currentFormState.name)}
					type="text"
					label="Policy Name"
					placeholder="Enter policy name"
					value={currentFormState.name}
					onValueChange={(value) => {
						setCurrentFormState!((prev) => ({
							...prev,
							name: value,
						}));
					}}
				/>

				<Input
					className={styles.input}
					withErrorStyle={!commonStringIsValid(currentFormState.description)}
					type="text"
					label="Description (optional)"
					placeholder="Enter description"
					value={currentFormState.description}
					onValueChange={(value) => {
						setCurrentFormState!((prev) => ({
							...prev,
							description: value,
						}));
					}}
				/>

				<Textarea
					onValueChange={(value) =>
						setCurrentFormState!((prev) => ({ ...prev, preamble: value }))
					}
					value={currentFormState?.preamble}
					className={styles.input}
					withErrorStyle={!commonStringIsValid(currentFormState.preamble)}
					placeholder="Enter or paste policy preamble here..."
					label="Preamble (optional)"
				/>

				<Select
					id="framework"
					className={styles.input}
					options={getFrameworkOptions(frameworkItems || [])}
					label="Framework"
					value={currentFormState.framework}
					onValueChange={(value) => {
						setCurrentFormState!((prev) => ({
							...prev,
							framework: value,
						}));
					}}
					placeholder="Select framework"
				/>

				<div className={styles['checkbox-group']}>
					<Checkbox
						className={styles.input}
						id="default"
						text="Default policy"
						checked={currentFormState.isInitial}
						onChange={(e) =>
							setCurrentFormState!((prev) => ({
								...prev,
								isInitial: e.target.checked,
							}))
						}
					/>
					<span
						data-tooltip-id="tooltip-default"
						data-tooltip-content="This policy will be added as a default template for new users"
						className={styles.info}
					></span>
				</div>
			</div>

			{formActions}
		</div>
	);

	return (
		<>
			{createForm}

			<HoverTooltip tooltipId="tooltip-default" place="bottom-start" />
		</>
	);
};

export default DetailsStep;
