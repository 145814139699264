import axios from 'axios';
import { COMPANIES } from './api.path';
import { ICompany, ICompanyBasic } from '../store/slices/companies.slice';

const companiesApi = {
	getCompanies: (): Promise<ICompanyBasic[]> => {
		return axios.get(`${COMPANIES}`);
	},
	getCompanyById: (id: string): Promise<ICompanyBasic> => {
		return axios.get(`${COMPANIES}/${id}`);
	},
	addNewCompany: (data: any): Promise<{ companyId: number }> => {
		return axios.post(`${COMPANIES}`, data);
	},
	deleteCompany: (companyId: string) => {
		return axios.delete(`${COMPANIES}/${companyId}`);
	},
	updateCompany: (companyId: string, data: Partial<ICompany>) => {
		return axios.put(`${COMPANIES}/${companyId}`, data);
	},
	resetCompany: (companyId: string) => {
		return axios.put(`${COMPANIES}/reset/${companyId}`);
	},
	bulkStatusUpdate: (companyIds: number[], status: string) => {
		return axios.put(`${COMPANIES}/status/bulk-update`, { companyIds, status });
	},
};

export default companiesApi;
