import { FC, useState } from 'react';
import styles from './ModalDeleteCompany.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import ActionsBlock from '../../../primitives/actions-block/ActionsBlock';
import Button from '../../../primitives/button/Button';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import IModalDeleteCompany from './IModalDeleteCompany';
import Loader from '../../../primitives/loader/Loader';
import { deleteCompany } from '../../../../services/store/slices/companies.slice';

const ModalDeleteCompany: FC<IModalDeleteCompany> = ({
	open,
	setOpen,
	id,
	name,
	onDeleteError,
	onDeleteSuccess,
	onCancel,
}) => {
	const dispatch = useAppDispatch();
	const [deleteLoading, setDeleteLoading] = useState(false);

	const onDelete = () => {
		setDeleteLoading(true);

		handleWithTryCatch(
			async () => {
				if (id) {
					await dispatch(deleteCompany(id));
					onDeleteSuccess();
					setOpen(false);
					setDeleteLoading(false);
				}
			},
			undefined,
			() => {
				onDeleteError();
				setDeleteLoading(false);
			},
		);
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Delete Company?</h2>

				<p className={styles.text}>
					Are you sure you want to delete <strong>{name}</strong> from companies?
				</p>

				<ActionsBlock>
					<Button type="button" width={160} negative onClick={onCancel}>
						Cancel
					</Button>

					<Button
						errorStyled
						type="button"
						width={160}
						onClick={onDelete}
						disabled={deleteLoading}
					>
						{deleteLoading ? (
							<Loader
								thin
								maxHeight={14}
								maxWidth={14}
								color={'#ffffff'}
								transparentColor="transparent"
							/>
						) : (
							'Delete'
						)}
					</Button>
				</ActionsBlock>
			</BasicPanel>
		</Modal>
	);
};

export default ModalDeleteCompany;
