import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import frameworksApi from '../../api/frameworks.api';
import { getFrameworkNameWithoutSubType } from '../../../utils/helpers/common';

export interface IFrameworksSlice {
	loading?: boolean;
}

export interface IFrameworkBasic {
	id: number;
	name: string;
	description: string;
	revisionNumber: string;
	type: string;
}

export interface IFramework {
	id: string;
	displayName: string;
	name: string;
	description: string;
	type: string;
}

export interface IFrameworks extends IFrameworksSlice {
	items: IFramework[] | null;
}

const initialState: IFrameworks = {
	loading: false,
	items: null,
};

export const frameworksSlice = createSlice({
	name: 'frameworks',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		setFrameworks: (state, { payload }: PayloadAction<IFramework[]>) => {
			state.items = payload;
		},
	},
});

export const getFrameworks = () => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const frameworks = await frameworksApi.getFrameworks();

		if (frameworks) {
			const transformedFrameworks: IFramework[] = frameworks.map(
				(framework: IFrameworkBasic) => ({
					id: framework.id?.toString(),
					displayName: getFrameworkNameWithoutSubType(framework.name),
					name: framework.name,
					description: framework.description,
					type: framework.type,
				}),
			);

			dispatch(setFrameworks(transformedFrameworks));
		}
	} catch (error: any) {
		dispatch(setFrameworks([]));
	} finally {
		dispatch(loaded());
	}
};

export const { loading, loaded, setFrameworks } = frameworksSlice.actions;
export default frameworksSlice.reducer;
