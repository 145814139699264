import { IGSPolicy } from '../../../../services/store/slices/policies.slice';
import { controlsColumnRenderHelper } from '../../../../utils/helpers/common';
import { CommonStatusDisplayNames, StatusColors } from '../../../../utils/helpers/constants';
import { DeviceType } from '../../../../utils/helpers/hooks/useDevice/IUseDevice';
import { IIdName } from '../../../../utils/types';
import { ITableColumn } from '../../../primitives/table/ITable';
import ColoredTag from '../../../primitives/tag/ColoredTag';

export const getGSPolicyColumns = (device?: DeviceType | null) => {
	return [
		{
			header: 'Policy Name',
			key: 'name',
			sorting: true,
			width: '22%',
			columnRender: (value: string, rowData: IGSPolicy) => {
				return <h4>{value}</h4>;
			},
		},
		{
			header: 'Controls',
			key: 'baseControls',
			width: '18%',
			columnRender: (baseControls: IIdName[]) =>
				controlsColumnRenderHelper(
					baseControls.map((c) => c.displayId!),
					device,
				),
		},
		{
			header: 'Frameworks',
			key: 'framework',
			sorting: true,
			width: '18%',
			columnRender: (framework: IIdName) => (framework ? framework.name : ''),
		},
		{
			header: 'Version',
			key: 'version',
			sorting: true,
			width: '10%',
		},
		{
			header: 'Status',
			key: 'status',
			sorting: true,
			width: '12%',
			columnRender: (status: string) => {
				const statusColor = StatusColors[status as keyof typeof StatusColors];

				return (
					<ColoredTag
						tagId={status}
						key={status}
						text={
							CommonStatusDisplayNames[
								status as keyof typeof CommonStatusDisplayNames
							]
						}
						bgColor={statusColor}
					/>
				);
			},
		},
		{
			header: 'Updated Time',
			key: 'updatedAt',
			sorting: true,
			width: '15%',
			columnRender: (value: string) => {
				return (
					<ColoredTag
						borderColor={'#F4F9EB'}
						textColor="#7A926A"
						tagId={value}
						text={value}
						withoutBackground
					/>
				);
			},
		},
		{
			header: '',
			key: 'actions',
			width: '5%',
		},
	] as ITableColumn[];
};
