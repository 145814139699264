import React from "react";
import styles from "./Sidebar.module.scss";
import Navigation from "../navigation/Navigation";
import UserProfile from "../user-profile/UserProfile";
import { Link } from "react-router-dom";
import { AppUrls } from "../../../utils/helpers/constants/app-routes";

const Sidebar = () => {
  return (
    <aside className={styles.sidebar}>
      <div className={styles.top}>
        <Link to={AppUrls.companies} className={styles.logo}></Link>

        <Navigation />
      </div>

      <div className={styles.bottom}>
        <UserProfile />
      </div>
    </aside>
  );
};

export default React.memo(Sidebar);
