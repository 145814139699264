import { FC } from 'react';
import styles from './AILogDetails.module.scss';
import LoadingSkeleton from '../../../primitives/loading-skeleton/LoadingSkeleton';

const AILogDetailsSkeleton: FC = () => {
	const editForm = (
		<>
			<LoadingSkeleton containerClassName={styles['section-title']} height={24} />

			<LoadingSkeleton containerClassName={styles['section-title']} height={24} />

			<LoadingSkeleton containerClassName={styles.item} height={20} />
			<LoadingSkeleton containerClassName={styles.item} height={20} />
			<LoadingSkeleton containerClassName={styles.item} height={20} />
		</>
	);

	return <div className={styles['log-entry-details-skeleton']}>{editForm}</div>;
};

export default AILogDetailsSkeleton;
