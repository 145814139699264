import { FC } from 'react';
import styles from './EditAdmin.module.scss';
import LoadingSkeleton from '../../../primitives/loading-skeleton/LoadingSkeleton';

const EditAdminSkeleton: FC = () => {
	const editForm = (
		<div className={styles.form}>
			<div className={styles.inputs}>
				<div className={styles['input-group']}>
					<LoadingSkeleton height={54} />

					<LoadingSkeleton height={54} />
				</div>

				<div className={styles['input-group']}>
					<LoadingSkeleton height={54} />

					<LoadingSkeleton height={54} />
				</div>

				<div className={styles['input-group']}>
					<LoadingSkeleton height={54} />

					<LoadingSkeleton height={54} />
				</div>
			</div>
		</div>
	);

	return <div className={styles['edit-admin-skeleton']}>{editForm}</div>;
};

export default EditAdminSkeleton;
