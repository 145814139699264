import React, { FC, useCallback, useState } from 'react';

import IDownloadFile from './IDownloadFile';
import { useFileDownload } from '../../../utils/helpers/hooks/useFileDownload';
import { handleWithTryCatch } from '../../../utils/helpers/errors';
import Alert from '../../primitives/alert/Alert';
import ActionProgress from '../../primitives/action-progress/ActionProgress';
import ActionButton from '../../primitives/action-button/ActionButton';

const DownloadFile: FC<IDownloadFile> = ({
	entityId,
	fileUrl,
	fileName,
	tooltipId,
	tooltipContent,
	onFileLoading,
	className,
}) => {
	const [fileLoading, setFileLoading] = useState(false);
	const [downloadErrorMessage, setDownloadErrorMessage] = useState<string>('');

	const { progress, setProgress, setDownloadResult, downloadResult, downloadFileByUrl } =
		useFileDownload();

	const onFileDownload = useCallback(() => {
		onFileLoading((prev) => [...prev, entityId]);
		setFileLoading(true);
		setProgress(0);

		if (entityId) {
			handleWithTryCatch(
				async () => {
					//@ts-ignore
					await downloadFileByUrl(fileUrl, `${fileName}`);
					setTimeout(() => {
						setFileLoading(false);
						onFileLoading((prev) => prev.filter((fileId) => fileId !== entityId));
					}, 2000);
				},
				undefined,
				() => {
					setFileLoading(false);
					onFileLoading((prev) => prev.filter((fileId) => fileId !== entityId));
					setDownloadErrorMessage('Download failed' || '');
					setDownloadResult('error');
				},
			);
		}
	}, [
		onFileLoading,
		entityId,
		setProgress,
		downloadFileByUrl,
		fileUrl,
		fileName,
		setDownloadResult,
	]);

	const alerts = (
		<>
			<Alert
				uniqueKey={'download-success'}
				show={downloadResult === 'success'}
				type="success"
				message="File downloaded"
				clearActionStatus={() => setDownloadResult('')}
			/>
			<Alert
				uniqueKey={'download-error'}
				show={downloadResult === 'error'}
				type="error"
				message={downloadErrorMessage || "File wasn't downloaded. Please try again"}
				clearActionStatus={() => setDownloadResult('')}
			/>
		</>
	);

	return (
		<>
			{fileLoading ? (
				<ActionProgress className={className} progress={progress} />
			) : (
				<ActionButton
					className={className}
					tooltipId={tooltipId}
					tooltipContent={tooltipContent}
					type="download"
					onClick={onFileDownload}
				/>
			)}

			{alerts}
		</>
	);
};

export default DownloadFile;
