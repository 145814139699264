import axios from 'axios';
import { AI_LOGS } from './api.path';
import { IAILogBasic, IAILogJobBasic } from '../store/slices/ai-logs.slice';

const aiLogsApi = {
	getAILogs: (): Promise<IAILogBasic[]> => {
		return axios.get(`${AI_LOGS}`);
	},
	getAILogById: (id: string): Promise<IAILogJobBasic> => {
		return axios.get(`${AI_LOGS}/${id}`);
	},
};

export default aiLogsApi;
