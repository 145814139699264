import { FC, useEffect, useState } from 'react';
import styles from './AdminStep.module.scss';
import ActionsBlock from '../../../../../primitives/actions-block/ActionsBlock';
import Button from '../../../../../primitives/button/Button';
import IAdminStep from './IAdminStep';
import { emailIsValid, nameStringIsValid } from '../../../../../../utils/helpers/common/form';
import classNames from 'classnames';
import Input from '../../../../../primitives/form/input/Input';
import useCompanies from '../../../../../../utils/helpers/hooks/useCompanies';
import Loader from '../../../../../primitives/loader/Loader';

const AdminStep: FC<IAdminStep> = ({
	currentFormState,
	setCurrentFormState,
	setOpen,
	className,
	userError,
}) => {
	const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
	const { loading } = useCompanies();

	const formActions = (
		<ActionsBlock className={styles.actions}>
			<Button type="button" width={136} onClick={() => setOpen(false)} negative>
				Cancel
			</Button>

			<Button type="submit" width={74} disabled={submitIsDisabled || loading}>
				{loading ? <Loader thin maxHeight={14} maxWidth={14} /> : 'Finish'}
			</Button>
		</ActionsBlock>
	);

	useEffect(() => {
		if (
			currentFormState.adminFirstName &&
			nameStringIsValid(currentFormState.adminFirstName) &&
			nameStringIsValid(currentFormState.adminLastName) &&
			emailIsValid(currentFormState.adminEmail) &&
			nameStringIsValid(currentFormState.adminTitle) &&
			currentFormState.adminLastName &&
			currentFormState.adminEmail &&
			currentFormState.adminTitle
		) {
			setSubmitIsDisabled(false);
		} else {
			setSubmitIsDisabled(true);
		}
	}, [currentFormState]);

	return (
		<div className={classNames(styles['add-admin'], className)}>
			<div className={styles.inputs}>
				<p className={styles.note}>
					Add a person responsible for the management of this organization's account.
				</p>

				<Input
					className={styles.input}
					withErrorStyle={!nameStringIsValid(currentFormState.adminFirstName)}
					type="text"
					label="First Name"
					placeholder="Enter first name"
					value={currentFormState.adminFirstName}
					onValueChange={(value) => {
						setCurrentFormState((prev) => ({
							...prev,
							adminFirstName: value,
						}));
					}}
				/>

				<Input
					className={styles.input}
					withErrorStyle={!nameStringIsValid(currentFormState.adminLastName)}
					type="text"
					label="Last Name"
					placeholder="Enter last name"
					value={currentFormState.adminLastName}
					onValueChange={(value) => {
						setCurrentFormState((prev) => ({
							...prev,
							adminLastName: value,
						}));
					}}
				/>

				<Input
					withErrorStyle={
						!!(
							userError ||
							(currentFormState.adminEmail &&
								!emailIsValid(currentFormState.adminEmail))
						)
					}
					error={userError ? 'User with such email already exists' : ''}
					className={styles.input}
					type="text"
					label="Email"
					placeholder="Enter email"
					value={currentFormState.adminEmail}
					onValueChange={(value) => {
						setCurrentFormState((prev) => ({
							...prev,
							adminEmail: value,
						}));
					}}
				/>

				<Input
					withErrorStyle={!nameStringIsValid(currentFormState.adminTitle)}
					className={styles.input}
					type="text"
					label="Title"
					placeholder="Enter title"
					value={currentFormState.adminTitle}
					onValueChange={(value) => {
						setCurrentFormState((prev) => ({
							...prev,
							adminTitle: value,
						}));
					}}
				/>
			</div>

			{formActions}
		</div>
	);
};

export default AdminStep;
