import React, { FC } from 'react';
import { Variants, motion } from 'framer-motion';
import { forwardRef } from 'react';
import IAnimatedScreenWrapper from './IAnimatedScreenWrapper';

export interface MotionAnimationType {
	in: any;
	out: any;
	initial?: any;
}

export const AnimatedScreenWrapper: FC<IAnimatedScreenWrapper> = forwardRef(
	(
		{
			children,
			inTransitionDuration = 0.4,
			outTransitionDuration = 0.1,
			isDrawer,
			drawerWidth = '46%',
			animate,
			className,
		},
		ref,
	) => {
		const pageVariants: Variants = {
			in: {},
			out: {},
		};

		if (isDrawer) {
			pageVariants.in = {
				x: 0,
				width: drawerWidth,
				transition: {
					type: 'spring',
					bounce: 0,
					duration: inTransitionDuration,
				},
			};

			pageVariants.out = {
				x: '100%',
				transition: {
					type: 'spring',
					bounce: 0,
					duration: outTransitionDuration,
				},
			};
		} else {
			pageVariants.in = {
				opacity: 1,
				transition: { type: 'spring', bounce: 0, duration: inTransitionDuration },
			};

			pageVariants.out = {
				opacity: 0,
				transition: { type: 'spring', bounce: 0, duration: outTransitionDuration },
			};
		}

		return (
			<motion.div
			// @ts-ignore
				ref={ref}
				initial="out"
				{...(!animate && { exit: 'out' })}
				className={className}
				animate={animate || 'in'}
				variants={pageVariants}
				children={children}
			/>
		);
	},
);
