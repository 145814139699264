import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import { sortUsersByFullName } from '../../../utils/helpers/common';
import { ICompany } from './companies.slice';
import adminsApi from '../../api/admins.api';

export interface IAdminsSlice {
	loading?: boolean;
	updateLoading?: boolean;
}

export interface IAdminBasic {
	id: string;
	firstName: string;
	lastName?: string;
	email?: string;
	title?: string;
	company?: {
		companyId: number;
		name: string;
	};
	companyId?: number;
	companyName?: string;
	industry: string;
}

export interface IAdmin extends Omit<IAdminBasic, 'company'> {
	company?: ICompany;
	fullName?: string;
}

export interface ICreateAdmin {
	firstName: string;
	lastName?: string;
	email?: string;
	title?: string;
	companyId?: string;
	role?: string;
}

export interface IUpdateAdmin {
	userId: string;
	firstName: string;
	lastName?: string;
	title?: string;
}

export interface IAdmins extends IAdminsSlice {
	items: IAdmin[] | null;
}

const initialState: IAdmins = {
	loading: false,
	updateLoading: false,
	items: null,
};

export const adminsSlice = createSlice({
	name: 'admins',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		updateLoading: (state) => {
			state.updateLoading = true;
		},
		updateLoaded: (state) => {
			state.updateLoading = false;
		},
		setAdmins: (state, { payload }: PayloadAction<IAdmin[]>) => {
			state.items = payload;
		},
	},
});

export const getAdmins = () => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const admins = await adminsApi.getAdmins();

		const transformedAdmins = admins.map((admin) => {
			return {
				...admin,
				id: admin.id.toString(),
				fullName: `${admin.firstName} ${admin.lastName}`,
				company: {
					id: admin.company?.companyId.toString() || '',
					name: admin.company?.name || '',
				},
			};
		});

		dispatch(setAdmins(sortUsersByFullName(transformedAdmins)));
	} catch (error: any) {
		dispatch(setAdmins([]));
	} finally {
		dispatch(loaded());
	}
};

export const getAdminById = (adminId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const admin = await adminsApi.getAdminById(adminId);

		return {
			...admin,
			id: admin.id.toString(),
			fullName: `${admin.firstName} ${admin.lastName}`,
			company: {
				id: admin.companyId?.toString() || '',
				name: admin.companyName || '',
				industry: admin.industry,
			},
		};
	} catch (error: any) {
		dispatch(setAdmins([]));
	} finally {
		dispatch(loaded());
	}
};

export const addNewAdmin = (data: ICreateAdmin) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		await adminsApi.addNewAdmin(data);
		await dispatch(getAdmins());
	} catch (error: any) {
		if (error && error.message?.includes('already exists')) {
			throw new CommonResponseError('Such user is already exists.');
		}

		throw new CommonResponseError('Error while adding admin.');
	} finally {
		dispatch(loaded());
	}
};

export const deleteAdmin = (adminId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		await adminsApi.deleteAdmin(adminId);
		await dispatch(getAdmins());
	} catch (error: any) {
		if (error && error.message?.includes('at least one admin'))
			throw new CommonResponseError('Company must have at least one admin');

		throw new CommonResponseError('Error while deleting admin.');
	} finally {
		dispatch(loaded());
	}
};

export const updateAdmin =
	(adminId: string, data: Partial<IUpdateAdmin>) => async (dispatch: Dispatch<any>) => {
		dispatch(updateLoading());

		try {
			await adminsApi.updateAdmin({
				userId: Number(adminId),
				...data,
			});

			await dispatch(getAdmins());
		} catch (error: any) {
			throw new CommonResponseError('Error while updating admin.');
		} finally {
			dispatch(updateLoaded());
		}
	};

export const { loading, loaded, updateLoading, updateLoaded, setAdmins } = adminsSlice.actions;
export default adminsSlice.reducer;
