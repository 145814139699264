import { FC } from 'react';
import styles from './EditCompany.module.scss';
import LoadingSkeleton from '../../../primitives/loading-skeleton/LoadingSkeleton';

const EditCompanySkeleton: FC = () => {
	const editForm = (
		<div className={styles.form}>
			<div className={styles.inputs}>
				<LoadingSkeleton containerClassName={styles['section-title']} height={24} />

				<div className={styles['input-group']}>
					<LoadingSkeleton height={54} />

					<LoadingSkeleton height={54} />
				</div>

				<LoadingSkeleton containerClassName={styles.input} height={54} />

				<div className={styles['input-group']}>
					<LoadingSkeleton height={54} />

					<LoadingSkeleton height={54} />
				</div>

				<div className={styles['input-group']}>
					<LoadingSkeleton height={54} />

					<LoadingSkeleton height={54} />
				</div>

				<LoadingSkeleton containerClassName={styles['section-title']} height={24} />

				<LoadingSkeleton containerClassName={styles.input} height={54} />

				<div className={styles['input-group']}>
					<LoadingSkeleton height={54} />

					<LoadingSkeleton height={54} />
				</div>
			</div>
		</div>
	);

	return <div className={styles['edit-company-skeleton']}>{editForm}</div>;
};

export default EditCompanySkeleton;
