import { FC, useEffect, useMemo, useState } from 'react';
import styles from './ModalAddControls.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import IModalAddControls from './IModalAddControls';
import ActionsBlock from '../../../primitives/actions-block/ActionsBlock';
import Button from '../../../primitives/button/Button';
import AddControlsTable from '../../tables/add-controls-table/AddControlsTable';
import useBaseControls from '../../../../utils/helpers/hooks/useBaseControls';

const ModalAddControls: FC<IModalAddControls> = ({
	predefinedItems,
	allPreselected = false,
	description,
	open,
	setOpen,
	onNewAdded,
	entityControlIds,
	setEntityControls,
	cancelBtnText = 'Cancel',
	withSearch = true,
	onCancel,
	controlsType,
}) => {
	const { nistControls: baseNistControlItems } =
		useBaseControls();

	const itemsToDisplay = useMemo(() => {
		if (predefinedItems?.length) return predefinedItems;

		if (controlsType === 'nist') return baseNistControlItems?.filter((c) => !entityControlIds.includes(c.id))

		return [];
	}, [baseNistControlItems, controlsType, entityControlIds, predefinedItems]);

	const [selectedControls, setSelectedControls] = useState<string[]>([]);
	const submitIsDisabled = !selectedControls.length;

	const onAdd = () => {
		const newControls = entityControlIds.length
			? [...entityControlIds, ...selectedControls].sort()
			: selectedControls;

		onNewAdded(selectedControls);
		setEntityControls(newControls);
		setSelectedControls([]);
		setOpen(false);
	};

	const renderActions = () => {
		return (
			<ActionsBlock className={styles.actions}>
				<Button
					width={136}
					type="button"
					negative
					onClick={() => {
						if (onCancel) onCancel();
						setSelectedControls([]);
						setOpen(false);
					}}
				>
					{cancelBtnText}
				</Button>

				<Button width={136} type="button" disabled={submitIsDisabled} onClick={onAdd}>
					Add Selected
				</Button>
			</ActionsBlock>
		);
	};

	useEffect(() => {
		if (allPreselected && itemsToDisplay?.length) {
			setSelectedControls(itemsToDisplay.map((control) => control.id));
		}
	}, [allPreselected, itemsToDisplay]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<div className={styles.top}>
					<h2 className={styles.title}>Add Controls</h2>

					{description ? <p className={styles.description}>{description}</p> : null}

					<div className={styles['table-wrap']}>
						<AddControlsTable
							data={itemsToDisplay || []}
							selectedRows={selectedControls}
							setSelectedRows={setSelectedControls}
							withSearch={withSearch}
						/>
					</div>
				</div>

				{renderActions()}
			</BasicPanel>
		</Modal>
	);
};

export default ModalAddControls;
