import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ISortProps, IKeyValuePair } from '../../../../utils/types';
import Table from '../../../primitives/table/Table';
import { processTableData } from '../../../../utils/helpers/common';
import TableFilters from '../../../primitives/table-filters/TableFilters';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import styles from './AILogsTable.module.scss';
import { getAILogsColumns } from './utils';
import usePagination from '../../../../utils/helpers/hooks/usePagination';
import TableSkeleton from '../../../primitives/table/TableSkeleton';
import classNames from 'classnames';
import SearchFilter from '../../../primitives/filters/search-filter/SearchFilter';
import IPartialTable from '../common/IPartialTable';
import { IAdmin } from '../../../../services/store/slices/admins.slice';
import { AppUrls } from '../../../../utils/helpers/constants/app-routes';
import { useNavigate } from 'react-router-dom';
import { AppQueryParams } from '../../../../utils/helpers/constants';
import { IAILog } from '../../../../services/store/slices/ai-logs.slice';
import AILogDetails from '../../edit-panels/ai-log-details/AILogDetails';
import Drawer from '../../../primitives/drawer/Drawer';

const AILogsTable: FC<IPartialTable<IAILog>> = ({ data, dataLoading, showItemId }) => {
	const navigate = useNavigate();

	const [processedData, setProcessedData] = useState(data);
	const [currentPageData, setCurrentPageData] = useState<IAdmin[]>([]);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [isProcessing, setIsProcessing] = useState(true);

	const { paginationSection, setCurrentPage, pagesCount, goToPageByDataEntryProperty } =
		usePagination(processedData, setCurrentPageData);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IAdmin) => true,
	});

	const tableColumnsConfig = useMemo(() => getAILogsColumns(), []);

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onRowClickHandler = useCallback(
		(id: string) => navigate(`${AppUrls.aiLogs}?${AppQueryParams.item}=${id}`),
		[navigate],
	);

	const detailsDrawer = (
		<>
			<Drawer
				title="Job Details"
				open={openDrawer}
				onCloseClickHandler={() => {
					setOpenDrawer(false);
					navigate(AppUrls.aiLogs);
				}}
			>
				{showItemId ? <AILogDetails logEntryId={showItemId || ''} /> : null}
			</Drawer>
		</>
	);

	const tableFilters = useMemo(() => {
		const leftSection = (
			<SearchFilter
				placeholder="Search logs..."
				setFilters={setCurrentFilters}
				properties={['jobId', 'companyName']}
			/>
		);

		return <TableFilters disabled={!dataLoading && !data.length} leftSection={leftSection} />;
	}, [data.length, dataLoading]);

	const mainTable = useMemo(
		() => (
			<Table
				tableKey="ai-logs"
				data={currentPageData}
				columns={tableColumnsConfig}
				options={{
					onSortChange,
					onRowClickHandler,
					emptyResultMessage:
						!dataLoading && !data.length
							? `No logs to display yet.`
							: `No matches found. Please try another search query.`,
				}}
			/>
		),
		[currentPageData, data, dataLoading, onRowClickHandler, onSortChange, tableColumnsConfig],
	);

	useEffect(() => {
		setOpenDrawer(!!showItemId);
	}, [showItemId]);

	useEffect(() => {
		if (showItemId) goToPageByDataEntryProperty(showItemId, 'id');
	}, [goToPageByDataEntryProperty, showItemId]);

	useEffect(() => {
		setIsProcessing(true);
		setCurrentPage(1);

		const proccessedData: IAILog[] = processTableData(data, currentFilters, currentSort);

		setProcessedData(proccessedData);
		setIsProcessing(false);
	}, [currentFilters, currentSort, data, setCurrentPage]);

	return (
		<>
			<BasicPanel
				className={classNames(styles['basic-panel'], isProcessing ? styles.disabled : '')}
			>
				{tableFilters}

				{isProcessing || dataLoading ? (
					<TableSkeleton rowsNumber={4} columns={tableColumnsConfig} />
				) : (
					mainTable
				)}
			</BasicPanel>

			{pagesCount > 1 ? paginationSection : null}

			{detailsDrawer}
		</>
	);
};

export default AILogsTable;
