import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import { dateInMDYFormat, sortByNameAsc } from '../../../utils/helpers/common';
import companiesApi from '../../api/companies.api';
import { getAdmins } from './admins.slice';
import { ICommonStatuses } from '../../../utils/types';

export type JobStatus =
	| ICommonStatuses.pending
	| ICommonStatuses.inProgress
	| ICommonStatuses.completed
	| ICommonStatuses.failed;

export interface ICompaniesSlice {
	loading?: boolean;
	updateLoading?: boolean;
	error?: string | null;
}

export interface ICreateCompany {
	name: string;
	contactEmail: string;
	description?: string;
	industry?: string;
	lifetime?: string;
	defaultStatementOnboardingFlow?: boolean;
	defaultStatementOnboardingFrameworkId?: string;
	adminFirstName: string;
	adminLastName: string;
	adminEmail: string;
	adminTitle?: string;
}

export interface ICompanyLocation {
	name: string;
	timeZone: string;
}

export interface ICompanyBasic {
	id?: string;
	name?: string;
	contactEmail?: string;
	description?: string;
	location?: ICompanyLocation;
	industry?: string;
	lifetime?: string;
	status?: string;
	createdAt?: string;
	expirationDate?: string;
	mainAdminId?: string;
}

export interface ICompany extends Omit<ICompanyBasic, 'id'> {
	id: string;
	locationName?: string;
	locationTimeZone?: string;
	primaryFrameworkId?: number | null;
	onboardingStatus?: JobStatus;
	defaultStatementOnboardingFrameworkId?: number | null;
}

export interface ICompanies extends ICompaniesSlice {
	items: ICompany[] | null;
}

const initialState: ICompanies = {
	loading: false,
	updateLoading: false,
	items: null,
	error: null,
};

export const companiesSlice = createSlice({
	name: 'companies',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		updateLoading: (state) => {
			state.updateLoading = true;
		},
		updateLoaded: (state) => {
			state.updateLoading = false;
		},
		setCompaniesError: (state) => {
			state.error = 'Error while getting companies';
		},
		setCompanies: (state, { payload }: PayloadAction<ICompany[]>) => {
			state.items = payload;
		},
	},
});

export const getCompanies = () => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const companies = await companiesApi.getCompanies();

		const transformedData = companies.map((company: ICompanyBasic) => {
			return {
				...company,
				id: company.id?.toString(),
				createdAt: dateInMDYFormat(company.createdAt),
				expirationDate: dateInMDYFormat(company.expirationDate),
			};
		});

		await dispatch(setCompanies(sortByNameAsc(transformedData) || []));
	} catch (error: any) {
		dispatch(setCompaniesError());
		dispatch(setCompanies([]));
	} finally {
		dispatch(loaded());
	}
};

export const getCompanyById = (companyId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const company = await companiesApi.getCompanyById(companyId);

		return {
			...company,
			id: company.id?.toString(),
			locationName: company.location?.name,
			locationTimeZone: company.location?.timeZone,
			mainAdminId: company.mainAdminId?.toString(),
		} as ICompany;
	} catch (error: any) {
		dispatch(setCompaniesError());
		dispatch(setCompanies([]));
	} finally {
		dispatch(loaded());
	}
};

export const addNewCompany =
	(data: ICreateCompany & { location: ICompanyLocation }) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			const companyToCreate = {
				name: data.name,
				contactEmail: data.contactEmail,
				description: data.description,
				industry: data.industry,
				lifetime: data.lifetime,
				location: data.location,
				defaultStatementOnboardingFrameworkId: data.defaultStatementOnboardingFrameworkId
					? parseInt(data.defaultStatementOnboardingFrameworkId)
					: undefined,
				mainAdmin: {
					firstName: data.adminFirstName,
					lastName: data.adminLastName,
					email: data.adminEmail,
					title: data.adminTitle,
					role: 'client-admin',
				},
			};

			const addedCompany = await companiesApi.addNewCompany(companyToCreate);

			await dispatch(getCompanies());
			await dispatch(getAdmins());

			return addedCompany.companyId.toString();
		} catch (error: any) {
			if (error && error.message && error.message.includes('already exists'))
				throw new CommonResponseError('Such user already exists.');

			throw new CommonResponseError('Error while adding company.');
		} finally {
			dispatch(loaded());
		}
	};

export const deleteCompany = (companyId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		await companiesApi.deleteCompany(companyId);

		await dispatch(getCompanies());
	} catch (error: any) {
		throw new CommonResponseError('Error while deleting company.');
	} finally {
		dispatch(loaded());
	}
};

export const updateCompany =
	(companyId: string, data: Partial<ICompany>) => async (dispatch: Dispatch<any>) => {
		dispatch(updateLoading());

		try {
			await companiesApi.updateCompany(companyId, {
				...data,
				//@ts-ignore
				mainAdminId: Number(data.mainAdminId),
			});

			await dispatch(getCompanies());
		} catch (error: any) {
			throw new CommonResponseError('Error while updating company.');
		} finally {
			dispatch(updateLoaded());
		}
	};

export const resetCompany = (companyId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		return await companiesApi.resetCompany(companyId);
	} catch (error: any) {
		throw new CommonResponseError('Error while reseting company');
	} finally {
		dispatch(loaded());
	}
};

export const bulkUpdateCompaniesStatus =
	(companyIds: string[], status: string) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			await companiesApi.bulkStatusUpdate(
				companyIds.map((id) => Number(id)),
				status,
			);
			await dispatch(getCompanies());
		} catch (error: any) {
			throw new CommonResponseError('Error while updating companies status');
		} finally {
			dispatch(loaded());
		}
	};

export const { loading, loaded, updateLoading, updateLoaded, setCompanies, setCompaniesError } =
	companiesSlice.actions;
export default companiesSlice.reducer;
