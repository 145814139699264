import { useSelector } from 'react-redux';
import { RootState } from '../../../services/store';

export default function usePolicies() {
	const { gsItems, policyLoading, updateLoading, loading } = useSelector(
		(store: RootState) => store.policies,
	);

	return {
		gsItems,
		policyLoading,
		updateLoading,
		loading,
	};
}
