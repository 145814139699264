import React, { FC, forwardRef, useEffect, useState } from 'react';
import ICheckbox from './ICheckbox';
import styles from './Checkbox.module.scss';
import classNames from 'classnames';

const Checkbox: FC<ICheckbox> = forwardRef<HTMLInputElement, ICheckbox>(
	({ id, name, text, checked, className, disabled, onChange }, ref) => {
		const [checkedState, setCheckedState] = useState(!!checked);

		useEffect(() => {
			setCheckedState(!!checked);
		}, [checked]);

		return (
			<div
				className={classNames(className, styles.checkbox, disabled ? styles.disabled : '')}
			>
				<input
					disabled={disabled}
					type="checkbox"
					{...{ id, name }}
					ref={ref}
					checked={checkedState}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setCheckedState((prevState) => !prevState);

						if (onChange) {
							onChange(event);
						}
					}}
				/>
				<label htmlFor={id}></label>

				{text ? <span className={styles.text}>{text}</span> : null}
			</div>
		);
	},
);

export default Checkbox;
