import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';
import IButton from './IButton';

const Button: FC<IButton> = ({
	children,
	className,
	negative,
	btnRef,
	width,
	small,
	errorStyled,
	...rest
}) => {
	return (
		<button
			className={classNames(
				styles.button,
				className,
				negative ? styles.negative : '',
				small ? styles.small : '',
				errorStyled ? styles.error : '',
			)}
			style={{ width }}
			ref={btnRef}
			{...rest}
		>
			<span className={styles.text}>{children}</span>
		</button>
	);
};

export default Button;
