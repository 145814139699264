import React, { FC } from 'react';
import ILoadingSkeleton from './ILoadingSkeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';
import { CommonColors } from '../../../utils/helpers/constants';

const LoadingSkeleton: FC<ILoadingSkeleton> = ({
	className,
	height,
	width,
	containerClassName,
}) => {
	return (
		<Skeleton
			baseColor={CommonColors.skeletonBaseColor}
			className={className}
			{...{ height, width, containerClassName }}
		/>
	);
};

export default LoadingSkeleton;
