import { FC, useState } from 'react';
import styles from './ModalDeleteAdmin.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import ActionsBlock from '../../../primitives/actions-block/ActionsBlock';
import Button from '../../../primitives/button/Button';
import IModalDeleteAdmin from './IModalDeleteAdmin';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import { deleteAdmin } from '../../../../services/store/slices/admins.slice';
import Loader from '../../../primitives/loader/Loader';

const ModalDeleteAdmin: FC<IModalDeleteAdmin> = ({
	open,
	setOpen,
	id,
	name,
	onDeleteError,
	onDeleteSuccess,
	onCancel,
}) => {
	const dispatch = useAppDispatch();
	const [deleteLoading, setDeleteLoading] = useState(false);

	const onDelete = () => {
		setDeleteLoading(true);

		handleWithTryCatch(
			async () => {
				if (id) {
					await dispatch(deleteAdmin(id));
					onDeleteSuccess();
					setOpen(false);
					setDeleteLoading(false);
				}
			},
			undefined,
			(message) => {
				onDeleteError(!!message?.includes('at least one admin'));
				setDeleteLoading(false);
			},
		);
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Delete Admin?</h2>

				<p className={styles.text}>
					Are you sure you want to delete <strong>{name}</strong> from admins?
				</p>

				<ActionsBlock>
					<Button type="button" width={160} negative onClick={onCancel}>
						Cancel
					</Button>

					<Button errorStyled type="button" width={160} onClick={onDelete}>
						{deleteLoading ? (
							<Loader
								thin
								maxHeight={14}
								maxWidth={14}
								color={'#ffffff'}
								transparentColor="transparent"
							/>
						) : (
							'Delete'
						)}
					</Button>
				</ActionsBlock>
			</BasicPanel>
		</Modal>
	);
};

export default ModalDeleteAdmin;
