import axios from 'axios';
import { ADMIN } from './api.path';
import { IUser } from '../store/slices/user.slice';

const userApi = {
	getUserInfo: (): Promise<IUser> => {
		return axios.get(`${ADMIN}/current-user`);
	},
	updateUserInfo: (data: Partial<IUser>): Promise<IUser> => {
		return axios.put(`${ADMIN}`, data);
	},
};

export default userApi;
