import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './EditCompany.module.scss';
import Input from '../../../primitives/form/input/Input';
import Button from '../../../primitives/button/Button';
import ActionsBlock from '../../../primitives/actions-block/ActionsBlock';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import {
	requireValidationPattern,
	nameValidationPattern,
	locationStringIsValid,
	emailValidationPattern,
} from '../../../../utils/helpers/common/form';
import Drawer from '../../../primitives/drawer/Drawer';
import ModalLeaveWarning from '../../modals/modal-leave-warning/ModalLeaveWarning';
import useLeaveWarning from '../../../../utils/helpers/hooks/useLeaveWarning';
import { getAdminOptions, sanitizeData } from '../../../../utils/helpers/common';
import SectionTitle from '../../../primitives/section-title/SectionTitle';
import CollapsiblePanel from '../../../primitives/collapsible-panel/CollapsiblePanel';
import Scrollbar from '../../../primitives/scrollbar/Scrollbar';
import IEditCompany from './IEditCompany';
import {
	ICompany,
	getCompanyById,
	updateCompany,
} from '../../../../services/store/slices/companies.slice';
import Select from '../../../primitives/form/select/Select';
import {
	CompanyStatusSelectOptions,
	FrameworkTypes,
	IndustriesSelectOptions,
	LifeTimeSelectOptions,
} from '../../../../utils/helpers/constants';
import useFormItemValues from '../../../../utils/helpers/hooks/useFormItemValues';
import LocationSearch from '../../location-search/LocationSearch';
import useAdmins from '../../../../utils/helpers/hooks/useAdmins';
import ModalDeleteCompany from '../../modals/modal-delete-company/ModalDeleteCompany';
import ModalResetCompany from '../../modals/modal-reset-company/ModalResetCompany';
import { CommonResultStatusType, ICommonStatuses } from '../../../../utils/types';
import Alert from '../../../primitives/alert/Alert';
import Error from '../../../primitives/error/Error';
import EditCompanySkeleton from './EditCompanySkeleton';
import useCompanies from '../../../../utils/helpers/hooks/useCompanies';
import Loader from '../../../primitives/loader/Loader';
import useFrameworks from '../../../../utils/helpers/hooks/useFrameworks';
import { Pill } from '../../../primitives';

const EditCompany: FC<IEditCompany> = ({
	companyId,
	closeHandler,
	onUpdateError,
	onUpdateSuccess,
	onDeleteSuccess,
	onDeleteError,
	open,
}) => {
	const dispatch = useAppDispatch();
	const { items: admins } = useAdmins();
	const { updateLoading } = useCompanies();

	const [currentCompany, setCurrentCompany] = useState<ICompany | null>(null);

	const {
		id,
		name,
		description,
		industry,
		contactEmail,
		mainAdminId,
		lifetime,
		status,
		locationName,
		locationTimeZone: currentTimeZone,
		primaryFrameworkId,
		onboardingStatus,
		defaultStatementOnboardingFrameworkId,
	} = currentCompany || {};

	const [locationValue, setLocationValue] = useState<string | null>(locationName || '');
	const [locationTimeZone, setLocationTimeZone] = useState('');

	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [showBrowserLeaveWarning, setBrowserShowLeaveWarning] = useState(false);
	const [currentAdminId, setCurrentAdminId] = useState(mainAdminId);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [resetModalOpen, setResetModalOpen] = useState(false);
	const [resetResult, setResetResult] = useState<CommonResultStatusType>('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const { items: frameworkItems } = useFrameworks();
	const defaultFrameworksOptions = useMemo(() => {
		return (
			frameworkItems
				?.filter((framework) =>
					[FrameworkTypes.iso, FrameworkTypes.soc].includes(framework.type),
				)
				.map((item) => ({
					value: item.id,
					label: item.name,
				})) || []
		);
	}, [frameworkItems]);

	const {
		register,
		handleSubmit,
		getValues,
		reset,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			name,
			description: description || '',
			locationName,
			industry,
			contactEmail,
			lifetime,
			status,
			mainAdminId,
			primaryFrameworkId,
		},
	});

	useFormItemValues(setValue, { industry, lifetime, status, mainAdminId, locationName });

	const onFormSubmitHandler = async (data: Partial<ICompany>) => {
		if (Object.keys(errors).length) return;

		const newData = sanitizeData(data);
		delete newData.locationName;

		if (id)
			await handleWithTryCatch(
				async () => {
					await dispatch(
						updateCompany(id!.toString(), {
							...newData,
							mainAdminId: currentAdminId,
							location: {
								name: locationValue,
								timeZone: locationTimeZone || currentTimeZone,
							},
						}),
					);
					onUpdateSuccess();
				},
				undefined,
				onUpdateError,
			);
	};

	const formActions = (
		<ActionsBlock className={styles.actions}>
			<div className={styles['actions-group']}>
				<Button
					className={styles.action}
					type="button"
					width={136}
					onClick={() => setDeleteModalOpen(true)}
					negative
				>
					Delete
				</Button>

				<Button
					className={styles.action}
					type="button"
					width={136}
					onClick={() => setResetModalOpen(true)}
					negative
				>
					Reset Account
				</Button>
			</div>

			<Button
				type="submit"
				width={136}
				disabled={
					updateLoading ||
					!!Object.keys(errors).length ||
					!!(!locationValue || (locationValue && !locationStringIsValid(locationValue)))
				}
			>
				{updateLoading ? (
					<Loader
						transparentColor="transparent"
						color="#ffffff"
						thin
						maxHeight={14}
						maxWidth={14}
					/>
				) : (
					'Save'
				)}
			</Button>
		</ActionsBlock>
	);

	const renderDetails = () => {
		const trigger = <SectionTitle className={styles.details}>Details</SectionTitle>;

		return (
			<CollapsiblePanel trigger={trigger}>
				<div className={styles['input-group']}>
					<Input
						{...register('name', {
							...requireValidationPattern(),
							...nameValidationPattern(),
						})}
						defaultValue={name}
						className={styles.input}
						withErrorStyle={!!errors.name}
						type="text"
						label="Name"
						placeholder="Enter name"
					/>

					<Input
						{...register('contactEmail', {
							...emailValidationPattern(),
						})}
						withErrorStyle={!!errors.contactEmail}
						className={styles.input}
						defaultValue={contactEmail}
						type="text"
						label="Contact Email (optional)"
						placeholder="Enter contact email"
					/>
				</div>

				<Input
					{...register('description', { ...nameValidationPattern() })}
					defaultValue={description}
					className={styles.input}
					withErrorStyle={!!errors.description}
					type="text"
					label="Description (Optional)"
					placeholder="Enter description"
				/>

				<div className={styles['input-group']}>
					<LocationSearch
						{...register('locationName')}
						type="text"
						defaultValue={locationName}
						withErrorStyle={
							!!(
								!locationValue ||
								(locationValue && !locationStringIsValid(locationValue))
							)
						}
						className={styles.input}
						onValueChange={(data) => {
							setLocationValue(data.value || locationName || '');
							setLocationTimeZone(data.timeZone || locationTimeZone || '');
							setValue('locationName', data.value);
						}}
					/>

					<Select
						id="industry"
						{...register('industry')}
						className={styles.input}
						defaultValue={industry}
						options={IndustriesSelectOptions}
						label="Industry"
						onValueChange={(value) => setValue('industry', value)}
						placeholder="Select industry"
					/>
				</div>

				<div className={styles['input-group']}>
					<Select
						id="lifetime"
						{...register('lifetime')}
						className={styles.input}
						defaultValue={lifetime}
						options={LifeTimeSelectOptions}
						label="Account Lifetime"
						onValueChange={(value) => setValue('lifetime', value)}
						placeholder="Select account lifetime"
					/>

					<Select
						id="status"
						{...register('status')}
						className={styles.input}
						defaultValue={status}
						options={CompanyStatusSelectOptions}
						label="Status"
						onValueChange={(value) => setValue('status', value)}
						placeholder="Select status"
					/>
				</div>
			</CollapsiblePanel>
		);
	};

	const renderAdminInfo = () => {
		const trigger = <SectionTitle className={styles.details}>Main Company Admin</SectionTitle>;
		const currentAdmin = admins?.find((admin) => admin.id === currentAdminId);

		return (
			<CollapsiblePanel trigger={trigger}>
				<Select
					id="mainAdminId"
					{...register('mainAdminId')}
					className={styles.input}
					defaultValue={mainAdminId}
					options={getAdminOptions(admins, id)}
					label="Company Admin"
					onValueChange={(value) => {
						setValue('mainAdminId', value);
						setCurrentAdminId(value);
					}}
					placeholder="Select company admin"
				/>

				<div className={styles['input-group']}>
					<Input
						disabled
						className={styles.input}
						value={currentAdmin?.email}
						type="text"
						label="Email"
					/>

					<Input
						disabled
						className={styles.input}
						value={currentAdmin?.title}
						type="text"
						label="Title"
					/>
				</div>
			</CollapsiblePanel>
		);
	};

	const renderOnboarding = () => {
		const trigger = <SectionTitle className={styles.details}>Onboarding</SectionTitle>;

		return (
			<CollapsiblePanel trigger={trigger}>
				<Select
					id="primaryFrameworkId"
					{...register('primaryFrameworkId')}
					className={styles.input}
					options={defaultFrameworksOptions}
					label="Primary Framework"
					onValueChange={(value) => {
						setValue('primaryFrameworkId', parseInt(value));
					}}
					placeholder="Select primary framework"
					disabled
				/>
				<div className={styles['input-group']}>
					<div className={styles['input-item']}>
						<label>Onboarding status</label>
						{onboardingStatus && (
							<Pill className={styles.pill} status={onboardingStatus} />
						)}
					</div>

					<div className={styles['input-item']}>
						<label>Default Statement Onboarding Flow</label>
						<Pill
							status={
								defaultStatementOnboardingFrameworkId
									? ICommonStatuses.approved
									: ICommonStatuses.failed
							}
						>
							{defaultStatementOnboardingFrameworkId ? 'YES' : 'NO'}
						</Pill>
					</div>
				</div>
			</CollapsiblePanel>
		);
	};

	const editCompany = (
		<div className={styles['edit-company']}>
			<form onSubmit={handleSubmit(onFormSubmitHandler)}>
				<Scrollbar className={styles.scrollbar}>
					<div className={styles.content}>
						{renderDetails()}

						{renderAdminInfo()}

						{renderOnboarding()}
					</div>
				</Scrollbar>

				{formActions}
			</form>
		</div>
	);

	const changesWereMade = useCallback(() => {
		if (!id) return false;

		const currentValues = getValues();

		const result = !!(
			currentValues.name !== name ||
			currentValues.description !== description ||
			currentValues.locationName !== locationName ||
			currentValues.industry !== industry ||
			currentValues.lifetime !== lifetime ||
			(currentAdminId && currentValues.mainAdminId !== currentAdminId) ||
			currentValues.contactEmail !== contactEmail
		);

		return result;
	}, [
		id,
		getValues,
		name,
		description,
		locationName,
		industry,
		lifetime,
		currentAdminId,
		contactEmail,
	]);

	const modals = (
		<>
			<ModalLeaveWarning
				open={warningModalOpen}
				setOpen={setWarningModalOpen}
				onConfirm={() => {
					setBrowserShowLeaveWarning(false);
					closeHandler();
				}}
			/>

			<ModalDeleteCompany
				open={deleteModalOpen}
				setOpen={setDeleteModalOpen}
				id={currentCompany?.id || ''}
				name={currentCompany?.name}
				onDeleteSuccess={onDeleteSuccess}
				onDeleteError={onDeleteError}
				onCancel={() => setDeleteModalOpen(false)}
			/>

			<ModalResetCompany
				open={resetModalOpen}
				setOpen={setResetModalOpen}
				id={currentCompany?.id || ''}
				name={currentCompany?.name}
				onResetSuccess={() => setResetResult('success')}
				onResetError={() => setResetResult('error')}
				onCancel={() => setResetModalOpen(false)}
			/>
		</>
	);

	const alerts = (
		<>
			<Alert
				uniqueKey={'reset-success'}
				show={resetResult === 'success'}
				type="success"
				message="Company reseted!"
				clearActionStatus={() => setResetResult('')}
			/>
			<Alert
				uniqueKey={'reset-error'}
				show={resetResult === 'error'}
				type="error"
				message="Error occurred while reseting company. Please try again."
				clearActionStatus={() => setResetResult('')}
			/>
		</>
	);

	useLeaveWarning(showBrowserLeaveWarning);

	useEffect(() => setCurrentAdminId(mainAdminId || ''), [mainAdminId]);

	useEffect(() => {
		if (changesWereMade()) setBrowserShowLeaveWarning(true);
	}, [changesWereMade]);

	useEffect(() => {
		if (!open) {
			reset({}, { keepValues: false });
			setBrowserShowLeaveWarning(false);
		}
	}, [open, reset]);

	useEffect(() => {
		if (locationName) setLocationValue(locationName || '');
	}, [locationName]);

	useEffect(() => {
		if (!currentCompany && companyId) setLoading(true);
	}, [currentCompany, companyId]);

	useEffect(() => {
		if (companyId && !currentCompany && !loading && !error) {
			handleWithTryCatch(async () => {
				const company = await dispatch(getCompanyById(companyId));
				if (company) setCurrentCompany(company);
				setLoading(false);
			});
		}
	}, [companyId, currentCompany, dispatch, error, loading]);

	useEffect(() => {
		if (error) setLoading(false);
	}, [error]);

	useEffect(() => {
		if (!open) {
			setCurrentCompany(null);
			setError('');
		}
	}, [open]);

	return (
		<Drawer
			contentClassName={styles.drawer}
			open={open}
			title="Company Details"
			onCloseClickHandler={() => {
				if (changesWereMade()) setWarningModalOpen(true);
				else closeHandler();
			}}
		>
			{error ? <Error message={error} /> : null}

			{loading ? <EditCompanySkeleton /> : null}

			{!loading && id ? editCompany : null}

			{modals}

			{alerts}
		</Drawer>
	);
};

export default EditCompany;
