import React, { useEffect, useState } from 'react';
import Tabs from '../../components/primitives/tabs/Tabs';
import NistCrosswalkTable from '../../components/partials/tables/nist-crosswalk/NistCrosswalkTable';
import useGetCrosswalk from '../../utils/helpers/hooks/useGetCrosswalk';
import useCrosswalk from '../../utils/helpers/hooks/useCrosswalk';
import SupplementaryCrosswalkTable from '../../components/partials/tables/iso-crosswalk/SupplementaryCrosswalkTable';
import { SupplementaryFrameworkTypes } from '../../services/store/slices/crosswalk.slice';

const FrameworkCrosswalk = () => {
	const { nistItems, isoItems, socItems } = useCrosswalk();

	const [dataLoading, setDataLoading] = useState(true);

	useGetCrosswalk();

	useEffect(() => {
		if (nistItems && isoItems && socItems) setDataLoading(false);
	}, [isoItems, nistItems, socItems]);


	return (
		<>
			<h2>Framework Crosswalk</h2>

			<Tabs
				tabs={[
					{
						key: 'nist',
						text: 'NIST 800-53',
						component: (
							<NistCrosswalkTable data={nistItems || []} dataLoading={dataLoading} />
						),
					},
					{
						key: 'iso',
						text: 'ISO 27001:2022',
						component: (
							<SupplementaryCrosswalkTable
								type={SupplementaryFrameworkTypes.ISO}
								data={isoItems || []}
								dataLoading={dataLoading}
							/>
						),
					},
					{
						key: 'soc',
						text: 'SOC 2',
						component: (
							<SupplementaryCrosswalkTable
								type={SupplementaryFrameworkTypes.SOC}
								data={socItems || []}
								dataLoading={dataLoading}
							/>
						),
					},
				]}
			/>
		</>
	);
};

export default FrameworkCrosswalk;
