export type CommonResultStatusType = 'success' | 'error' | '';

export interface IFormItemProps {
	error?: string;
	className?: string;
	disabled?: boolean;
	withErrorStyle?: boolean;
}

export interface IFilters {
	search?: string;
	filters?: IKeyValuePair;
}

export interface IKeyValuePair {
	[key: string]: any;
}

export interface ISortProps {
	property?: string;
	direction?: 'asc' | 'desc' | '';
}

export interface ITableDataProcessingParameters {
	sort?: ISortProps;
	filters?: IKeyValuePair;
}

export interface ISelectOption {
	label: string;
	value: string;
	metaData?: IKeyValuePair;
}

export interface ISelectGroupedOptions {
	groupName: string;
	options: ISelectOption[];
}

export interface IAWSPasswordRequirements {
	MinimumLength: number;
	RequireLowercase: boolean;
	RequireNumbers: boolean;
	RequireSymbols: boolean;
	RequireUppercase: boolean;
	TemporaryPasswordValidityDays: number;
}

export interface IPasswordRequirements {
	minimumLength: number;
	requireLowercase: boolean;
	requireUppercase: boolean;
	requireNumbers: boolean;
	requireSymbols: boolean;
}

export interface IComment {
	id?: string;
	author?: string;
	date?: string;
	text?: string;
}

export interface IVariable {
	id: string;
	type: 'text' | 'time' | 'numeric';
	prefix?: string;
	sufix?: string;
	value?: string;
	unit?: string;
}

export interface IChangeHistoryEntry {
	id: string;
	author: string;
	text: string;
	date: string;
}

export interface IFileUploadResult {
	name: string;
	progress: number;
	isSuccess: boolean;
}

export interface IIdName {
	id: string;
	displayId?: string;
	name: string;
}

export interface IFilePreassignedData {
	file: any;
	signedUrl: string;
}

export enum ICommonStatuses {
	draft = 'draft',
	approved = 'approved',
	pending = 'pending',
	inProgress = 'in-progress',
	archived = 'archived',
	reviewed = 'reviewed',
	active = 'active',
	suspended = 'suspended',
	published = 'published',
	unpublished = 'unpublished',
	completed = 'completed',
	failed = 'failed',
};
