import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './Alert.module.scss';
import classNames from 'classnames';
import IAlert from './IAlert';
import { createPortal } from 'react-dom';

const Alert: FC<IAlert> = ({
	className,
	message,
	type,
	show,
	uniqueKey,
	clearActionStatus,
	hideAfterDelay = true,
}) => {
	const portalsElement = useRef(document.getElementById('portals'));
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		if (visible && hideAfterDelay) {
			const delay = setTimeout(() => {
				setVisible(false);
				if (clearActionStatus) clearActionStatus();
				clearTimeout(delay);
			}, 2000);
		}
	}, [clearActionStatus, visible, hideAfterDelay]);

	useEffect(() => {
		setVisible(show);
	}, [show]);

	return (
		<>
			{portalsElement.current
				? createPortal(
						<div
							className={classNames(
								styles.alert,
								className,
								type ? styles[type] : '',
								visible ? styles.visible : '',
							)}
						>
							{message}
						</div>,
						portalsElement.current,
						uniqueKey,
					)
				: null}
		</>
	);
};

export default Alert;
