import { useEffect } from 'react';
import useUser from './useUser';
import { useAppDispatch } from '../../../services/store';
import { handleWithTryCatch } from '../errors';
import { getNistCrosswalk, getSupplementaryCrosswalk, SupplementaryFrameworkTypes } from '../../../services/store/slices/crosswalk.slice';
import useBaseControls from './useBaseControls';
import { getNistBaseControls } from '../../../services/store/slices/base-controls.slice';

export default function useGetCrosswalk(skip: boolean = false) {
	const dispatch = useAppDispatch();
	const { authorized } = useUser();
	const { baseControls, nistControls } = useBaseControls();

	useEffect(() => {
		if (skip) return;

		if (authorized && baseControls) {
			handleWithTryCatch(async () => {
				if (!nistControls) await dispatch(getNistBaseControls());

				await dispatch(getNistCrosswalk());
				await dispatch(getSupplementaryCrosswalk(SupplementaryFrameworkTypes.ISO));
				await dispatch(getSupplementaryCrosswalk(SupplementaryFrameworkTypes.SOC));
			});
		}
	}, [authorized, dispatch, skip, baseControls, nistControls]);
}
