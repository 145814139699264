import React, { FC, useEffect, useState } from 'react';
import styles from './ChangeStatusBlock.module.scss';
import classNames from 'classnames';
import Select from '../form/select/Select';
import Loader from '../loader/Loader';
import IChangeStatusBlock from './IChangeStatusBlock';
import { CompanyStatusSelectOptions } from '../../../utils/helpers/constants';

const ChangeStatusBlock: FC<IChangeStatusBlock> = ({ className, onChange, show, loading }) => {
	const [selectedStatus, setSelectedStatus] = useState('');

	useEffect(() => {
		if (!show) setSelectedStatus('');
	}, [show]);

	return (
		<div
			className={classNames(
				styles['change-status-block'],
				className,
				show ? styles.show : '',
			)}
		>
			Change selected to
			<Select
				id="status"
				options={CompanyStatusSelectOptions || []}
				value={selectedStatus}
				onValueChange={(status: string) => {
					onChange(status);
					setSelectedStatus(status);
				}}
				className={styles.select}
				placeholder={'Select status'}
				smallCarret
			/>
			{loading ? (
				<Loader thin maxHeight={14} maxWidth={14} className={styles.loader} />
			) : null}
		</div>
	);
};

export default ChangeStatusBlock;
