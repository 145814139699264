import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppQueryParams } from '../../utils/helpers/constants';
import usePolicies from '../../utils/helpers/hooks/usePolicies';
import useGetPolicies from '../../utils/helpers/hooks/useGetPolicies';
import GSPoliciesTable from '../../components/partials/tables/gs-policies/GSPoliciesTable';

const GSPolicies: FC = () => {
	const location = useLocation();
	const { gsItems: gsPolicyItems } = usePolicies();

	const [gsPoliciesLoading, setGSPoliciesLoading] = useState(true);

	const navigateToItem = useMemo(() => {
		if (!gsPoliciesLoading && gsPolicyItems) {
			const urlParams = new URLSearchParams(location.search);
			const policyIdFromUrl = urlParams.get(AppQueryParams.item) || '';

			if (policyIdFromUrl) {
				const policy = gsPolicyItems?.find((item) => item.id === policyIdFromUrl);

				if (policy) return policyIdFromUrl;
			}
		}
	}, [gsPoliciesLoading, gsPolicyItems, location.search]);

	useGetPolicies();

	useEffect(() => {
		if (gsPolicyItems) setGSPoliciesLoading(false);
	}, [gsPolicyItems]);

	return (
		<>
			<h2>GS Policy Library</h2>

			<GSPoliciesTable
				data={gsPolicyItems || []}
				dataLoading={gsPoliciesLoading}
				showItemId={navigateToItem}
			/>
		</>
	);
};

export default GSPolicies;
