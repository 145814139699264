import axios from 'axios';
import { FRAMEWORKS } from './api.path';
import { IFrameworkBasic } from '../store/slices/frameworks.slice';

const frameworksApi = {
	getFrameworks: (): Promise<IFrameworkBasic[]> => {
		return axios.get(`${FRAMEWORKS}`);
	},
};

export default frameworksApi;
