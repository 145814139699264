import React, { FC } from 'react';
import styles from './ActionProgress.module.scss';
import IActionProgress from './IActionProgress';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import classNames from 'classnames';

const ActionProgress: FC<IActionProgress> = ({ progress, className }) => {
	return (
		<>
			<div className={classNames(styles['progress-wrap'], className)}>
				<CircularProgressbar
					value={progress}
					strokeWidth={16}
					styles={buildStyles({
						pathColor: '#5BAF23',
						trailColor: '#F4F9EB',
					})}
				/>

				<div className={styles.percent}>{progress}%</div>
			</div>
		</>
	);
};

export default React.memo(ActionProgress);
