import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import BasicLayout from '../../../../components/layouts/basic-layout/BasicLayout';
import { AppUrls } from '../../constants/app-routes';
import { handleWithTryCatch } from '../../errors';
import { useAppDispatch } from '../../../../services/store';
import { getUserInfo } from '../../../../services/store/slices/user.slice';
import useAuthorization from '../../hooks/useAuthorization';

const UnprotectedRoute: FC<PropsWithChildren> = ({ children }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { authorized } = useUser();
	const { mfaCodeRequired } = useAuthorization();

	useEffect(() => {
		if (mfaCodeRequired && !authorized) {
			return navigate(AppUrls.verify, { replace: true });
		} else if (authorized) {
			return navigate(AppUrls.companies, { replace: true });
		} else {
			handleWithTryCatch(async () => {
				// check if user is authorized
				await dispatch(getUserInfo());
				return navigate(AppUrls.companies, { replace: true });
			});
		}
	}, [mfaCodeRequired, authorized, dispatch, navigate]);

	return <BasicLayout>{children}</BasicLayout>;
};

export default UnprotectedRoute;
