import { useEffect, useMemo, useState } from 'react';
import useCompanies from '../../utils/helpers/hooks/useCompanies';
import useGetCompanies from '../../utils/helpers/hooks/useGetCompanies';
import CompaniesTable from '../../components/partials/tables/companies/CompaniesTable';
import { useLocation } from 'react-router-dom';
import { AppQueryParams } from '../../utils/helpers/constants';
import useGetAdmins from '../../utils/helpers/hooks/useGetAdmins';

const Companies = () => {
	const { items: companyItems } = useCompanies();
	const location = useLocation();
	const [loading, setLoading] = useState(true);

	const navigateToItem = useMemo(() => {
		if (!loading && companyItems) {
			const urlParams = new URLSearchParams(location.search);
			const companyIdFromUrl = urlParams.get(AppQueryParams.item) || '';

			if (companyIdFromUrl) {
				const company = companyItems?.find((item) => item.id === companyIdFromUrl);

				if (company) return companyIdFromUrl;
			}
		}
	}, [loading, companyItems, location.search]);

	useGetCompanies();
	useGetAdmins();

	useEffect(() => {
		if (companyItems) setLoading(false);
	}, [companyItems]);

	return (
		<>
			<h2>Companies</h2>

			<CompaniesTable
				data={companyItems || []}
				dataLoading={loading}
				showItemId={navigateToItem}
			/>
		</>
	);
};

export default Companies;
