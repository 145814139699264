import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import { ICommonStatuses, IIdName } from '../../../utils/types';
import { RootState } from '..';
import {
	dateInMDYTimeFormat,
	sortBaseControlObjByDisplayIdAsc,
	sortByNameAsc,
} from '../../../utils/helpers/common';
import policiesApi from '../../api/policies.api';
import { IFramework } from './frameworks.slice';
import { IBaseControl } from './base-controls.slice';

export interface IGSPoliciesSlice {
	loading?: boolean;
	policyLoading?: boolean;
	updateLoading?: boolean;
}

export interface IGSPolicyBasic {
	id: string;
	name: string;
	description?: string;
	preamble?: string;
	version?: string;
	baseControls?: IBaseControl[];
	frameworkId?: number;
	status?: string;
	updatedAt?: string;
	isInitial?: boolean;
}

export interface IGSPolicy extends Omit<IGSPolicyBasic, 'framework' | 'baseControls'> {
	baseControls?: IIdName[];
	framework?: IFramework;
}

export interface IGSPolicies extends IGSPoliciesSlice {
	gsItems: IGSPolicy[] | null;
	baseControls: IIdName[] | null;
}

export interface ICreatePolicy {
	name: string;
	framework: string;
	controls?: string[];
	description?: string;
	preamble?: string;
	version: string;
	isInitial?: boolean;
	status?: string;
}

export interface IUpdatePolicy {
	id?: string;
	name?: string;
	status?: string;
	framework?: string;
	controls?: string[];
	description?: string;
	preamble?: string;
	isInitial?: boolean;
}

const initialState: IGSPolicies = {
	loading: false,
	policyLoading: false,
	updateLoading: false,
	gsItems: null,
	baseControls: null,
};

export const policiesSlice = createSlice({
	name: 'policies',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		policyLoading: (state) => {
			state.policyLoading = true;
		},
		policyLoaded: (state) => {
			state.policyLoading = false;
		},
		updateLoading: (state) => {
			state.updateLoading = true;
		},
		updateLoaded: (state) => {
			state.updateLoading = false;
		},
		setGSPolicies: (state, { payload }: PayloadAction<IGSPolicy[]>) => {
			state.gsItems = payload;
		},
	},
});

export const getGSPolicies = () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
	dispatch(loading());

	try {
		const gsPolicies = await policiesApi.getGSPolicies();
		const frameworks = getState().frameworks.items;

		const transformedData = gsPolicies.map((item) => {
			const frameworkObj = frameworks?.find(
				(framework) => item.frameworkId?.toString() === framework.id,
			);

			const transformedBaseControls = item.baseControls?.map((control: IBaseControl) => {
				return {
					id: control.id.toString(),
					displayId: control.controlId,
					name: control.enhancementName,
				};
			});

			return {
				id: item.id.toString(),
				name: item.name,
				description: item.description,
				preamble: item.preamble,
				status: item.status || ICommonStatuses.unpublished,
				framework: {
					id: frameworkObj?.id,
					name: frameworkObj?.name,
				} as IFramework,
				version: item.version,
				baseControls: sortBaseControlObjByDisplayIdAsc(transformedBaseControls || []),
				updatedAt: dateInMDYTimeFormat(item.updatedAt),
				isInitial: item.isInitial,
			};
		});

		const sortedPolicies = sortByNameAsc(transformedData);

		dispatch(setGSPolicies(sortedPolicies as IGSPolicy[]));
	} catch (error: any) {
		dispatch(setGSPolicies([]));
	} finally {
		dispatch(loaded());
	}
};

export const createPolicy = (data: ICreatePolicy) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const newPolicy = await policiesApi.createGSPolicy(data);

		await dispatch(getGSPolicies());

		return newPolicy.id.toString();
	} catch (error: any) {
		throw new CommonResponseError('Error while creating policy.');
	} finally {
		dispatch(loaded());
	}
};

export const updatePolicy =
	(policyId: string, data: IUpdatePolicy) => async (dispatch: Dispatch<any>) => {
		dispatch(updateLoading());

		try {
			await policiesApi.updateGSPolicy(policyId, data);

			await dispatch(getGSPolicies());
		} catch (error: any) {
			throw new CommonResponseError('Error while creating policy.');
		} finally {
			dispatch(updateLoaded());
		}
	};

export const deletePolicy = (policyId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		await policiesApi.deleteGSPolicy(policyId);

		await dispatch(getGSPolicies());
	} catch (error: any) {
		throw new CommonResponseError('Error while deleting policy.');
	} finally {
		dispatch(loaded());
	}
};

export const {
	loading,
	loaded,
	policyLoading,
	policyLoaded,
	updateLoading,
	updateLoaded,
	setGSPolicies,
} = policiesSlice.actions;
export default policiesSlice.reducer;
