import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IIdName } from '../../../utils/types';
import { sortBaseControlObjByDisplayIdAsc } from '../../../utils/helpers/common';
import baseControlsApi from '../../api/base-controls.api';
import { FrameworkTypes } from '../../../utils/helpers/constants';

export interface IBaseControlsSlice {
	loading?: boolean;
}

export interface IBaseControl {
	id: string;
	controlId: string;
	enhancementName: string;
}

export interface IBaseControls extends IBaseControlsSlice {
	baseControls: IIdName[] | null;
	nistControls: IIdName[] | null;
}

const initialState: IBaseControls = {
	loading: false,
	baseControls: null,
	nistControls: null,
};

export const baseControlsSlice = createSlice({
	name: 'baseControls',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		setBaseControls: (state, { payload }: PayloadAction<IIdName[]>) => {
			state.baseControls = payload;
		},
		setNistControls: (state, { payload }: PayloadAction<IIdName[]>) => {
			state.nistControls = payload;
		},
	},
});

export const getAllBaseControls = () => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const baseControls = await baseControlsApi.getBaseControls();

		const transformedControls = baseControls.map(
			(control: IBaseControl) => ({
				id: control.id.toString(),
				displayId: control.controlId,
				name: control.enhancementName,
			}),
			[],
		);

		await dispatch(
			setBaseControls(sortBaseControlObjByDisplayIdAsc(transformedControls || [])),
		);
	} catch (error: any) {
		dispatch(setBaseControls([]));
	} finally {
		dispatch(loaded());
	}
};

export const getBaseControlsByType =
	(frameworkType: string, frameworkSubType?: string) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			const baseControls = await baseControlsApi.getBaseControlsByType(
				frameworkType,
				frameworkSubType,
			);

			const transformedControls = baseControls.map(
				(control: IBaseControl) => ({
					id: control.id.toString(),
					displayId: control.controlId,
					name: control.enhancementName,
				}),
				[],
			);

			return sortBaseControlObjByDisplayIdAsc(transformedControls || []);
		} catch (error: any) {
			return [];
		} finally {
			dispatch(loaded());
		}
	};

export const getNistBaseControls =
	(frameworkSubType?: string) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			const nistControls = await baseControlsApi.getBaseControlsByType(
				FrameworkTypes.nist,
				frameworkSubType,
			);

			const transformedControls = nistControls.map(
				(control: IBaseControl) => ({
					id: control.id.toString(),
					displayId: control.controlId,
					name: control.enhancementName,
				}),
				[],
			);

			await dispatch(
				setNistControls(sortBaseControlObjByDisplayIdAsc(transformedControls || [])),
			);
		} catch (error: any) {
			return [];
		} finally {
			dispatch(loaded());
		}
	};

export const { loading, loaded, setBaseControls, setNistControls } = baseControlsSlice.actions;
export default baseControlsSlice.reducer;
