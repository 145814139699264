import { useEffect } from 'react';
import useUser from './useUser';
import { useAppDispatch } from '../../../services/store';
import { handleWithTryCatch } from '../errors';
import { getCompanies } from '../../../services/store/slices/companies.slice';

export default function useGetCompanies() {
	const dispatch = useAppDispatch();
	const { authorized, info } = useUser();

	useEffect(() => {
		if (authorized && info?.email) {
			handleWithTryCatch(() => dispatch(getCompanies()));
		}
	}, [authorized, dispatch, info]);
}
