import React, { FC, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import countriesAndTimezones from 'countries-and-timezones';
import cityTimezones from 'city-timezones';
import { ISelectOption } from '../../../utils/types';
import ILocationSearch from './ILocationSearch';
import Input from '../../primitives/form/input/Input';
import OptionsListComponentOverride from '../../primitives/form/select/options-list-component-override/OptionsListComponentOverride';
import styles from './LocationSearch.module.scss';
import classNames from 'classnames';
import useClickAwayListener from '../../../utils/helpers/hooks/useClickAwayListener';

const LocationSearch: FC<ILocationSearch> = ({ className, onValueChange, value, ...rest }) => {
	const [citiesData, setCitiesData] = useState<any[]>();
	const [currentValue, setCurrentValue] = useState(value as string);
	const [currentTimeZone, setCurrentTimeZone] = useState('');
	const [showOptions, setShowOptions] = useState(false);
	const locationWrapperRef = useRef(null);
	useClickAwayListener(locationWrapperRef, () => setShowOptions(false));

	const formatUTCOffset = (utcOffset: string) => {
		const sign = utcOffset[0];
		const numericPart = utcOffset.match(/[0-9]+/)?.[0];
		if (numericPart === '00') return sign + '0';
		return sign + (parseInt(numericPart ?? '') || '');
	};

	const processSearchInput = (cityInput: string) => {
		const cityLookup = cityTimezones.lookupViaCity(cityInput);

		if (cityLookup.length) {
			setCitiesData(() => {
				return cityLookup.map((city) => {
					const cityName = city.city_ascii;

					const timezoneInfo = countriesAndTimezones.getTimezone(city.timezone);

					if (timezoneInfo) {
						const cityUtcOffset = timezoneInfo.utcOffsetStr; //-05:00

						const time = new Date()
							.toLocaleString('en-US', { timeZone: city.timezone })
							.split(',')[1];

						return {
							name: cityName,
							country: city.iso3,
							utcOffset: formatUTCOffset(cityUtcOffset),
							time: time,
							timezone: timezoneInfo.name,
						};
					}

					return [];
				});
			});
		}
	};

	const dymamicOptions = useCallback(() => {
		if (!citiesData?.length) return [];

		function formatTime(inputTime: string) {
			const [time, period] = inputTime.trim().split(' ');
			const [hours, minutes] = time.split(':');

			return hours + ':' + minutes + ' ' + period.toLowerCase();
		}

		return citiesData?.map((city) => {
			const timeStr = formatTime(city.time);

			return {
				value: `${city.timezone}`,
				label: `${city.name}, ${city.country}, UTC${city.utcOffset} (${timeStr})`,
			} as ISelectOption;
		});
	}, [citiesData]);

	const onSearchChange = (e: FormEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const value = target.value;
		if (value === currentValue) return;
		if (!value) setCitiesData([]);

		setCurrentValue(value);
		processSearchInput(value);
	};

	const onInputClick = () => {
		if (!showOptions && currentValue) processSearchInput(currentValue);
	};

	useEffect(() => {
		if (dymamicOptions().length) setShowOptions(true);
		else setShowOptions(false);
	}, [dymamicOptions]);

	useEffect(() => {
		if (onValueChange) onValueChange({ value: currentValue, timeZone: currentTimeZone });
	}, [currentTimeZone, currentValue, onValueChange]);

	return (
		<div ref={locationWrapperRef} className={classNames(styles['location-search'], className)}>
			<Input
				{...rest}
				autoComplete="off"
				value={currentValue}
				label="Location"
				onChange={onSearchChange}
				onClick={onInputClick}
				placeholder="Search by a city name"
			/>

			{showOptions ? (
				<div className={styles['options-list']}>
					<OptionsListComponentOverride
						selectId="location"
						options={dymamicOptions()}
						onSelectChangeHandler={(option) => {
							setCurrentValue(option.label);
							setCurrentTimeZone(option.value);
							setShowOptions(false);
						}}
					/>
				</div>
			) : null}
		</div>
	);
};

export default LocationSearch;
