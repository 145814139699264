import React, { FC, useEffect, useState } from 'react';

import styles from './Tabs.module.scss';
import ITabs from './ITabs';
import Tab from '../tab/Tab';
import { useLocation, useNavigate } from 'react-router-dom';

const Tabs: FC<ITabs> = ({ tabs, ...rest }) => {
	const location = useLocation();
	const navigate = useNavigate();

	const [activeTab, setActiveTab] = useState<string>();
	const isActiveTab = (key: string): boolean => key === activeTab;

	const onTabClick = (tabKey: string) => navigate(`?tab=${tabKey}`);

	const tabsElement = tabs.map(({ text, key }) => (
		<Tab key={key} isActive={isActiveTab(key)} onClick={() => onTabClick(key)}>
			{text}
		</Tab>
	));

	const currentTab = tabs.find((tab) => tab.key === activeTab);
	const componentsElement = currentTab?.component;

	useEffect(() => {
		const tabKey = new URLSearchParams(location.search).get('tab');

		if (tabKey) setActiveTab(tabKey);
		else {
			navigate(`?tab=${tabs[0].key}`);
		}
	}, [location, navigate, tabs]);

	return (
		<div className={styles.tabs} {...rest}>
			<div className={styles['tabs-wrap']}>{tabsElement}</div>

			{componentsElement}
		</div>
	);
};

export default Tabs;
