import axios from 'axios';
import { CROSSWALK } from './api.path';
import {
	ISupplementaryCrosswalk,
	INistCrosswalkBasic,
	IUpdateIsoCrosswalk,
	IUpdateNistCrosswalk,
	SupplementaryFrameworkTypes,
} from '../store/slices/crosswalk.slice';

const crosswalkApi = {
	getFoundationalCrosswalkForNist: (): Promise<INistCrosswalkBasic[]> => {
		return axios.get(`${CROSSWALK}/nist`);
	},
	getSupplementaryCrosswalk: (type: SupplementaryFrameworkTypes): Promise<ISupplementaryCrosswalk[]> => {
		return axios.get(`${CROSSWALK}/${type}`);
	},
	updateNistCrosswalk: (data: IUpdateNistCrosswalk[]) => {
		return axios.post(`${CROSSWALK}/nist`, {
			controls: data,
		});
	},
	updateSupplementaryCrosswalk: (data: IUpdateIsoCrosswalk, type: SupplementaryFrameworkTypes) => {
		return axios.post(`${CROSSWALK}/${type}/${data.id}`, { controlIds: data.controls });
	},
};

export default crosswalkApi;
