import { FC, useEffect, useState } from 'react';
import styles from './ModalAddAdmin.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import ActionsBlock from '../../../primitives/actions-block/ActionsBlock';
import Button from '../../../primitives/button/Button';
import { useAppDispatch } from '../../../../services/store';
import { useForm } from 'react-hook-form';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import {
	requireValidationPattern,
	emailValidationPattern,
	nameValidationPattern,
} from '../../../../utils/helpers/common/form';
import Input from '../../../primitives/form/input/Input';
import Select from '../../../primitives/form/select/Select';
import { getCompanyOptions, sanitizeData } from '../../../../utils/helpers/common';
import Loader from '../../../primitives/loader/Loader';
import IModalAddAdmin from './IModalAddAdmin';
import useCompanies from '../../../../utils/helpers/hooks/useCompanies';
import useAdmins from '../../../../utils/helpers/hooks/useAdmins';
import { ICreateAdmin, addNewAdmin } from '../../../../services/store/slices/admins.slice';

const ModalAddAdmin: FC<IModalAddAdmin> = ({ open, setOpen, onAddSuccess, onAddError }) => {
	const dispatch = useAppDispatch();
	const { loading: adminsLoading } = useAdmins();
	const { items: companies } = useCompanies();

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
	});

	const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
	const [error, setError] = useState('');

	const onFormSubmitHandler = async (data: Partial<ICreateAdmin>) => {
		if (Object.keys(errors).length) return;

		const sanitizedData = sanitizeData(data);
		const trimmedEmail = sanitizedData.email?.trim();

		handleWithTryCatch(
			async () => {
				await dispatch(
					addNewAdmin({
						...sanitizedData,
						email: trimmedEmail,
						role: 'client-admin',
						companyId: Number(sanitizedData.company),
					}),
				);
				onAddSuccess(trimmedEmail);
				setOpen(false);
			},
			setError,
			onAddError,
		);
	};

	const formActions = (
		<ActionsBlock className={styles.actions}>
			<Button type="button" width={136} onClick={() => setOpen(false)} negative>
				Cancel
			</Button>

			<Button
				type="submit"
				width={136}
				disabled={submitIsDisabled || !!Object.keys(errors).length || adminsLoading}
			>
				{adminsLoading ? <Loader thin maxHeight={14} maxWidth={14} /> : 'Save'}
			</Button>
		</ActionsBlock>
	);

	const createForm = (
		<div className={styles['add-admin']}>
			<form onSubmit={handleSubmit(onFormSubmitHandler)}>
				<div className={styles.inputs}>
					<Input
						{...register('firstName', {
							...requireValidationPattern(),
							...nameValidationPattern(),
						})}
						className={styles.input}
						withErrorStyle={!!errors.firstName}
						error={errors?.firstName?.message as string}
						type="text"
						label="First Name"
						placeholder="Enter first name"
					/>

					<Input
						{...register('lastName', {
							...requireValidationPattern(),
							...nameValidationPattern(),
						})}
						className={styles.input}
						withErrorStyle={!!errors.lastName}
						error={errors?.lastName?.message as string}
						type="text"
						label="Last Name"
						placeholder="Enter last name"
					/>

					<Input
						{...register('email', {
							...requireValidationPattern(),
							...emailValidationPattern(),
						})}
						withErrorStyle={
							!!errors.email || error.includes('email') || error.includes('already exists')
						}
						error={
							error.includes('already exists') ? 'User with such email already exists' : ''
						}
						className={styles.input}
						type="text"
						label="Email"
						placeholder="Enter email"
					/>

					<Select
						id="company"
						{...register('company')}
						className={styles.input}
						options={getCompanyOptions(companies)}
						label="Company"
						onValueChange={(value) => {
							setValue('company', value);
						}}
						placeholder="Select company"
					/>

					<Input
						{...register('title', {
							...requireValidationPattern(),
							...nameValidationPattern(),
						})}
						className={styles.input}
						withErrorStyle={!!errors.title}
						error={errors?.title?.message as string}
						type="text"
						label="Title"
						placeholder="Enter title"
					/>
				</div>

				{formActions}
			</form>
		</div>
	);

	useEffect(() => {
		if (!open) {
			reset({}, { keepValues: false });
			setError('');
		}
	}, [open, reset]);

	useEffect(() => {
		setSubmitIsDisabled(!isValid);
	}, [isValid]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Add Admin</h2>

				{createForm}
			</BasicPanel>
		</Modal>
	);
};

export default ModalAddAdmin;
