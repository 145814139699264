import { FC, useEffect, useState } from 'react';
import IOptionsListComponentOverride from './IOptionsListComponentOverride';
import styles from './OptionsListComponentOverride.module.scss';
import Checkbox from '../../checkbox/Checkbox';
import ColoredTag from '../../../tag/ColoredTag';
import Scrollbar from '../../../scrollbar/Scrollbar';
import Input from '../../input/Input';
import { ISelectOption } from '../../../../../utils/types';

const OptionsListComponentOverride: FC<IOptionsListComponentOverride> = ({
	options,
	selectedOptions,
	onSelectChangeHandler,
	isTagStyledList = true,
	isTagsWithoutBg = false,
	withSearch,
}) => {
	const [currentOptions, setCurrentOptions] = useState<ISelectOption[]>([]);

	const onSearchValueChange = (value: string) => {
		const filteredOptions = options.filter((opt: ISelectOption) =>
			opt.label.toLowerCase().includes(value.toLowerCase()),
		);

		setCurrentOptions(filteredOptions);
	};

	useEffect(() => {
		if (options) setCurrentOptions([...options]);
	}, [options]);

	return (
		<Scrollbar small>
			<div className={styles['options-list']}>
				{withSearch ? (
					<Input
						type="search"
						placeholder={'Search...'}
						className={styles.search}
						onValueChange={onSearchValueChange}
						smallSearch
					/>
				) : null}

				{currentOptions.map((item: ISelectOption) => (
					<div key={item.value} className={styles['option-group']}>
						<Checkbox
							id={`${item.value}-${item.label}`}
							checked={!!selectedOptions.find((opt) => opt.value === item.value)}
							onChange={(e: any) => {
								const newValue = e.target.checked
									? [...selectedOptions, item]
									: selectedOptions.filter((opt) => opt.value !== item.value);
								onSelectChangeHandler(newValue);
							}}
						/>

						{isTagStyledList ? (
							<ColoredTag
								tagId={item.value}
								className={styles.tag}
								key={item.value}
								text={item.label}
								bgColor={item.metaData?.color}
								withoutBackground={isTagsWithoutBg}
							/>
						) : (
							<p className={styles.text}>{item.label}</p>
						)}
					</div>
				))}
			</div>
		</Scrollbar>
	);
};

export default OptionsListComponentOverride;
