import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ISortProps, CommonResultStatusType, IKeyValuePair } from '../../../../utils/types';
import Table from '../../../primitives/table/Table';
import { getCompanyOptions, processTableData } from '../../../../utils/helpers/common';
import TableFilters from '../../../primitives/table-filters/TableFilters';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import styles from './AdminsTable.module.scss';
import { getAdminColumns } from './utils';
import usePagination from '../../../../utils/helpers/hooks/usePagination';
import Alert from '../../../primitives/alert/Alert';
import useDevice from '../../../../utils/helpers/hooks/useDevice/useDevice';
import TableSkeleton from '../../../primitives/table/TableSkeleton';
import classNames from 'classnames';
import SearchFilter from '../../../primitives/filters/search-filter/SearchFilter';
import IPartialTable from '../common/IPartialTable';
import EditAdmin from '../../edit-panels/admin/EditAdmin';
import { IAdmin } from '../../../../services/store/slices/admins.slice';
import ActionButton from '../../../primitives/action-button/ActionButton';
import HoverTooltip from '../../../primitives/tooltip/HoverTooltip';
import ModalDeleteAdmin from '../../modals/modal-delete-admin/ModalDeleteAdmin';
import CompanyFilter from '../../../primitives/filters/company-filter/CompanyFilter';
import useCompanies from '../../../../utils/helpers/hooks/useCompanies';
import { AppUrls } from '../../../../utils/helpers/constants/app-routes';
import { useNavigate } from 'react-router-dom';
import { AppQueryParams } from '../../../../utils/helpers/constants';
import Button from '../../../primitives/button/Button';
import ModalAddAdmin from '../../modals/modal-add-admin/ModalAddAdmin';

const AdminsTable: FC<IPartialTable<IAdmin>> = ({ data, dataLoading, showItemId }) => {
	const device = useDevice();
	const navigate = useNavigate();
	const { items: companies } = useCompanies();

	const [processedData, setProcessedData] = useState(data);
	const [currentPageData, setCurrentPageData] = useState<IAdmin[]>([]);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [adminUpdateResult, setAdminUpdateResult] = useState<CommonResultStatusType>('');
	const [isProcessing, setIsProcessing] = useState(true);
	const [addNewResult, setAddNewResult] = useState<CommonResultStatusType>('');
	const [deleteResult, setDeleteResult] = useState<CommonResultStatusType | 'last-admin-error'>(
		'',
	);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [userToDelete, setUserToDelete] = useState('');
	const [updatedAdminId, setUpdatedAdminId] = useState<string | null>('');
	const [addNewModalOpen, setAddNewModalOpen] = useState(false);
	const [addedUser, setAddedUser] = useState('');

	const { paginationSection, setCurrentPage, pagesCount, goToPageByDataEntryProperty } =
		usePagination(processedData, setCurrentPageData);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IAdmin) => true,
	});

	const tableColumnsConfig = useMemo(() => getAdminColumns(device), [device]);

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onRowClickHandler = useCallback(
		(id: string) => navigate(`${AppUrls.admins}?${AppQueryParams.item}=${id}`),
		[navigate],
	);

	const onAdminUpdateSuccess = () => {
		setUpdatedAdminId(showItemId || '');
		setAdminUpdateResult('success');
		setOpenDrawer(false);
		navigate(`${AppUrls.admins}`);
	};
	const onAdminUpdateError = () => setAdminUpdateResult('error');

	const editDrawer = (
		<EditAdmin
			adminId={showItemId}
			onUpdateSuccess={onAdminUpdateSuccess}
			onUpdateError={onAdminUpdateError}
			onDeleteSuccess={() => setDeleteResult('success')}
			onDeleteError={(isLastAdmin?: boolean) => {
				if (isLastAdmin) setDeleteResult('last-admin-error');
				else setDeleteResult('error');
			}}
			closeHandler={() => {
				setOpenDrawer(false);
				navigate(AppUrls.admins);
			}}
			open={openDrawer}
		/>
	);

	const alerts = (
		<>
			<Alert
				uniqueKey={'admin-update-success'}
				show={adminUpdateResult === 'success'}
				type="success"
				message="Admin updated!"
				clearActionStatus={() => setAdminUpdateResult('')}
			/>

			<Alert
				uniqueKey={'admin-update-error'}
				show={adminUpdateResult === 'error'}
				type="error"
				message="Admin details were not updated. Please try again."
				clearActionStatus={() => setAdminUpdateResult('')}
			/>

			<Alert
				uniqueKey={'admin-success'}
				show={addNewResult === 'success'}
				type="success"
				message="Admin created!"
				clearActionStatus={() => setAddNewResult('')}
			/>

			<Alert
				uniqueKey={'admin-error'}
				show={addNewResult === 'error'}
				type="error"
				message="Error occurred while creating admin. Please try again."
				clearActionStatus={() => setAddNewResult('')}
			/>

			<Alert
				uniqueKey={'delete-success'}
				show={deleteResult === 'success'}
				type="success"
				message="Admin deleted!"
				clearActionStatus={() => setDeleteResult('')}
			/>
			<Alert
				uniqueKey={'delete-error'}
				show={deleteResult === 'error' || deleteResult === 'last-admin-error'}
				type="error"
				message={
					deleteResult === 'last-admin-error'
						? 'Company must have at least one admin. This is the last one.'
						: 'Error occurred while deleting admin. Please try again.'
				}
				clearActionStatus={() => setDeleteResult('')}
			/>
		</>
	);

	const renderModals = () => {
		const foundedUser = data?.find((user) => user.id === userToDelete);
		const name = `${foundedUser?.firstName} ${foundedUser?.lastName}`;

		return (
			<>
				<ModalDeleteAdmin
					open={deleteModalOpen}
					setOpen={setDeleteModalOpen}
					id={userToDelete}
					name={name}
					onDeleteSuccess={() => setDeleteResult('success')}
					onDeleteError={(isLastAdmin?: boolean) => {
						if (isLastAdmin) setDeleteResult('last-admin-error');
						else setDeleteResult('error');
					}}
					onCancel={() => {
						setDeleteModalOpen(false);
						setUserToDelete('');
					}}
				/>

				<ModalAddAdmin
					open={addNewModalOpen}
					setOpen={setAddNewModalOpen}
					onAddSuccess={(email: string) => {
						setAddedUser(email);
						setAddNewResult('success');
					}}
					onAddError={() => setAddNewResult('error')}
				/>
			</>
		);
	};

	const tableFilters = useMemo(() => {
		const leftSection = (
			<>
				<SearchFilter
					placeholder="Search admin..."
					setFilters={setCurrentFilters}
					properties={['firstName', 'lastName', 'email', 'title']}
					disabled={!dataLoading && !data.length}
				/>

				<CompanyFilter
					setFilters={setCurrentFilters}
					options={getCompanyOptions(companies)}
					disabled={!companies?.length || (!dataLoading && !data.length)}
				/>
			</>
		);

		const rightSection = (
			<Button width={132} onClick={() => setAddNewModalOpen(true)}>
				Add New
			</Button>
		);

		return <TableFilters leftSection={leftSection} rightSection={rightSection} />;
	}, [companies, data.length, dataLoading]);

	const mainTable = useMemo(() => {
		const getAddedAdminId = (email: string) => {
			const addedAdmin = data.find((admin) => admin.email === email);
			if (addedAdmin) return addedAdmin.id;
		};

		return (
			<Table
				tableKey="admins"
				data={currentPageData}
				columns={tableColumnsConfig}
				options={{
					rowActions: [
						{
							actionComponent: (
								<ActionButton
									tooltipId={'tooltip-delete-user'}
									tooltipContent={'Delete'}
									type="delete-user"
								/>
							),
							actionHandler: (id: string) => setUserToDelete(id),
						},
					],
					successHighlightedRows: [updatedAdminId || getAddedAdminId(addedUser) || ''],
					onSortChange,
					onRowClickHandler,
					emptyResultMessage:
						!dataLoading && !data.length
							? `No admins to display yet.`
							: `No matches found. Please try another search query.`,
					emptyResultMessageType: !dataLoading && !data.length ? 'users' : 'search',
				}}
			/>
		);
	}, [
		addedUser,
		currentPageData,
		data,
		dataLoading,
		onRowClickHandler,
		onSortChange,
		tableColumnsConfig,
		updatedAdminId,
	]);

	useEffect(() => {
		if (addedUser) {
			goToPageByDataEntryProperty(addedUser, 'email');

			const addDelay = setTimeout(() => {
				setAddedUser('');
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [addedUser, goToPageByDataEntryProperty]);

	useEffect(() => {
		if (updatedAdminId && data.find((p) => p.id === updatedAdminId)) {
			goToPageByDataEntryProperty(updatedAdminId, 'id');

			const addDelay = setTimeout(() => {
				setUpdatedAdminId(null);
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [data, goToPageByDataEntryProperty, updatedAdminId]);

	useEffect(() => {
		setOpenDrawer(!!showItemId);
	}, [showItemId]);

	useEffect(() => {
		if (showItemId) goToPageByDataEntryProperty(showItemId, 'id');
	}, [goToPageByDataEntryProperty, showItemId]);

	useEffect(() => {
		setIsProcessing(true);
		setCurrentPage(1);

		const proccessedData: IAdmin[] = processTableData(data, currentFilters, currentSort);

		setProcessedData(proccessedData);
		setIsProcessing(false);
	}, [currentFilters, currentSort, data, setCurrentPage]);

	useEffect(() => {
		if (!deleteModalOpen) setUserToDelete('');
	}, [deleteModalOpen]);

	useEffect(() => {
		if (userToDelete) setDeleteModalOpen(true);
	}, [userToDelete]);

	return (
		<>
			<BasicPanel
				className={classNames(styles['basic-panel'], isProcessing ? styles.disabled : '')}
			>
				{tableFilters}

				{isProcessing || dataLoading ? (
					<TableSkeleton rowsNumber={4} columns={tableColumnsConfig} />
				) : (
					mainTable
				)}
			</BasicPanel>

			{pagesCount > 1 ? paginationSection : null}

			{editDrawer}

			<HoverTooltip tooltipId="tooltip-delete-user" place="top-end" />

			{renderModals()}

			{alerts}
		</>
	);
};

export default AdminsTable;
