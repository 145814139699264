import { FC, useEffect, useMemo, useState } from 'react';
import styles from './EditGSPolicy.module.scss';
import IEditGSPolicy from './IEditGSPolicy';
import SectionTitle from '../../../primitives/section-title/SectionTitle';
import CollapsiblePanel from '../../../primitives/collapsible-panel/CollapsiblePanel';
import Input from '../../../primitives/form/input/Input';
import Textarea from '../../../primitives/form/textarea/Textarea';
import Select from '../../../primitives/form/select/Select';
import ModalLeaveWarning from '../../modals/modal-leave-warning/ModalLeaveWarning';
import { controlsAreDifferent, sanitizeData } from '../../../../utils/helpers/common';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import { IUpdatePolicy, updatePolicy } from '../../../../services/store/slices/policies.slice';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../services/store';
import useLeaveWarning from '../../../../utils/helpers/hooks/useLeaveWarning';
import usePolicies from '../../../../utils/helpers/hooks/usePolicies';
import Scrollbar from '../../../primitives/scrollbar/Scrollbar';
import FrameworksAndControlsBlock from '../../frameworks-and-controls-block/FrameworksAndControlsBlock';
import Loader from '../../../primitives/loader/Loader';
import ActionsBlock from '../../../primitives/actions-block/ActionsBlock';
import Button from '../../../primitives/button/Button';
import { PolicyStatusOptions } from '../../../../utils/helpers/constants';
import Checkbox from '../../../primitives/form/checkbox/Checkbox';
import HoverTooltip from '../../../primitives/tooltip/HoverTooltip';
import ModalDeletePolicy from '../../modals/modal-delete-policy/ModalDeletePolicy';
import { CommonResultStatusType } from '../../../../utils/types';
import Alert from '../../../primitives/alert/Alert';
import { commonStringIsValid } from '../../../../utils/helpers/common/form';
import Drawer from '../../../primitives/drawer/Drawer';

const EditGSPolicy: FC<IEditGSPolicy> = ({
	policy,
	open,
	onUpdateError,
	onUpdateSuccess,
	onDeleteSuccess,
	closeHandler,
}) => {
	const dispatch = useAppDispatch();
	const { handleSubmit } = useForm();
	const { policyLoading, updateLoading } = usePolicies();

	const { id, name, status, framework, description, preamble, baseControls, version, isInitial } =
		policy || {};

	const [submitIsDisabled, setSubmitIsDisabled] = useState(false);
	const [currentFormState, setCurrentFormState] = useState<IUpdatePolicy | null>();
	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [showBrowserLeaveWarning, setShowBrowserLeaveWarning] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [deleteResult, setDeleteResult] = useState<CommonResultStatusType>('');

	const initialEditFormState: IUpdatePolicy = useMemo(
		() => ({
			id,
			name,
			status,
			controls: baseControls?.map((c) => c.id || ''),
			framework: framework?.id,
			description: description || '',
			preamble: preamble || '',
			isInitial,
		}),
		[id, name, status, baseControls, framework?.id, description, preamble, isInitial],
	);

	const onFormSubmitHandler = () => {
		if (submitIsDisabled) return;

		const sanitizedData = sanitizeData(currentFormState);

		handleWithTryCatch(
			async () => {
				await dispatch(updatePolicy(policy?.id!, sanitizedData));

				onUpdateSuccess();
			},
			undefined,
			onUpdateError,
		);
	};

	const renderDetails = () => {
		const trigger = <SectionTitle className={styles.details}>Details</SectionTitle>;

		return (
			<CollapsiblePanel trigger={trigger}>
				<div className={styles['checkbox-group']}>
					<Checkbox
						className={styles.input}
						id="default"
						text="Default policy"
						checked={isInitial}
						onChange={(e) =>
							setCurrentFormState((prev) => ({
								...prev,
								isInitial: e.target.checked,
							}))
						}
					/>
					<span
						data-tooltip-id="tooltip-default"
						data-tooltip-content="This policy will be added as a default template for new users"
						className={styles.info}
					></span>
				</div>

				<Input
					type="text"
					className={styles.input}
					value={currentFormState?.name}
					withErrorStyle={
						!currentFormState?.name || !commonStringIsValid(currentFormState.name)
					}
					label="Policy Name"
					onValueChange={(value) =>
						setCurrentFormState((prev) => ({ ...prev, name: value }))
					}
				/>

				<Textarea
					onValueChange={(value) =>
						setCurrentFormState((prev) => ({ ...prev, description: value }))
					}
					defaultValue={currentFormState?.description}
					withErrorStyle={!commonStringIsValid(currentFormState?.description)}
					className={styles.input}
					placeholder="Enter or paste policy description here..."
					label="Description (optional)"
					asInput
				/>

				<Textarea
					onValueChange={(value) =>
						setCurrentFormState((prev) => ({ ...prev, preamble: value }))
					}
					defaultValue={currentFormState?.preamble}
					withErrorStyle={!commonStringIsValid(currentFormState?.preamble)}
					className={styles.input}
					placeholder="Enter or paste policy preamble here..."
					label="Preamble (optional)"
				/>

				<div className={styles['input-group']}>
					<Select
						id="status"
						onValueChange={(value) =>
							setCurrentFormState((prev) => ({ ...prev, status: value }))
						}
						defaultValue={currentFormState?.status}
						className={styles.input}
						options={PolicyStatusOptions}
						label="Status"
					/>

					<Input
						disabled
						type="text"
						className={styles.input}
						value={`${version || 1}`}
						label="Policy Version"
					/>
				</div>
			</CollapsiblePanel>
		);
	};

	const renderFrameworksAndControls = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>
				Frameworks and Controls
			</SectionTitle>
		);

		const setPolicyControls = (newControls: string[]) => {
			setCurrentFormState((prev) => ({ ...prev, controls: newControls }));
		};

		const setPolicyFramework = (newFramework: string) => {
			setCurrentFormState((prev) => ({ ...prev, framework: newFramework }));
		};

		return (
			<CollapsiblePanel trigger={trigger} isOpened>
				<FrameworksAndControlsBlock
					entityControlIds={currentFormState?.controls || []}
					entityFrameworkId={currentFormState?.framework || ''}
					setControlIds={setPolicyControls}
					setFrameworkId={setPolicyFramework}
				/>
			</CollapsiblePanel>
		);
	};

	const renderFormActions = () => {
		return (
			<ActionsBlock className={styles.actions}>
				<Button
					className={styles.action}
					width={132}
					negative
					type="button"
					disabled={submitIsDisabled || policyLoading || updateLoading}
					onClick={() => setDeleteModalOpen(true)}
				>
					Delete
				</Button>

				<Button
					className={styles.action}
					width={132}
					type="submit"
					disabled={submitIsDisabled || policyLoading || updateLoading}
				>
					{policyLoading || updateLoading ? (
						<Loader thin maxHeight={14} maxWidth={14} />
					) : (
						'Save'
					)}
				</Button>
			</ActionsBlock>
		);
	};

	const editForm = (
		<form onSubmit={handleSubmit(onFormSubmitHandler)}>
			<Scrollbar className={styles.scrollbar}>
				<div className={styles.inputs}>
					{renderDetails()}

					{renderFrameworksAndControls()}
				</div>
			</Scrollbar>

			{renderFormActions()}
		</form>
	);

	const alerts = (
		<Alert
			uniqueKey={'delete-error'}
			show={deleteResult === 'error'}
			type="error"
			message="Error while policy delete."
			clearActionStatus={() => setDeleteResult('')}
		/>
	);

	const modals = (
		<>
			<ModalLeaveWarning
				open={warningModalOpen}
				setOpen={setWarningModalOpen}
				onConfirm={() => {
					setShowBrowserLeaveWarning(false);
					closeHandler();
				}}
			/>

			<ModalDeletePolicy
				open={deleteModalOpen}
				setOpen={setDeleteModalOpen}
				id={policy?.id || ''}
				name={policy?.name}
				onDeleteSuccess={onDeleteSuccess}
				onDeleteError={() => setDeleteResult('error')}
				onCancel={() => setDeleteModalOpen(false)}
			/>
		</>
	);

	const changesWereMade = useMemo(() => {
		if (currentFormState?.id && policy) {
			const controlsDiffer = controlsAreDifferent(
				currentFormState?.controls,
				initialEditFormState?.controls,
			);

			if (
				currentFormState?.name !== initialEditFormState.name ||
				currentFormState?.status !== initialEditFormState.status ||
				currentFormState?.description !== initialEditFormState.description ||
				currentFormState?.preamble !== initialEditFormState.preamble ||
				currentFormState?.isInitial !== initialEditFormState.isInitial ||
				currentFormState?.framework !== initialEditFormState.framework ||
				controlsDiffer
			) {
				return true;
			}

			return false;
		}

		return false;
	}, [
		currentFormState?.controls,
		currentFormState?.description,
		currentFormState?.framework,
		currentFormState?.id,
		currentFormState?.isInitial,
		currentFormState?.name,
		currentFormState?.preamble,
		currentFormState?.status,
		initialEditFormState?.controls,
		initialEditFormState.description,
		initialEditFormState.framework,
		initialEditFormState.isInitial,
		initialEditFormState.name,
		initialEditFormState.preamble,
		initialEditFormState.status,
		policy,
	]);

	useLeaveWarning(showBrowserLeaveWarning);

	useEffect(() => {
		if (changesWereMade) setShowBrowserLeaveWarning(true);
	}, [changesWereMade]);

	useEffect(() => {
		setCurrentFormState(initialEditFormState);
	}, [initialEditFormState]);

	useEffect(() => {
		if (!currentFormState?.name || !commonStringIsValid(currentFormState.name))
			return setSubmitIsDisabled(true);

		setSubmitIsDisabled(false);
	}, [currentFormState?.name]);

	useEffect(() => {
		if (!open) {
			setShowBrowserLeaveWarning(false);
			setSubmitIsDisabled(false);
		}
	}, [open]);

	return (
		<Drawer
			title="Policy Details"
			contentClassName={styles.drawer}
			open={open}
			onCloseClickHandler={() => {
				if (changesWereMade) setWarningModalOpen(true);
				else closeHandler();
			}}
		>
			<div className={styles['edit-policy']}>
				{open ? editForm : null}

				{modals}

				{alerts}

				<HoverTooltip tooltipId="tooltip-default" place="bottom-start" />
			</div>
		</Drawer>
	);
};

export default EditGSPolicy;
