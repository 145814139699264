import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ISortProps, CommonResultStatusType, IKeyValuePair } from '../../../../utils/types';
import Table from '../../../primitives/table/Table';
import { processTableData } from '../../../../utils/helpers/common';
import TableFilters from '../../../primitives/table-filters/TableFilters';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import styles from './CompaniesTable.module.scss';
import { getCompanyColumns } from './utils';
import usePagination from '../../../../utils/helpers/hooks/usePagination';
import Alert from '../../../primitives/alert/Alert';
import useDevice from '../../../../utils/helpers/hooks/useDevice/useDevice';
import TableSkeleton from '../../../primitives/table/TableSkeleton';
import classNames from 'classnames';
import SearchFilter from '../../../primitives/filters/search-filter/SearchFilter';
import IPartialTable from '../common/IPartialTable';
import Button from '../../../primitives/button/Button';
import ActionButton from '../../../primitives/action-button/ActionButton';
import HoverTooltip from '../../../primitives/tooltip/HoverTooltip';
import { ICompany } from '../../../../services/store/slices/companies.slice';
import ModalDeleteCompany from '../../modals/modal-delete-company/ModalDeleteCompany';
import EditCompany from '../../edit-panels/company/EditCompany';
import StatusFilter from '../../../primitives/filters/status-filter/StatusFilter';
import { AppQueryParams, CompanyStatusOptions } from '../../../../utils/helpers/constants';
import ModalCreateCompany from '../../modals/modal-create-company/ModalCreateCompany';
import ModalCompaniesStatusChange from '../../modals/modal-companies-status-change/ModalCompaniesStatusChange';
import SelectedNumber from '../../../primitives/selected-number/SelectedNumber';
import ChangeStatusBlock from '../../../primitives/change-status-block/ChangeStatusBlock';
import { AppUrls } from '../../../../utils/helpers/constants/app-routes';
import { useNavigate } from 'react-router-dom';

const CompaniesTable: FC<IPartialTable<ICompany>> = ({ data, dataLoading, showItemId }) => {
	const device = useDevice();
	const navigate = useNavigate();

	const [processedData, setProcessedData] = useState(data);
	const [currentPageData, setCurrentPageData] = useState<ICompany[]>([]);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [companyUpdateResult, setCompanyUpdateResult] = useState<CommonResultStatusType>('');
	const [isProcessing, setIsProcessing] = useState(true);
	const [addNewModalOpen, setAddNewModalOpen] = useState(false);
	const [addNewResult, setAddNewResult] = useState<CommonResultStatusType | 'user-error'>('');
	const [deleteResult, setDeleteResult] = useState<CommonResultStatusType>('');
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [companyToDelete, setCompanyToDelete] = useState('');
	const [addedCompany, setAddedCompany] = useState('');
	const [showExtraActionsBlock, setShowExtraActionsBlock] = useState(false);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [changeStatusResult, setChangeStatusResult] = useState<CommonResultStatusType>('');
	const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false);
	const [changeStatusValue, setChangeStatusValue] = useState('');
	const [updatedCompanyId, setUpdatedCompanyId] = useState<string | null>('');

	const { paginationSection, setCurrentPage, pagesCount, goToPageByDataEntryProperty } =
		usePagination(processedData, setCurrentPageData);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: ICompany) => true,
	});

	const tableColumnsConfig = useMemo(() => getCompanyColumns(device), [device]);

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onRowClickHandler = useCallback(
		(id: string) => navigate(`${AppUrls.companies}?${AppQueryParams.item}=${id}`),
		[navigate],
	);

	const onCompanyUpdateSuccess = () => {
		setUpdatedCompanyId(showItemId || '');
		setCompanyUpdateResult('success');
		setOpenDrawer(false);
		navigate(`${AppUrls.companies}`);
	};
	const onCompanyUpdateError = () => setCompanyUpdateResult('error');

	const editDrawer = (
		<EditCompany
			companyId={showItemId}
			onUpdateSuccess={onCompanyUpdateSuccess}
			onUpdateError={onCompanyUpdateError}
			onDeleteSuccess={() => setDeleteResult('success')}
			onDeleteError={() => setDeleteResult('error')}
			closeHandler={() => {
				setOpenDrawer(false);
				navigate(`${AppUrls.companies}`);
			}}
			open={openDrawer}
		/>
	);

	const alerts = (
		<>
			<Alert
				uniqueKey={'company-update-success'}
				show={companyUpdateResult === 'success'}
				type="success"
				message="Company updated!"
				clearActionStatus={() => setCompanyUpdateResult('')}
			/>

			<Alert
				uniqueKey={'company-update-error'}
				show={companyUpdateResult === 'error'}
				type="error"
				message="Company details were not updated. Please try again."
				clearActionStatus={() => setCompanyUpdateResult('')}
			/>

			<Alert
				uniqueKey={'company-success'}
				show={addNewResult === 'success'}
				type="success"
				message="Company created!"
				clearActionStatus={() => setAddNewResult('')}
			/>

			<Alert
				uniqueKey={'company-error'}
				show={addNewResult === 'error' || addNewResult === 'user-error'}
				type="error"
				message={
					addNewResult === 'error'
						? 'Error occurred while creating company. Please try again.'
						: 'Such user already exists.'
				}
				clearActionStatus={() => setAddNewResult('')}
			/>

			<Alert
				uniqueKey={'delete-success'}
				show={deleteResult === 'success'}
				type="success"
				message="Company deleted!"
				clearActionStatus={() => setDeleteResult('')}
			/>
			<Alert
				uniqueKey={'delete-error'}
				show={deleteResult === 'error'}
				type="error"
				message="Error occurred while deleting company. Please try again."
				clearActionStatus={() => setDeleteResult('')}
			/>

			<Alert
				uniqueKey={'change-status-success'}
				show={changeStatusResult === 'success'}
				type="success"
				message="Status changed!"
				clearActionStatus={() => setChangeStatusResult('')}
			/>
			<Alert
				uniqueKey={'change-status-error'}
				show={changeStatusResult === 'error'}
				type="error"
				message="Error occurred while changing status. Please try again."
				clearActionStatus={() => setChangeStatusResult('')}
			/>
		</>
	);

	const renderModals = () => {
		const foundedCompany = data?.find((company) => company.id === companyToDelete);

		return (
			<>
				<ModalCreateCompany
					open={addNewModalOpen}
					setOpen={setAddNewModalOpen}
					onAddSuccess={(id: string) => {
						setAddedCompany(id);
						setAddNewResult('success');
					}}
					onAddError={(isAlreadyExists) =>
						setAddNewResult(isAlreadyExists ? 'user-error' : 'error')
					}
				/>

				<ModalDeleteCompany
					open={deleteModalOpen}
					setOpen={setDeleteModalOpen}
					id={companyToDelete}
					name={foundedCompany?.name}
					onDeleteSuccess={() => setDeleteResult('success')}
					onDeleteError={() => setDeleteResult('error')}
					onCancel={() => {
						setDeleteModalOpen(false);
						setCompanyToDelete('');
					}}
				/>

				<ModalCompaniesStatusChange
					ids={selectedRows}
					open={changeStatusModalOpen}
					setOpen={setChangeStatusModalOpen}
					status={changeStatusValue}
					onChangeSuccess={() => {
						setChangeStatusResult('success');
						setSelectedRows([]);
						setChangeStatusValue('');
					}}
					onChangeError={() => setChangeStatusResult('error')}
					onCancel={() => {
						setSelectedRows([]);
						setChangeStatusModalOpen(false);
						setChangeStatusValue('');
					}}
				/>
			</>
		);
	};

	const tableFilters = useMemo(() => {
		const leftSection = (
			<>
				<SearchFilter
					placeholder="Search company..."
					setFilters={setCurrentFilters}
					properties={['name']}
					disabled={!dataLoading && !data.length}
				/>

				<StatusFilter
					disabled={!dataLoading && !data.length}
					options={CompanyStatusOptions}
					setFilters={setCurrentFilters}
				/>
			</>
		);
		const rightSection = (
			<Button width={132} onClick={() => setAddNewModalOpen(true)}>
				Add New
			</Button>
		);

		return <TableFilters rightSection={rightSection} leftSection={leftSection} />;
	}, [data.length, dataLoading]);

	const highlightedRows = useMemo(() => {
		if (changeStatusResult === 'success') return selectedRows;

		if (addedCompany) {
			return [data?.find((company) => company.id === addedCompany)?.id || ''];
		}

		if (updatedCompanyId) return [updatedCompanyId];

		return [];
	}, [addedCompany, data, selectedRows, changeStatusResult, updatedCompanyId]);

	const mainTable = useMemo(
		() => (
			<Table
				tableKey="companies"
				data={currentPageData}
				columns={tableColumnsConfig}
				options={{
					rowActions: [
						{
							actionComponent: (
								<ActionButton
									tooltipId={'tooltip-delete-company'}
									tooltipContent={'Delete'}
									type="cancel"
								/>
							),
							actionHandler: (id: string) => setCompanyToDelete(id),
						},
					],
					selectedRows,
					setSelectedRows,
					rowCheckbox: true,
					successHighlightedRows: highlightedRows,
					onSortChange,
					onRowClickHandler,
					emptyResultMessage:
						!dataLoading && !data.length
							? `No companies to display yet.`
							: `No matches found. Please try another search query.`,
					emptyResultMessageType: !dataLoading && !data.length ? 'common' : 'search',
				}}
			/>
		),
		[
			currentPageData,
			data.length,
			dataLoading,
			highlightedRows,
			onRowClickHandler,
			onSortChange,
			selectedRows,
			tableColumnsConfig,
		],
	);

	useEffect(() => {
		setOpenDrawer(!!showItemId);
	}, [showItemId]);

	useEffect(() => {
		if (showItemId) goToPageByDataEntryProperty(showItemId, 'id');
	}, [goToPageByDataEntryProperty, showItemId]);

	useEffect(() => {
		setShowExtraActionsBlock(!!selectedRows.length);
	}, [selectedRows.length]);

	useEffect(() => {
		if (addedCompany) {
			goToPageByDataEntryProperty(addedCompany, 'id');

			const addDelay = setTimeout(() => {
				setAddedCompany('');
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [addedCompany, goToPageByDataEntryProperty]);

	useEffect(() => {
		if (updatedCompanyId && data.find((p) => p.id === updatedCompanyId)) {
			goToPageByDataEntryProperty(updatedCompanyId, 'id');

			const addDelay = setTimeout(() => {
				setUpdatedCompanyId(null);
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [data, goToPageByDataEntryProperty, updatedCompanyId]);

	useEffect(() => {
		setIsProcessing(true);
		setCurrentPage(1);

		const proccessedData: ICompany[] = processTableData(data, currentFilters, currentSort);

		setProcessedData(proccessedData);
		setIsProcessing(false);
	}, [currentFilters, currentSort, data, setCurrentPage]);

	useEffect(() => {
		if (!deleteModalOpen) setCompanyToDelete('');
	}, [deleteModalOpen]);

	useEffect(() => {
		if (companyToDelete) setDeleteModalOpen(true);
	}, [companyToDelete]);

	return (
		<>
			<BasicPanel
				className={classNames(styles['basic-panel'], isProcessing ? styles.disabled : '')}
			>
				<SelectedNumber number={selectedRows.length} />

				{showExtraActionsBlock ? (
					<ChangeStatusBlock
						className={styles['change-status']}
						show
						onChange={(status: string) => {
							setChangeStatusValue(status);
							setChangeStatusModalOpen(true);
						}}
					/>
				) : (
					tableFilters
				)}

				{isProcessing || dataLoading ? (
					<TableSkeleton rowsNumber={4} columns={tableColumnsConfig} />
				) : (
					mainTable
				)}
			</BasicPanel>

			{pagesCount > 1 ? paginationSection : null}

			{editDrawer}

			<HoverTooltip tooltipId="tooltip-delete-company" place="top-end" />

			{renderModals()}

			{alerts}
		</>
	);
};

export default CompaniesTable;
