import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import userApi from '../../api/user.api';
import { RootState } from '..';

export interface IPasswordChangeData {
	oldPassword?: string;
	newPassword?: string;
}

export interface IUserSlice {
	authorized: boolean | null;
	loading?: boolean;
}

export interface IUser {
	email?: string;
	password?: string;
	firstName?: string;
	lastName?: string;
	title?: string;
	mfaConfigured?: boolean;
}

export interface IUserInfo extends IUserSlice {
	info?: Partial<IUser> | null;
}

const initialState: IUserInfo = {
	authorized: null,
	loading: false,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		clearUserInfo: (state) => {
			state.info = null;
			state.loading = false;
		},
		setUserIsAuthorized: (state) => {
			state.authorized = true;
		},
		setUserIsUnauthorized: (state) => {
			state.authorized = false;
			state.loading = false;
			state.info = null;
		},
		setUserInfo: (state, { payload }: PayloadAction<Partial<IUser> | null>) => {
			state.info = { ...state.info, ...payload };
		},
	},
});

export const getUserInfo =
	(withLoading: boolean = true) =>
	async (dispatch: Dispatch<any>) => {
		if (withLoading) dispatch(loading());

		try {
			const user = await userApi.getUserInfo();

			dispatch(setUserInfo(user));
		} catch (error: any) {
			dispatch(setUserInfo(null));
			throw new CommonResponseError('Error while getting user info');
		} finally {
			dispatch(loaded());
		}
	};

export const updateUserMFAConfigured =
	(newMFAConfigured: boolean) =>
	async (dispatch: Dispatch<any>, getState: () => RootState) => {
		try {
			await userApi.updateUserInfo({
				mfaConfigured: newMFAConfigured,
			});

			dispatch(getUserInfo(false));
		} catch (error: any) {
			throw new CommonResponseError('Error while updating email preferences');
		}
	};

export const {
	loading,
	loaded,
	clearUserInfo,
	setUserIsAuthorized,
	setUserIsUnauthorized,
	setUserInfo,
} = userSlice.actions;
export default userSlice.reducer;
