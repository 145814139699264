import React, { FC, useState } from 'react';
import IAttemptFiles from './IAttemptFiles';
import styles from './AttemptFiles.module.scss';
import { IAILogJobAttemptFile } from '../../../services/store/slices/ai-logs.slice';
import LogFileBlock from '../log-file-block/LogFileBlock';
import usePagination from '../../../utils/helpers/hooks/usePagination';

const AttemptFiles: FC<IAttemptFiles> = ({ files, status }) => {
	const [currentPageData, setCurrentPageData] = useState<IAILogJobAttemptFile[]>([]);

	const { paginationSection, pagesCount } = usePagination(
		files || [],
		setCurrentPageData,
		3,
		true,
	);

	return (
		<div className={styles.files}>
			{currentPageData?.map((attemptFile: IAILogJobAttemptFile) => {
				return (
					<LogFileBlock
						key={attemptFile.file?.id}
						attemptFile={attemptFile}
						status={status}
					/>
				);
			})}

			{pagesCount > 1 ? <div className={styles.pagination}>{paginationSection}</div> : null}
		</div>
	);
};

export default AttemptFiles;
