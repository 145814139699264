import React, { FC, useCallback, useMemo, useState } from 'react';
import styles from './PasswordStep.module.scss';
import Button from '../../../../components/primitives/button/Button';
import useAuthorization from '../../../../utils/helpers/hooks/useAuthorization';
import Input from '../../../../components/primitives/form/input/Input';
import IResetPasswordStep from '../IResetPasswordStep';
import { useForm } from 'react-hook-form';
import Error from '../../../../components/primitives/error/Error';
import ActionsBlock from '../../../../components/primitives/actions-block/ActionsBlock';
import PasswordInstructions from '../../../../components/primitives/password-instructions/PasswordInstructions';

const PasswordStep: FC<IResetPasswordStep> = ({ error, onSubmitHandler }) => {
	const { loading: authorizationLoading } = useAuthorization();
	const { handleSubmit } = useForm();
	const [newPasswordIsValid, setNewPasswordIsValid] = useState(true);

	const [passwords, setPasswords] = useState({
		password: '',
		confirmedPassword: '',
	});

	const passwordsAreSimilar =
		passwords.password && passwords.confirmedPassword
			? passwords.password === passwords.confirmedPassword
			: true;

	const submitIsDisabled = !!(
		!passwords.password ||
		!passwords.confirmedPassword ||
		!passwordsAreSimilar ||
		!newPasswordIsValid
	);

	const onFormSubmitHandler = () => {
		if (submitIsDisabled) return;
		if (onSubmitHandler) onSubmitHandler(passwords.password);
	};

	const onValidationHandler = useCallback((isValid: boolean) => {
		setNewPasswordIsValid(isValid);
	}, []);

	const passwordInstructions = useMemo(
		() => (
			<PasswordInstructions
				passwordInput={passwords.password}
				className={styles.instructions}
				onValidationHandler={onValidationHandler}
			/>
		),
		[passwords.password, onValidationHandler],
	);

	const resetPasswordForm = (
		<form className={styles.form} noValidate onSubmit={handleSubmit(onFormSubmitHandler)}>
			<Input
				id="password"
				type="password"
				autoComplete="off"
				label="New Password"
				value={passwords.password}
				placeholder="Enter your new password"
				withErrorStyle={!newPasswordIsValid || !!error}
				onValueChange={(value: string) =>
					setPasswords((prev) => ({
						...prev,
						password: value,
					}))
				}
			/>

			<Input
				id="confirm-password"
				type="password"
				autoComplete="off"
				label="Repeat Password"
				value={passwords.confirmedPassword}
				placeholder="Repeat your new password"
				withErrorStyle={!passwordsAreSimilar || !!error}
				error={!passwordsAreSimilar ? `Passwords don't match` : ''}
				className={styles['confirm-password']}
				onValueChange={(value: string) =>
					setPasswords((prev) => ({
						...prev,
						confirmedPassword: value,
					}))
				}
			/>

			{error ? <Error message={error} /> : null}

			{passwordInstructions}

			<ActionsBlock className={styles.actions}>
				<Button disabled={submitIsDisabled || authorizationLoading} type="submit">
					Save
				</Button>
			</ActionsBlock>
		</form>
	);

	return (
		<>
			<p className={styles.info}>
				Create a new, strong password, that you don't use for other websites
			</p>

			{resetPasswordForm}
		</>
	);
};

export default PasswordStep;
