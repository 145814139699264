const AUTH = '/auth';
const LOGIN = '/auth/admin/login';
const LOGOUT = '/auth/admin/logout';
const INITIATE_RESET_PASSWORD = '/auth/admin/forgot-password';
const CONFIRM_RESET_PASSWORD = '/auth/reset-password';
const PASSWORD_REQUIREMENTS = '/auth/password-requirements';
const ADMINS = '/admin/users';
const ADMIN = '/admin/accounts';
const COMPANIES = '/admin/companies';
const BASE_POLICY = '/base-policy';
const BASE_CONTROL = '/base-control';
const FRAMEWORKS = '/frameworks';
const CROSSWALK = 'crosswalk-evaluation/controls';
const AI_LOGS = '/admin/jobs';

export {
	AUTH,
	LOGIN,
	LOGOUT,
	INITIATE_RESET_PASSWORD,
	ADMINS,
	CONFIRM_RESET_PASSWORD,
	BASE_POLICY,
	BASE_CONTROL,
	PASSWORD_REQUIREMENTS,
	COMPANIES,
	FRAMEWORKS,
	ADMIN,
	CROSSWALK,
	AI_LOGS,
};
