import { useContext, useEffect, useRef, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext, useNavigate } from 'react-router-dom';

export function useConfirmNavigation(showPrompt: () => void, when = true) {
	const navigate = useNavigate();
	const { navigator } = useContext(NavigationContext);

	const [nextUrl, setNextUrl] = useState<string | null>(null);
	const originNavigatorPush = useRef<any>();

	useEffect(() => {
		if (!when) return;

		const push = navigator.push;
		originNavigatorPush.current = push;

		navigator.push = (...args: Parameters<typeof push>) => {
			// @ts-ignore
			setNextUrl(`${args[0]?.pathname as string}${args[0]?.search as string}`);
			showPrompt();
		};

		return () => {
			navigator.push = push;
		};
	}, [navigator, when, showPrompt]);

	const continueNavigation = () => {
		if (originNavigatorPush.current) navigator.push = originNavigatorPush.current;
		navigate(nextUrl || '');
	};

	return {
		continueNavigation,
	};
}
