import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import { RootState } from '..';
import { IIdName } from '../../../utils/types';
import {
	dateInMDYFormat,
	getFrameworkSmallName,
	sortBaseControlObjByDisplayIdAsc,
	sortBaseControlsForCrosswalkAsc,
} from '../../../utils/helpers/common';
import crosswalkApi from '../../api/crosswalk.api';

export enum SupplementaryFrameworkTypes {
	ISO = 'iso',
	SOC = 'soc_2',
}

export interface ICrosswalkSlice {
	loading?: boolean;
	updateLoading?: boolean;
}

export interface ISupplementaryCrosswalkBasic {
	id: string;
	controlId: string;
	enhancementName: string;
	crosswalkMap: {
		id: number;
		relatedControls: {
			id: number;
			controlId: string;
			enhancementName: string;
		}[];
	};
	updatedAt: string;
}

export interface INistCrosswalkBasic {
	id: number;
	controlId: string;
	enhancementName: string;
	frameworks: { id: number }[];
	updatedAt?: Date;
}

export interface INistCrosswalk
	extends Omit<INistCrosswalkBasic, 'frameworks' | 'updatedAt' | 'id'> {
	id: string;
	low: boolean;
	moderate: boolean;
	high: boolean;
	updatedAt?: string;
}

export interface ISupplementaryCrosswalk
	extends Omit<ISupplementaryCrosswalkBasic, 'controls' | 'id'> {
	id: string;
	controls: IIdName[];
}

export interface ICrosswalks extends ICrosswalkSlice {
	nistItems: INistCrosswalk[] | null;
	isoItems: ISupplementaryCrosswalk[] | null;
	socItems: ISupplementaryCrosswalk[] | null;
}

export interface IUpdateNistCrosswalk {
	id: number;
	frameworkIds: number[];
}

export interface IUpdateIsoCrosswalk {
	id: string;
	controls: number[];
}

const initialState: ICrosswalks = {
	loading: false,
	updateLoading: false,
	nistItems: null,
	isoItems: null,
	socItems: null,
};

export const crosswalkSlice = createSlice({
	name: 'crosswalk',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		updateLoading: (state) => {
			state.updateLoading = true;
		},
		updateLoaded: (state) => {
			state.updateLoading = false;
		},
		setNistCrosswalk: (state, { payload }: PayloadAction<INistCrosswalk[]>) => {
			state.nistItems = payload;
		},
		setIsoCrosswalk: (state, { payload }: PayloadAction<ISupplementaryCrosswalk[]>) => {
			state.isoItems = payload;
		},
		setSocCrosswalk: (state, { payload }: PayloadAction<ISupplementaryCrosswalk[]>) => {
			state.socItems = payload;
		},
	},
});

export const getNistCrosswalk =
	() => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		dispatch(loading());

		try {
			const frameworks = getState().frameworks.items;

			const crosswalkData = await crosswalkApi.getFoundationalCrosswalkForNist();

			const frameworksByIds = frameworks?.reduce(
				(acc, item) => {
					const smallName = getFrameworkSmallName(item.name || '');

					//@ts-ignore
					acc[smallName] = item.id;

					return acc;
				},
				{} as { [key: string]: string },
			);

			const transformedData = crosswalkData.map((item) => {
				const low = item.frameworks.some((f) => f.id.toString() === frameworksByIds?.low);
				const moderate = item.frameworks.some(
					(f) => f.id.toString() === frameworksByIds?.moderate,
				);
				const high = item.frameworks.some((f) => f.id.toString() === frameworksByIds?.high);

				return {
					...item,
					id: item.id.toString(),
					low,
					moderate,
					high,
					updatedAt: dateInMDYFormat(item.updatedAt),
				};
			});

			await dispatch(setNistCrosswalk(sortBaseControlsForCrosswalkAsc(transformedData)));
		} catch (error: any) {
			dispatch(setNistCrosswalk([]));
		} finally {
			dispatch(loaded());
		}
	};

export const getSupplementaryCrosswalk =
	(type: SupplementaryFrameworkTypes) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			const crosswalkData = await crosswalkApi.getSupplementaryCrosswalk(type);

			const transformedData = crosswalkData.map((item) => {
				const controls =
					item.crosswalkMap?.relatedControls.map((control) => {
						return {
							id: control?.id.toString() || '',
							displayId: control?.controlId || '',
							name: control?.enhancementName || '',
						};
					}) || [];

				return {
					...item,
					id: item.id.toString(),
					controls: sortBaseControlObjByDisplayIdAsc(controls),
					crosswalkMapId: item.crosswalkMap?.id,
					updatedAt: dateInMDYFormat(item.updatedAt),
				};
			});

			if (type === SupplementaryFrameworkTypes.ISO) {
				await dispatch(setIsoCrosswalk(sortBaseControlsForCrosswalkAsc(transformedData)));
			}
			if (type === SupplementaryFrameworkTypes.SOC) {
				await dispatch(setSocCrosswalk(sortBaseControlsForCrosswalkAsc(transformedData)));
			}
		} catch (error: any) {
			if (type === SupplementaryFrameworkTypes.ISO) {
				dispatch(setIsoCrosswalk([]));
			}
			if (type === SupplementaryFrameworkTypes.SOC) {
				dispatch(setSocCrosswalk([]));
			}
		} finally {
			dispatch(loaded());
		}
	};

export const updateNistCrosswalk =
	(data: INistCrosswalk[]) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		dispatch(updateLoading());

		try {
			const frameworks = getState().frameworks.items;

			const frameworksByIds = frameworks?.reduce(
				(acc, item) => {
					const smallName = getFrameworkSmallName(item.name || '');

					//@ts-ignore
					acc[smallName] = item.id;

					return acc;
				},
				{} as { [key: string]: string },
			);

			const transformedData = data.map((item: INistCrosswalk) => {
				const frameworks = [];

				if (item.low) frameworks.push(Number(frameworksByIds?.low));
				if (item.moderate) frameworks.push(Number(frameworksByIds?.moderate));
				if (item.high) frameworks.push(Number(frameworksByIds?.high));

				return {
					id: Number(item.id),
					frameworkIds: frameworks,
				} as IUpdateNistCrosswalk;
			});

			await crosswalkApi.updateNistCrosswalk(transformedData);
			await dispatch(getNistCrosswalk());
		} catch (error: any) {
			throw new CommonResponseError('Error while updating nist crosswalk.');
		} finally {
			dispatch(updateLoaded());
		}
	};

export const updateSupplementaryCrosswalk =
	(data: IUpdateIsoCrosswalk, type: SupplementaryFrameworkTypes) =>
	async (dispatch: Dispatch<any>) => {
		dispatch(updateLoading());

		try {
			await crosswalkApi.updateSupplementaryCrosswalk(data, type);
			await dispatch(getSupplementaryCrosswalk(type));
		} catch (error: any) {
			console.log('error update', error);
			throw new CommonResponseError('Error while updating iso crosswalk.');
		} finally {
			dispatch(updateLoaded());
		}
	};

export const {
	loading,
	loaded,
	setNistCrosswalk,
	setIsoCrosswalk,
	setSocCrosswalk,
	updateLoading,
	updateLoaded,
} = crosswalkSlice.actions;
export default crosswalkSlice.reducer;
