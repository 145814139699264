import { ITableColumn } from '../../../primitives/table/ITable';
import { DeviceType } from '../../../../utils/helpers/hooks/useDevice/IUseDevice';
import { ICompany } from '../../../../services/store/slices/companies.slice';

export const getAdminColumns = (device?: DeviceType | null) => {
	return [
		{
			header: 'First Name',
			key: 'firstName',
			sorting: true,
			width: '22%',
		},
		{
			header: 'Last Name',
			key: 'lastName',
			sorting: true,
			width: '22%',
		},
		{
			header: 'Email',
			key: 'email',
			sorting: true,
			width: '28%',
		},
		{
			header: 'Company',
			key: 'company',
			sorting: true,
			width: '24%',
			columnRender: (company: ICompany) => company?.name || '',
		},
		{
			header: '',
			key: 'actions',
			width: '5%',
		},
	] as ITableColumn[];
};
