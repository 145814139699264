import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../services/store';
import useUser from '../../utils/helpers/hooks/useUser';
import styles from './Profile.module.scss';
import BasicPanel from '../../components/primitives/basic-panel/BasicPanel';
import classNames from 'classnames';
import Alert from '../../components/primitives/alert/Alert';
import useAuthorization from '../../utils/helpers/hooks/useAuthorization';
import {
	disableMFACode,
	getPasswordRequirements,
	logOut,
} from '../../services/store/slices/authorization.slice';
import { handleWithTryCatch } from '../../utils/helpers/errors';
import { CommonResultStatusType } from '../../utils/types';
import ReactSwitch from 'react-switch';
import ColoredTag from '../../components/primitives/tag/ColoredTag';
import { AppTagColors } from '../../utils/helpers/constants';
import Scrollbar from '../../components/primitives/scrollbar/Scrollbar';
import EditTwoFactor from '../../components/partials/edit-panels/profile/two-factor/EditTwoFactorPanel';
import Button from '../../components/primitives/button/Button';
import {
	updateUserMFAConfigured,
} from '../../services/store/slices/user.slice';

const Profile: FC = () => {
	const dispatch = useAppDispatch();
	const { info } = useUser();
	const { passwordRequirements } = useAuthorization();

	const [openEditTwoFactor, setOpenEditTwoFactor] = useState(false);
	const [twoFactorAuthOn, setTwoFactorAuthOn] = useState(!!info?.mfaConfigured);
	const [twoFactorAuthUpdateResult, setTwoFactorAuthUpdateResult] =
		useState<CommonResultStatusType>('');

	const configsBlock = (
		<div className={styles.configs}>
			<div
				className={classNames(styles.logout, styles.item)}
				onClick={() => dispatch(logOut())}
			>
				Log Out
			</div>
		</div>
	);

	const onTwoFactorSuccess = () => {
		setTwoFactorAuthUpdateResult('success');
		setOpenEditTwoFactor(false);
	};

	const onTwoFactorError = () => {
		setTwoFactorAuthUpdateResult('error');
		setOpenEditTwoFactor(false);
		if (!info?.mfaConfigured) onTwoFactorAuthChange(false);
	};

	const onTwoFactorAuthChange = (turnOn: boolean) => {
		setTwoFactorAuthOn(turnOn);
		if (turnOn) setOpenEditTwoFactor(true);

		if (turnOn === false && info?.mfaConfigured) {
			handleWithTryCatch(
				async () => {
					await dispatch(disableMFACode());
					await dispatch(updateUserMFAConfigured(false));
				},
				undefined,
				() => {
					setTwoFactorAuthUpdateResult('error');
				},
			);
		}
	};

	const twoFactorConfigBlock = (
		<label className={styles['switcher-wrap']}>
			<ReactSwitch
				onChange={onTwoFactorAuthChange}
				checked={twoFactorAuthOn}
				uncheckedIcon={false}
				checkedIcon={false}
				activeBoxShadow={'none'}
				offHandleColor={'#A0D47D'}
				onHandleColor={'#62AB30'}
				onColor={'#F2FAE8'}
				offColor={'#F6FCEF'}
				className={twoFactorAuthOn ? '' : styles.off}
				handleDiameter={16}
				height={20}
				width={36}
			/>

			<p className={styles.label}>Enable 2-Step Verification for this account</p>
		</label>
	);

	useEffect(() => {
		if (!passwordRequirements) {
			handleWithTryCatch(() => dispatch(getPasswordRequirements()));
		}
	}, [dispatch, passwordRequirements]);

	useEffect(() => {
		setTwoFactorAuthOn(!!info?.mfaConfigured);
	}, [info]);

	const editTwoStepAuthSection = (
		<>
			<EditTwoFactor
				open={openEditTwoFactor}
				onUpdateSuccess={onTwoFactorSuccess}
				onUpdateError={onTwoFactorError}
				closeHandler={() => {
					setOpenEditTwoFactor(false);
					if (!info?.mfaConfigured) onTwoFactorAuthChange(false);
				}}
			/>

			<Alert
				uniqueKey={'two-step-auth-success'}
				show={twoFactorAuthUpdateResult === 'success'}
				type="success"
				message="2-Step verification updated!"
				clearActionStatus={() => setTwoFactorAuthUpdateResult('')}
			/>
			<Alert
				uniqueKey={'two-step-auth-error'}
				show={twoFactorAuthUpdateResult === 'error'}
				type="error"
				message="Error occurred while updating 2-Step verification"
				clearActionStatus={() => setTwoFactorAuthUpdateResult('')}
			/>
		</>
	);

	return (
		<>
			<h2>Profile</h2>
			<Scrollbar className={styles.scrollbar}>
				<div className={styles.panels}>
					<BasicPanel className={classNames(styles['basic-panel'], styles.notifications)}>
						<h3>2-Step Verification</h3>

						{twoFactorAuthOn && info?.mfaConfigured ? (
							<Button
								small
								className={styles.edit}
								negative
								width={56}
								onClick={() => setOpenEditTwoFactor(true)}
							>
								Edit
							</Button>
						) : (
							<ColoredTag
								tagId={'2-step-verification-recommended'}
								text={'Recommended'}
								bgColor={AppTagColors[8]}
								className={styles.recommendedLabel}
							/>
						)}

						{twoFactorConfigBlock}
					</BasicPanel>

					<BasicPanel className={classNames(styles['basic-panel'], styles.configs)}>
						{configsBlock}
					</BasicPanel>

					{editTwoStepAuthSection}
				</div>
			</Scrollbar>
		</>
	);
};

export default Profile;
