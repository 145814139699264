import { FC, useEffect, useState } from 'react';
import styles from './ModalCreatePolicy.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import StepsProgress from '../../../primitives/steps-progress/StepsProgress';
import Back from '../../../primitives/back/Back';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import { useAppDispatch } from '../../../../services/store';
import IModalCreatePolicy from './IModalCreatePolicy';
import DetailsStep from './steps/details-step/DetailsStep';
import { ICreatePolicy, createPolicy } from '../../../../services/store/slices/policies.slice';
import ControlsStep from './steps/controls-step/ControlsStep';
import { dateInVersionFormat } from '../../../../utils/helpers/common';
import { ICommonStatuses } from '../../../../utils/types';

const ModalCreatePolicy: FC<IModalCreatePolicy> = ({ open, setOpen, onAddSuccess, onAddError }) => {
	const dispatch = useAppDispatch();
	const [currentStep, setCurrentStep] = useState('details');

	const [currentFormState, setCurrentFormState] = useState<ICreatePolicy>({
		name: '',
		description: '',
		preamble: '',
		framework: '',
		controls: [],
		version: '',
		isInitial: false,
	});

	const onFormSubmit = (controls?: string[]) => {
		handleWithTryCatch(
			async () => {
				const addedPolicyId = await dispatch(
					createPolicy({
						...currentFormState,
						controls: controls || [],
						version: dateInVersionFormat(new Date().toString()),
						status: ICommonStatuses.unpublished,
						isInitial: currentFormState.isInitial || false,
						description: currentFormState.description || '',
						preamble: currentFormState.preamble || '',
					}),
				);
				onAddSuccess(addedPolicyId || '');
				setOpen(false);
			},
			undefined,
			onAddError,
		);
	};

	const createPolicySteps = [
		{
			stepName: 'details',
			component: (
				<DetailsStep
					currentFormState={currentFormState}
					setCurrentFormState={setCurrentFormState}
					setOpen={setOpen}
					setNextStep={() => setCurrentStep('controls')}
				/>
			),
		},
		{
			stepName: 'controls',
			component: (
				<ControlsStep
					currentFormState={currentFormState}
					setOpen={setOpen}
					onFormSubmit={onFormSubmit}
				/>
			),
		},
	];

	const activeStepNumber = createPolicySteps.findIndex((step) => step.stepName === currentStep);

	useEffect(() => {
		if (!open) {
			setCurrentStep('details');
			setCurrentFormState({
				name: '',
				description: '',
				framework: '',
				controls: [],
				version: '',
				isInitial: false,
				status: ICommonStatuses.unpublished,
			});
		}
	}, [open]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<StepsProgress
					small
					className={styles.progress}
					stepsNumber={createPolicySteps.length}
					activeStep={activeStepNumber}
				/>

				<div className={currentStep === 'controls' ? styles.title : ''}>
					{currentStep === 'controls' ? (
						<Back className={styles.back} onClick={() => setCurrentStep('details')} />
					) : null}

					<h2>Create Policy</h2>
				</div>

				{createPolicySteps.find((step) => step.stepName === currentStep)?.component}
			</BasicPanel>
		</Modal>
	);
};

export default ModalCreatePolicy;
