import { controlsColumnRenderHelper } from '../../../../utils/helpers/common';
import { DeviceType } from '../../../../utils/helpers/hooks/useDevice/IUseDevice';
import { IIdName } from '../../../../utils/types';
import { ITableColumn } from '../../../primitives/table/ITable';
import ColoredTag from '../../../primitives/tag/ColoredTag';

export const getIsoCrosswalkColumns = (device?: DeviceType | null) => {
	return [
		{
			header: 'Control ID',
			key: 'controlId',
			sorting: true,
			width: '13.8%',
		},
		{
			header: 'Control Enhancement Name',
			key: 'enhancementName',
			sorting: true,
			width: '31%',
		},
		{
			header: 'NIST Controls',
			key: 'controls',
			width: '35%',
			columnRender: (controls: IIdName[]) =>
				controlsColumnRenderHelper(
					controls.map((c) => c.displayId!),
					device,
				),
		},
		{
			header: 'Updated Time',
			key: 'updatedAt',
			sorting: true,
			width: '17.5%',
			columnRender: (value: string) => {
				return (
					<ColoredTag
						borderColor={'#F4F9EB'}
						textColor="#7A926A"
						tagId={value}
						text={value}
						withoutBackground
					/>
				);
			},
		},
	] as ITableColumn[];
};
