import React, { FC, useState } from 'react';
import styles from './LogFileBlock.module.scss';
import classNames from 'classnames';
import HoverTooltip from '../../primitives/tooltip/HoverTooltip';
import DownloadFile from '../download-file/DownloadFile';
import { AI_PLATFORM_THREADS_LINK } from '../../../utils/helpers/constants';
import ActionButton from '../../primitives/action-button/ActionButton';
import { IAILogJobAttemptTypeItem } from '../../../services/store/slices/ai-logs.slice';
import ILogFileBlock from './ILogFileBlock';
import { ICommonStatuses } from '../../../utils/types';

const LogFileBlock: FC<ILogFileBlock> = ({
	status,
	attemptFile: atemptFile,
}) => {
	const [typeFileLoadingIds, setTypeFileLoadingIds] = useState<string[]>([]);

	const onViewThread = (threadId?: string) => {
		if (!threadId) return;

		setTimeout(() => window.open(`${AI_PLATFORM_THREADS_LINK}${threadId}`, '_blank'));
	};

	const renderStatus = (typeData?: IAILogJobAttemptTypeItem) => {
		if (!typeData && status !== ICommonStatuses.completed && status !== ICommonStatuses.failed)
			return <div className={classNames(styles['status-item'], styles.pending)}></div>;

		if (!typeData?.logFileUrl)
			return <div className={classNames(styles['status-item'], styles.failed)}></div>;

		return <div className={classNames(styles['status-item'], styles.completed)}></div>;
	};

	const renderTypeBlock = (typeData?: IAILogJobAttemptTypeItem, type?: 'controls' | 'assets') => {
		const sectionName = type === 'controls' ? 'Controls' : 'Assets';

		return (
			<div
				key={typeData?.id}
				className={classNames(
					styles.section,
					typeFileLoadingIds.includes(typeData?.id.toString() || '')
						? styles.loading
						: '',
				)}
			>
				<div className={styles.type}>{sectionName}</div>

				<div className={styles.status}>{renderStatus(typeData)}</div>

				<div
					className={classNames(
						styles.actions,
						typeFileLoadingIds.includes(typeData?.id.toString() || '')
							? styles.active
							: '',
					)}
				>
					{typeData?.logFileUrl ? (
						<DownloadFile
							entityId={typeData.id.toString()}
							fileName={`AI_Logs_${sectionName}_${typeData.threadId}.csv`}
							fileUrl={typeData.logFileUrl || ''}
							className={styles.action}
							tooltipId="tooltip-download"
							tooltipContent="Download file"
							onFileLoading={setTypeFileLoadingIds}
						/>
					) : null}

					{typeData?.threadId ? (
						<ActionButton
							className={styles.action}
							tooltipId={'tooltip-link'}
							tooltipContent={'View thread'}
							type="link"
							onClick={() => onViewThread(typeData?.threadId || '')}
						/>
					) : null}
				</div>
			</div>
		);
	};

	return (
		<>
			<div className={styles['main-file']}>
				<div className={styles['file-name']}>{atemptFile.file?.name}</div>
				<div className={styles.actions}>
					<DownloadFile
						entityId={atemptFile.file?.id.toString() || ''}
						fileName={atemptFile.file?.name}
						fileUrl={atemptFile.file?.fileUrl || ''}
						className={styles.action}
						tooltipId="tooltip-download"
						tooltipContent="Download file"
						onFileLoading={setTypeFileLoadingIds}
					/>
				</div>
			</div>

			<div className={styles.types}>
				{renderTypeBlock(atemptFile.controls, 'controls')}
				{renderTypeBlock(atemptFile.assets, 'assets')}
			</div>

			<HoverTooltip tooltipId="tooltip-download" place="top-end" />
			<HoverTooltip tooltipId="tooltip-link" place="top-end" />
		</>
	);
};

export default LogFileBlock;
