import { useSelector } from 'react-redux';
import { RootState } from '../../../services/store';

export default function useAuthorization() {
	const { loading, passwordRequirements, qrCode, mfaCodeRequired, loginSession, userIdForSRP, secretCode } = useSelector(
		(store: RootState) => store.authorization,
	);

	return {
		loading,
		passwordRequirements,
		qrCode,
		secretCode,
		mfaCodeRequired,
		loginSession,
		userIdForSRP,
	};
}
