import axios from 'axios';
import { BASE_POLICY } from './api.path';
import { ICreatePolicy, IGSPolicyBasic, IUpdatePolicy } from '../store/slices/policies.slice';

const policiesApi = {
	getGSPolicies: (): Promise<IGSPolicyBasic[]> => {
		return axios.get(`${BASE_POLICY}`);
	},
	updateGSPolicy: (policyId: string, data: IUpdatePolicy): Promise<IGSPolicyBasic> => {
		return axios.put(`${BASE_POLICY}/${policyId}`, data);
	},
	createGSPolicy: (data: ICreatePolicy): Promise<IGSPolicyBasic> => {
		return axios.post(`${BASE_POLICY}`, data);
	},
	deleteGSPolicy: (policyId: string) => {
		return axios.delete(`${BASE_POLICY}/${policyId}`);
	},
};

export default policiesApi;
