import { useSelector } from 'react-redux';
import { RootState } from '../../../services/store';

export default function useCompanies() {
	const { items, loading, error, updateLoading } = useSelector((store: RootState) => store.companies);

	return {
		items,
		loading,
		error,
		updateLoading,
	};
}
