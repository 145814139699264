import axios from 'axios';
import { BASE_CONTROL } from './api.path';
import { IBaseControl } from '../store/slices/base-controls.slice';

const baseControlsApi = {
	getBaseControls: (): Promise<IBaseControl[]> => {
		return axios.get(`${BASE_CONTROL}`);
	},
	getBaseControlsByType: (
		frameworkType: string,
		frameworkSubType?: string,
	): Promise<IBaseControl[]> => {
		return axios.get(
			`${BASE_CONTROL}?type=${frameworkType}${frameworkSubType ? `&subType=${frameworkSubType}` : ''}`,
		);
	},
};

export default baseControlsApi;
