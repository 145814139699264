import { ICommonStatuses, ISelectOption } from '../../types';

export const AI_PLATFORM_THREADS_LINK = 'https://platform.openai.com/threads/';

export const AppQueryParams = {
	item: 'item',
	tab: 'tab',
};

export enum LayoutType {
	basic = 'basic',
	main = 'main',
}

export const baseLineOptions: ISelectOption[] = [
	{ value: 'low', label: 'Low' },
	{ value: 'medium', label: 'Medium' },
	{ value: 'high', label: 'High' },
];

export const AppTagColors = [
	'#A3E156',
	'#FFE453',
	'#93C2FD',
	'#FF8566',
	'#E298FD',
	'#2BDCFF',
	'#FFB856',
	'#2CF6F3',
	'#FFF1AA',
];

export const CommonColors = {
	skeletonBaseColor: '#F4F9EB',
	defaultTagBg: '#EFEDFC',
	alertTag: '#FFF1AA',
	alertLightTag: '#FFFCEA',
	errorTag: '#FFC3B4',
	errorLightTag: '#FFF0ED',
};

export const StatusColors = {
	draft: '#CAE1FE',
	approved: '#D1F0AB',
	pending: '#FFDCAB',
	inProgress: '#FFDCAB',
	archived: '#e7e7eb',
	reviewed: '#D1F0AB',
	active: '#BDF1B4',
	suspended: '#F1B4C3',
	published: '#BDF1B4',
	unpublished: '#CAE1FE',
	failed: '#FFC3B4',
	completed: '#D1F0AB',
};

export const CommonStatusDisplayNames = {
	approved: 'Approved',
	archived: 'Archived',
	reviewed: 'Reviewed',
	draft: 'Draft',
	published: 'Published',
	unpublished: 'Not Published',
	active: 'Active',
	suspended: 'Suspended',
	completed: 'Completed',
	failed: 'Failed',
	pending: 'Pending',
	inProgress: 'In Progress',
};

export const FrameworkTypes = {
	nist: 'NIST',
	iso: 'ISO',
	soc: 'SOC_2',
};

export const FrameworkSubTypes = {
	low: 'LOW',
	moderate: 'MODERATE',
	high: 'HIGH',
};

export const QueryParams = {
	token: 'token',
};

export const PAGE_SIZE = 50;
export const MAX_CONTROLS_DISPLAY_NUMBER = 5;
export const MIN_CONTROLS_DISPLAY_NUMBER = 2;

export const VariableUnits = [
	{ value: 'minutes', label: 'Minutes' },
	{ value: 'hours', label: 'Hours' },
	{ value: 'days', label: 'Days' },
	{ value: 'months', label: 'Months' },
	{ value: 'quaters', label: 'Quaters' },
	{ value: 'years', label: 'Years' },
];

export enum LifeTimePeriod {
	day = 'day',
	year = 'year',
}

export enum Industry {
	InformationTechnology = 'information-technology',
	Tech = 'tech',
	Healthcare = 'healthcare',
	Education = 'education',
	Accounting = 'accounting',
	Pharmaceutical = 'pharmaceutical',
	Finance = 'finance',
	Engineering = 'engineering',
	RealEstate = 'real-estate',
	HigherEducation = 'higher-education',
	Sales = 'sales',
	Government = 'government',
	Energy = 'energy',
	Retail = 'retail',
	Manufacturing = 'manufacturing',
	Architecture = 'architecture',
	HumanResources = 'human-resources',
	Nonprofit = 'nonprofit',
	Transportation = 'transportation',
	Hospitality = 'hospitality',
}

export const IndustriesDisplayNames = {
	[Industry.InformationTechnology]: 'Information Technology (IT)',
	[Industry.Tech]: 'Tech',
	[Industry.Healthcare]: 'Healthcare',
	[Industry.Education]: 'Education',
	[Industry.Accounting]: 'Accounting',
	[Industry.Pharmaceutical]: 'Pharmaceutical',
	[Industry.Finance]: 'Finance',
	[Industry.Engineering]: 'Engineering',
	[Industry.RealEstate]: 'Real Estate',
	[Industry.HigherEducation]: 'Higher Education',
	[Industry.Sales]: 'Sales',
	[Industry.Government]: 'Government',
	[Industry.Energy]: 'Energy',
	[Industry.Retail]: 'Retail',
	[Industry.Manufacturing]: 'Manufacturing',
	[Industry.Architecture]: 'Architecture',
	[Industry.HumanResources]: 'Human Resources',
	[Industry.Nonprofit]: 'Nonprofit',
	[Industry.Transportation]: 'Transportation',
	[Industry.Hospitality]: 'Hospitality',
};

export const CompanyStatusSelectOptions = [
	{
		value: ICommonStatuses.active,
		label: CommonStatusDisplayNames.active,
	},
	{
		value: ICommonStatuses.suspended,
		label: CommonStatusDisplayNames.suspended,
	},
];

export const IndustriesSelectOptions = [
	{
		value: Industry.InformationTechnology,
		label: 'Information Technology (IT)',
	},
	{
		value: Industry.Tech,
		label: 'Tech',
	},
	{
		value: Industry.Healthcare,
		label: 'Healthcare',
	},
	{
		value: Industry.Education,
		label: 'Education',
	},
	{
		value: Industry.Accounting,
		label: 'Accounting',
	},
	{
		value: Industry.Pharmaceutical,
		label: 'Pharmaceutical',
	},
	{
		value: Industry.Finance,
		label: 'Finance',
	},
	{
		value: Industry.Engineering,
		label: 'Engineering',
	},
	{
		value: Industry.RealEstate,
		label: 'Real Estate',
	},
	{
		value: Industry.HigherEducation,
		label: 'Higher Education',
	},
	{
		value: Industry.Sales,
		label: 'Sales',
	},
	{
		value: Industry.Government,
		label: 'Government',
	},
	{
		value: Industry.Energy,
		label: 'Energy',
	},
	{
		value: Industry.Retail,
		label: 'Retail',
	},
	{
		value: Industry.Manufacturing,
		label: 'Manufacturing',
	},
	{
		value: Industry.Architecture,
		label: 'Architecture',
	},
	{
		value: Industry.HumanResources,
		label: 'Human Resources',
	},
	{
		value: Industry.Nonprofit,
		label: 'Nonprofit',
	},
	{
		value: Industry.Transportation,
		label: 'Transportation',
	},
	{
		value: Industry.Hospitality,
		label: 'Hospitality',
	},
];

export const LifeTimeSelectOptions = [
	{
		value: LifeTimePeriod.day,
		label: '1 day',
	},
	{
		value: LifeTimePeriod.year,
		label: '1 year',
	},
];

export const CompanyStatusOptions = [
	{
		value: ICommonStatuses.active,
		label: 'Active',
		metaData: { color: StatusColors.active },
	},
	{
		value: ICommonStatuses.suspended,
		label: 'Suspended',
		metaData: { color: StatusColors.suspended },
	},
];

export const PolicyStatusOptions = [
	{
		value: ICommonStatuses.unpublished,
		label: CommonStatusDisplayNames.unpublished,
		metaData: { color: StatusColors.unpublished },
	},
	{
		value: ICommonStatuses.published,
		label: CommonStatusDisplayNames.published,
		metaData: { color: StatusColors.active },
	},
];

