import { useEffect } from 'react';
import useUser from './useUser';
import { useAppDispatch } from '../../../services/store';
import useFrameworks from './useFrameworks';
import { handleWithTryCatch } from '../errors';
import { getGSPolicies } from '../../../services/store/slices/policies.slice';

export default function useGetPolicies() {
	const dispatch = useAppDispatch();
	const { authorized } = useUser();
	const { items: frameworkItems } = useFrameworks();

	useEffect(() => {
		if (authorized && frameworkItems) {
			handleWithTryCatch(() => dispatch(getGSPolicies()));
		}
	}, [authorized, dispatch, frameworkItems]);
}
